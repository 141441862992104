/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react'
import { render } from "react-dom";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import './style.css'
import ErrorMsg from './ErrorMsg'
import SuccessMessage from './SuccessMessage'
import { KarumbeTextField,KarumbeBigTextField, KarumbeSelectProveedor, KarumbeMonthDatePicker, KarumbeSelect, KarumbeGridList, KarumbeInputFotos, KarumbeButton } from '../../karumbe_components'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import Typography from '@material-ui/core/Typography'
import './ScreenContactanos.css'
import facebookIcon from './logo-de-facebook.png'
import InstagramIcon from './ig.png'
import TwitterIcon from './twitter.png'
import WhatsAppIcon from './WA.png'
import YouTubeIcon from './YT.png'
import TikTokIcon from './tiktok.png'
import CorreoIcon from './correo.png'
import IconButton from '@material-ui/core/IconButton'
import { Redirect } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import { Container, Row, Col } from "reactstrap";
const useStyles = makeStyles({
    card: {
        maxWidth: '445px',
        height: '95%',
        display: 'inline-block',
        marginTop: '5%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0)',

    },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '40%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
        color: '#3C810D'
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},

    h7: {
    color: '#3C810D',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
    },
      body: {
      color:'white',
      fontSize:'20px',
      fontFamily: 'Microsoft Yi Baiti',
      lineHeight:'1.0'
  }
})
export default function ScreenContactanos (props) {
  const classes = useStyles()
  //const positionY = window.pageYOffset
 // const positionX = window.pageXOffset
  const [redirect, setRedirect] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const apiURL = process.env.REACT_APP_API_URL
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
  const [correo, setCorreo] = React.useState({
    nombreRemitente: null,
    emailContacto: null,
    mensaje: ''
  })
  function limpiarFormulario () {
    setError(false)
    setOpenSuccess(false)
    setOpenError(false)
    setCorreo({
    ...correo,
    nombreRemitente: null,
    emailContacto: null,
    mensaje: ''
    })
  }
  const [notifyEmail, setNotifyEmail] = React.useState(false)

  const handleChange = attribute => event => {
    setCorreo({ ...correo, [attribute]: event.target.value })
  }
   
  const postReport = function (event) {
    var positionY = window.pageYOffset
    var positionX = window.pageXOffset
    if (correo.nombreRemitente === null ||
      (correo.emailContacto !== null && !correo.emailContacto.match('.+@.+'))) {
      setError(true)
      
      window.scrollTo(positionX,positionY)
      setOpenError(true)
      setMessage('Por favor complete los campos obligatorios y vuelva a enviar su reporte')
    } else {
      var url = `${apiURL}/contactanos`
      trackPromise(
        axios.post(url, correo).then(response => {
          limpiarFormulario()
          setMessage('Su mensaje ha sido enviado correctamente. ¡Gracias por contactarse!')
          setOpenSuccess(true)
          setOpenError(false)
//          setRedirect(true)
        }).catch(responseError => {
          console.log(responseError)
          console.log(`${apiURL}/contactanos`)
          setMessage('Hubo un error con la página, inténtelo más tarde')
          setOpenError(true)
        })
      )
    }
  }

  function handleCorreoToggle (event) {
    setNotifyEmail(!notifyEmail)
  }

  function handleChangeEmail (event) {
    setCorreo({ ...correo, emailContacto: event.target.value.trim() })
  }

  React.useEffect((event) => {
    if (!notifyEmail) {
      setCorreo({ ...correo, emailContacto: null })
    }
  }, [notifyEmail])


  const cancelar = function () {
    window.scrollTo(0, 0)
//    setRedirect(true)
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
<div class="restOfScreenForContactanosScreen">
    <Container > 
      <div class="col cs-left">
                        
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                <Table   className={classes.table}>
                                    <TableBody>
                                          <TableRow>
                                    <TableCell  align='center'>                                    
                                    <Typography className={classes.body} >
                                       Karumbé
                                    </Typography>
                                    
                                    </TableCell>
                                </TableRow>
                                        
                                        <TableRow>
                                            <TableCell  align='left'>
                                                <Typography className={classes.body} >
                                                    Av. Rivera 3245 - 11600<br/>
                                                    Montevideo - Uruguay<br/>
                                                    Tel: 099917811 – 098614201<br/>
                                                    Email: karumbemail@gmail.com   
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                   
                    </div>
                    <div class="col cs-right">
                      
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}>
                                <Table   className={classes.table}>
                                <TableBody>
                           
                  
                                <TableRow>
                                    <TableCell  align='center'>                                    
                                    <Typography className={classes.body} >
                                       ¡Seguinos en nuestras redes!  
                                    </Typography>
                                    
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                    <TableCell  align='center'>
                    <IconButton href='https://www.twitter.com/karumbeuy/'>
                <img src={TwitterIcon} className='responsive' />
              </IconButton>
                <IconButton href='https://www.facebook.com/karumbe.org/'>
                <img src={facebookIcon} className='responsive' />
              </IconButton>
               <IconButton href='https://www.instagram.com/karumbeuruguay/'>
                <img src={InstagramIcon} className='responsive' />
              </IconButton>
              <IconButton href='https://www.youtube.com/karumbetv/'>
                <img src={YouTubeIcon} className='responsive' />
              </IconButton>
              <IconButton href='https://www.tiktok.com/@karumbeuruguay/'>
                <img src={TikTokIcon} className='responsive' />
              </IconButton>
             
             
                </TableCell>
                </TableRow>
                               
                                </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                
                    </div>
    </Container>
    </div>
  )
}

