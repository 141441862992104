// TODO contendor pagina Voluntariado.
// trae informacion del backend y llama a los componentes de presentacion pasandoles los datos pertinentes
import React from 'react'
import { render } from "react-dom";

import SuccessMessage from './SuccessMessage'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { KarumbeTextField, KarumbeButton } from '../../karumbe_components'
import {  animateScroll as scroll } from "react-scroll";
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from './ErrorMsg'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'


import Carousel from "../../generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Container, Row, Col } from "reactstrap";
import "./ScreenVoluntariado.css"
const useStyles = makeStyles({
    card: {
    width: '100%',
    maxWidth: '750px',
    marginTop: '5%',
    marginBottom:'3%',
    backgroundColor: 'rgb(32, 122, 126,0.95)',
   

  },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '40%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
//        color: 'rgb(32 122 126)'
        color:'white',
        fontFamily: 'TovariSans'
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
    },
    subtitle:{
        fontSize:'20px',
        fontFamily:'TovariSans'
    },
     body: {
      
    fontFamily: 'Gil Sans MT',
      fontSize:'16px',
      textAlign:'justify',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
})

export default function ScreenVoluntariado() {
    const [message, setMessage] = React.useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const classes = useStyles()
    
    const [redirect, setRedirect] = React.useState(false)
    const [openError, setOpenError] = React.useState(false)
    const [error, setError] = React.useState(false)
    const apiURL = process.env.REACT_APP_API_URL


    
    
    const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
    const [voluntario, setVoluntario] = React.useState({
      nombreVoluntario: null,
      paisVoluntario: null,
      correoVoluntario: null,
    })

     function limpiarFormulario () {
      setError(false)
      setOpenSuccess(false)
      setOpenError(false)
      setVoluntario({
      ...voluntario,
      nombreVoluntario: null,
      paisVoluntario: null,
      correoVoluntario: null,
      })
    }
    const [notifyEmail, setNotifyEmail] = React.useState(false)

    const handleChange = attribute => event => {
      setVoluntario({ ...voluntario, [attribute]: event.target.value })
    }

    const handleChangeDate = attribute => date => {
      setVoluntario({ ...voluntario, [attribute]: date.getTime() })
    }

    const postReport = function (event) {
      var positionY = window.pageYOffset
      var positionX = window.pageXOffset
      if (voluntario.nombreVoluntario === null ||  voluntario.paisVoluntario === null || 
          voluntario.correoVoluntario === null) {
        setError(true)
        window.scrollTo(positionX,positionY)
        setMessage('Por favor complete los campos obligatorios (*) y vuelva a enviar su reporte')
        setOpenError(true)
      } else {
        var url = `${apiURL}/voluntariado`
        trackPromise(
          axios.post(url, voluntario).then(response => {
              limpiarFormulario()
              setMessage('Sus datos han sido enviados correctamente. ¡Gracias!')
              setOpenSuccess(true)
              setOpenError(false)
  //          setdataRedirect({ path: '/', msj: 'Sus datos han sido enviados correctamente. ¡Gracias por colaborar!' })
  //          setRedirect(true)
          }).catch(responseError => {
            console.log(responseError)
            console.log(`${apiURL}/voluntariado`)
            setMessage('Hubo un error con la página, inténtelo más tarde')
            setOpenError(true)
          })
        )
      }
    }

    function handleCorreoToggle (event) {
      setNotifyEmail(!notifyEmail)
    }

    function handleChangeEmail (event) {
      setVoluntario({ ...voluntario, correoVoluntario: event.target.value.trim() })
    }

    React.useEffect((event) => {
      if (!notifyEmail) {
        setVoluntario({ ...voluntario, correoVoluntario: null })
      }
    }, [notifyEmail])

    const cancelar = function () {
      window.scrollTo(0, 0)
  //    setRedirect(true)
    }

    const { promiseInProgress } = usePromiseTracker()

    


    function handleCloseMsj() {
        setOpenSuccess(false)
    }

    return (
        <div class="restOfScreenForVoluntariadoScreen">
            <Container> 
                    <div class="container">
                        <Card className={classes.card}>
                         <ErrorMsg open={openError} onHandleOpen={() => { setOpenError(!openError) }} message={message} />
                        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
                          {promiseInProgress

                            ? (
                              <CardContent>
                                <LoadingSpinner />
                              </CardContent>
                            )
                            : (
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                <Table className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Typography className={classes.title} variant='h4'>
                                                    Voluntariado
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.subtitle} >
                                                  ¡Vení a ayudar a las tortugas marinas en Uruguay! Una experiencia única.
                                                </Typography>  
                                                </TableCell>
                                                </TableRow>
                                                 <TableRow>
                                            <TableCell  align='center'>
                                                    <Typography className={classes.body} >
                                                    <li>Karumbé monitorea la costa y área marina uruguaya desde 1999. Esta zona es clave para la alimentación y desarrollo de tres especies de tortugas marinas: la tortuga verde (Chelonia mydas),  la tortuga cabezona (Caretta caretta) y la tortuga 7 Quillas (Dermochelys coriacea). Realizamos tareas de investigación, conservacion y rehabilitación de tortugas.</li>
                                                    </Typography>
                                                    <Typography className={classes.body} >
                                                    <li>Lugar: La Coronilla, Rocha, Uruguay.</li>
                                                     </Typography>
                                                     <Typography className={classes.body} >
                                                     <li>Periodo: Noviembre – Diciembre – Enero – Febrero – Marzo – Abril</li>
                                                     </Typography>
                                                     <Typography className={classes.body} >
                                                     <li>Costos: Alimentación y hospedaje.</li>
                                                     </Typography>
                                                     <Typography className={classes.body} >
                                                     <li>Para recibir el instructivo y mayor información debes llenar el siguiente formulario:</li>
                                                     </Typography>
                                            </TableCell>
                                        </TableRow>
                                       
                                       <TableCell align='center'>
                                        <form noValidate autoComplete='off'>
                                          <List>
                                            <ListItem id='name'>
                                              <KarumbeTextField
                                                label='Nombre / Name (*)'
                                                maxLength={60}
                                                value={voluntario.nombreVoluntario}
                                                error={(voluntario.nombreVoluntario == null  && error)}
                                                placeholder='Ingrese su nombre completo'
                                                onChange={handleChange('nombreVoluntario')}
                                              />
                                            </ListItem>
                                            <ListItem id='commonName'>
                                                <KarumbeTextField
                                                  label='País / Country (*)'
                                                  value={voluntario.paisVoluntario}
                                                  error={(voluntario.paisVoluntario == null  && error)}
                                                  onChange={handleChange('paisVoluntario')}
                                                  maxLength={60}
                                                />
                                            </ListItem>
                                          
                                              <ListItem id='email'>
                                                <KarumbeTextField
                                                  maxLength={60}
                                                  error={!(voluntario.correoVoluntario !== null && voluntario.correoVoluntario.match('.+@.+')) && error}
                                                  label='Correo / Email (*)'
                                                  value={voluntario.correoVoluntario}
                                                  placeholder='ejemplo@karumbe.com'
                                                  onChange={handleChangeEmail}
                                                />
                                              </ListItem>  
                          
                                            <ListItem>
                                              <KarumbeButton
                                                label='Cancelar' onClick={cancelar}
                                              />
                                              <KarumbeButton
                                                label='Enviar' onClick={postReport}
                                              />
                                            </ListItem>
                                          </List>
                                        </form>
                                        </TableCell>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Avatar  alt='' src={'/images/voluntariado/voluntariado_1.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/voluntariado/voluntariado_2.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/voluntariado/voluntariado_3.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/voluntariado/voluntariado_4.jpg'} className={classes.bigAvatar} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                        )}
                        </Card>
                    </div>
                   
          
                <SuccessMessage open={openSuccess} handleOpen={() => {
                    setOpenSuccess(!openSuccess)
                }} message={message} handleCloseMsj={handleCloseMsj} />
            </Container>
        </div>
            )


}