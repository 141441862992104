import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import { ACCESS_TOKEN, ROLE } from '../../../constants'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import { KarumbeButton } from '../../karumbe_components'
import { Redirect } from 'react-router-dom'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  title: {
    color: '#3C810D'
  },
  error: {
    color: '#F0072D'
  },
  card: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  bigAvatar: {
    width: 70,
    height: 70
  }
}))

export default function ScreenVerUsuario (props) {
  const classes = useStyles()
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const apiURL = process.env.REACT_APP_API_URL
  const [usuario, setUsuario] = React.useState(null)
  const [fotoPerfil, setFotoPerfil] = React.useState([])
  const { promiseInProgress } = usePromiseTracker()
  const [redirect, setRedirect] = React.useState(false)
  const [dataRedirect, setdataRedirect] = React.useState()
  const [termino, setTermino] = React.useState(false)

  async function cargarFotoPerfil () {
    const headers = {
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN)
    }
    var promises = []
    var fotosId = []
    for (const link of usuario.foto) {
      var url = `${apiURL}${link}`
      promises.push(axios.get(url, { headers }))
    }
    try {
      var responses = await Promise.all(promises)
      fotosId = (responses.map((foto) => { return foto.data }))
      setFotoPerfil(fotosId)
    } catch (err) {
      if (err.response.status === 401) {
        autoLogout()
      } 
      console.log('Error al traer fotos: \n', err)
    }
  }

  React.useEffect((event) => {
    if (usuario) {
      cargarFotoPerfil()
    }
  }, [usuario])

  React.useEffect((event) => {
    var url
    if (props.match.params && props.match.params.idUsuario) {
      url = `${apiURL}/usuarios/${props.match.params.idUsuario}`
    } else {
      url = `${apiURL}/usuarios/perfil`
    }
    trackPromise(
      axios.get(url, config).then((response) => {
        setUsuario(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener usuario: \n', _error)
      }))
  }, [])

  function toUpLowerCase (texto) {
    let aux = texto.toLowerCase()
    aux = aux.charAt(0).toUpperCase() + aux.slice(1)
    return aux
  }

  const volver = function () {
    window.history.go(-1)
  }

  const modificarEstado = function () {
    var url = `${apiURL}/usuarios/${usuario.idUsuario}/modificar_activo`
    trackPromise(
      axios.post(url, {}, config).then(response => {
        setUsuario({ ...usuario, activo: !usuario.activo })
        setTermino(true)
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        setTermino(true)
        console.log('Error al dar de baja: \n', responseError)
      })
    )
  }

  const labelModificar = function () {
    if (usuario.activo) {
      return 'Dar de Baja'
    } else {
      return 'Dar de Alta'
    }
  }

  const editar = function () {
    setdataRedirect({ path: '/modificar_usuario/' + usuario.idUsuario })
    setRedirect(true)
  }

  function rol (rolUsuario) {
    if (rolUsuario === 'ADMIN') {
      return 'Administrador'
    }
    if(rolUsuario === 'VOL_FIN') {
        return 'Voluntario Administrador Financiero'
    }
      else {
      return 'Voluntario'
    }
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        {redirect
          ? (
            <Redirect
              push={dataRedirect.push}
              to={{
                pathname: dataRedirect.path,
                state: {
                  baseUsuario: usuario
                }
              }}

            />
          )
          : (promiseInProgress
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            ) : ((usuario)
              ? (
                <CardContent>
                  {fotoPerfil[0]
                    ? (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align='left'>
                              <Avatar alt='' src={`data:image/jpeg;base64,${fotoPerfil[0]}`} className={classes.bigAvatar} />
                            </TableCell>
                            <TableCell align='left'>
                              <Typography className={classes.title} variant='h5'>
                                {toUpLowerCase(usuario.nombre)} {toUpLowerCase(usuario.apellido)}
                              </Typography>
                              <Typography variant='subtitle2'>
                                {rol(usuario.rol)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>)
                    : (
                      <Table>
                        <Typography align='center' className={classes.title} variant='h4'>
                          {toUpLowerCase(usuario.nombre)} {toUpLowerCase(usuario.apellido)}
                        </Typography>
                        <Typography align='center' variant='subtitle2'>
                          {rol(usuario.rol)}
                        </Typography>
                      </Table>
                    )}
                  <Table>
                    <TableBody>
                      {usuario.correo &&
                        <TableRow>
                          <TableCell>Correo: </TableCell>
                          <TableCell>{usuario.correo}</TableCell>
                        </TableRow>}
                      {usuario.contacto &&
                        <TableRow>
                          <TableCell>Teléfono: </TableCell>
                          <TableCell>{usuario.contacto}</TableCell>
                        </TableRow>}
                      {usuario.departamento &&
                        <TableRow>
                          <TableCell>Departamento: </TableCell>
                          <TableCell>{usuario.departamento}</TableCell>
                        </TableRow>}
                      {usuario.nombreUsuario &&
                        <TableRow>
                          <TableCell>Usuario: </TableCell>
                          <TableCell>{usuario.nombreUsuario}</TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                  <List>
                    <ListItem>
                      {(usuario.modificar || (window.localStorage.getItem(ROLE) === 'ADMIN')) &&
                        <KarumbeButton label='Editar' onClick={editar} />}
                      <KarumbeButton label='Volver' onClick={volver} />
                    </ListItem>
                    {(window.localStorage.getItem(ROLE) === 'ADMIN') && !usuario.modificar &&
                      <ListItem>
                        <KarumbeButton
                          label={labelModificar()} onClick={modificarEstado}
                        />
                      </ListItem>}
                  </List>
                </CardContent>
              ) : ( termino && 
                <CardContent className={classes.card}>
                  <Typography align='center' className={classes.error} variant='h5'>
                    El usuario que usted solicita no se encuentra en el sistema.
                  </Typography>
                </CardContent>
              )))}
      </Card>
    </div>
  )
}
