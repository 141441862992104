import React from 'react'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles({
  card: {
    maxWidth: 373,
    height: 373,
    display: 'inline-block',
    margin: '5px 5px 5px 5px'
  },
  cardTitleSpinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    maxWidth: 1448,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  table: {
    padding: '9px 3px 9px 3px',
    margin: '3px',
    height: '100%'
  },
  text: {
    wordBreak: 'break-word',
    maxWidth: '100px'
  },
  textLong: {
    wordBreak: 'break-word',
    maxWidth: '100px'
  },
  fecha: {
    whiteSpace: 'nowrap'
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .8)'
    }
  },
  filter: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    position: 'fixed',
    top: '10',
    right: '0',
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: '#3EA91D'
    }
  },
  checkboxIcons: {
    color: '#20DD03'
  },
  checkboxIconsRed: {
    color: '#F53232'
  },
  checkboxText: {
    paddingLeft: '15px',
    color: '#757575'
  },
  checkboxContainer: {
    display: 'flex',
    alignContent: 'space-between'
  },
  root: {
    maxWidth: '261px',
    width: '100%',
    padding: '0px'
  },
  grid: {
    height: '100%'
  },
  title: {
    color: '#3C810D'
  },
  h6: { size: '1.132em' },
  bigAvatar: {
    width: 70,
    height: 70
  }
})

function CardFicha ({ ficha }) {
  const classes = useStyles()
  const [fotoPerfil, setFotoPerfil] = React.useState(null)
  const apiURL = process.env.REACT_APP_API_URL

  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  let fechaIngreso
  if (ficha.fechaIngresoSocobiomaMald) {
    const currentDate = iOS ? new Date(ficha.fechaIngresoSocobiomaMald.substr(0, 19)) : new Date(ficha.fechaIngresoSocobiomaMald)
    fechaIngreso = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear().toString()
  } else {
    fechaIngreso = <i>No ingresada</i>
  }

  let fechaEgreso
  if (ficha.fechaEgreso) {
    const currentDate = iOS ? new Date(ficha.fechaEgreso.substr(0, 19)) : new Date(ficha.fechaEgreso)
    fechaEgreso = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear().toString()
  } else {
    fechaEgreso = <i>No egresó</i>
  }

  async function cargarFotoPerfil () {
    const headers = {
      'Content-Type': 'text/plain',
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
    }
    var url = `${apiURL}${ficha.foto}`
    axios.get(url, headers).then((response) => {
      setFotoPerfil(response.data)
    }).catch((_error) => {
      console.log('Error al obtener los usuarios')
      if (_error.response.status === 401) {
        autoLogout()
      }
    })
  }

  React.useEffect((event) => {
    if (ficha && ficha.foto) {
      cargarFotoPerfil()
    }
  }, [ficha])

  return (
    <Link to={'/historicos/' + ficha.idFichaAnimal} underline='none' className={classes.hover}>
      <Card className={classes.card}>
        <CardContent className={classes.grid}>
          <Grid
            container
            spacing={0}
            alignItems='center'
            justify='center'
            textOverflow='hidden'
            className={classes.grid}
          >
            <Table className={classes.table}>
              <TableBody>
                {fotoPerfil
                  ? (
                    <TableRow>
                      <TableCell align='left'>
                        <Avatar alt='' src={`data:image/jpeg;base64,${fotoPerfil}`} className={classes.bigAvatar} />
                      </TableCell>
                      <TableCell align='right'>
                        <i>Ficha #{ficha.idFichaAnimal} </i>
                        <Typography className={classes.h6} variant='h6' noWrap>
                          {ANIMAL_FILE_STATE[ficha.estado]}
                        </Typography>
                      </TableCell>
                    </TableRow>)
                  : (
                    <TableRow>
                      <TableCell align='left'>
                        <i>Ficha #{ficha.idFichaAnimal} </i>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography className={classes.h6} variant='h6' noWrap>
                          {ANIMAL_FILE_STATE[ficha.estado]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                <TableRow>
                  <TableCell align='center'>
                    Fecha de Ingreso: <div className={classes.fecha}> {fechaIngreso}</div>
                  </TableCell>
                  <TableCell align='center'>
                    Fecha de Egreso: <div className={classes.fecha}> {fechaEgreso}</div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='center'>
                    {ficha.departamento}
                  </TableCell>
                  <TableCell className={classes.textLong} align='center'>
                    {ficha.ubicacionActual &&
                      <LinesEllipsis
                        text={ficha.ubicacionActual}
                        maxLine='3'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                      />}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  )
}

export default function ScreenAnimalesRehabilicaion () {
  const classes = useStyles()

  const apiURL = process.env.REACT_APP_API_URL
  const [fichas, setFichas] = React.useState(null)
  const [offset, setOffset] = React.useState(0)
  const [filtrar, setFiltrar] = React.useState(true)
  const [departamentos, setDepartamentos] = React.useState([])
  const [porNumero, setPorNumero] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [filters, setFilters] = React.useState({
    fileNumber: null,
    departamento: null,
    estado: null,
    clase: null,
    nombreComun: null,
    ordenFecha: true,
    pag: 1
  })
  React.useEffect((event) => {
    const url = `${apiURL}/fichas_animal?estado=En_rehabilitaci%C3%B3n&ordenFecha=true&pag=${filters.pag}`
    const config = {
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
      params: filters
    }
    trackPromise(
      axios.get(url, config).then((response) => {
        setFichas(response.data)
      }).catch((_error) => {
        console.log('Error en el GET de reportes: \n', _error) // TODO show errors to the user
        if (_error.response.status === 401) {
          autoLogout()
        }
      })
    )
  }, [filters, filtrar, porNumero])

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        const deps = [].concat(response.data.map((dep) => { return dep.nombre }))
        setDepartamentos(deps)
      }).catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          autoLogout()
        }
      })
    )
  }, [])

  const { promiseInProgress } = usePromiseTracker()
  return (
    <div>
      <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />

      <Card className={classes.cardTitleSpinner}>
        <CardContent>
          <Typography align='center' className={classes.title} variant='h4'>
            Animales en Rehabilitación
          </Typography>
        </CardContent>
      </Card>

      {promiseInProgress
        ? (
          <Card className={classes.cardTitleSpinner}>
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          </Card>
        )
        : (
          <Grid
            container
            spacing={0}
            alignItems='center'
            justify='center'
          >
            {fichas && fichas.content.map((ficha) => {
              return (
                <CardFicha key={ficha.idFichaAnimal} ficha={ficha} />)
            })}

            <div id='newLine' />

            <div class='fondoPag'>
              {(fichas && filtrar) &&
                <Pagination
                  limit={fichas.size}
                  offset={offset}
                  total={fichas.totalElements}
                  reduced='true'
                  onClick={(e, offset, page) => {
                    setOffset(offset)
                    setFilters({ ...filters, pag: page })
                  }}
                />}
            </div>
          </Grid>
        )}
    </div>
  )
}
