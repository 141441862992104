import React from 'react'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ToggleButton } from '@material-ui/lab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import Drawer from '@material-ui/core/Drawer'
import { FiltersTextField, FiltersComboBox } from '../../generalComponents/FilterComponents/FiltersTextField.js'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import PageviewIcon from '@material-ui/icons/Pageview'
import IconButton from '@material-ui/core/IconButton'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoSignout from '../../../generalFunctions/autoSignout'

const useStyles = makeStyles({
  card: {
    maxWidth: 373,
    height: 373,
    display: 'inline-block',
    margin: '5px 5px 5px 5px'
  },
  cardTitleSpinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    maxWidth: 1448,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  table: {
    padding: '9px 3px 9px 3px',
    margin: '3px',
    height: '100%'
  },
  text: {
    wordBreak: 'break-word',
    maxWidth: '100px'
  },
  textLong: {
    wordBreak: 'break-word',
    maxWidth: '100px'
  },
  fecha: {
    whiteSpace: 'nowrap'
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .8)'
    }
  },
  filter: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    position: 'fixed',
    top: '10',
    right: '0',
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: '#3EA91D'
    }
  },
  checkboxIcons: {
    color: '#20DD03'
  },
  checkboxIconsRed: {
    color: '#F53232'
  },
  checkboxText: {
    paddingLeft: '15px',
    color: '#757575'
  },
  checkboxContainer: {
    display: 'flex',
    alignContent: 'space-between'
  },
  root: {
    maxWidth: '261px',
    width: '100%',
    padding: '0px'
  },
  grid: {
    height: '100%'
  },
  title: {
    color: '#3C810D'
  },
  h6: { size: '1.132em' },
  bigAvatar: {
    width: 70,
    height: 70
  }
})

function CardFicha({ ficha }) {
  const classes = useStyles()
  const [fotoPerfil, setFotoPerfil] = React.useState(null)
  const apiURL = process.env.REACT_APP_API_URL

  let fechaIngreso
  if (ficha.fechaIngresoSocobiomaMald) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(ficha.fechaEgrefechaIngresoSocobiomaMald.substr(0, 19)) : new Date(ficha.fechaIngresoSocobiomaMald)
    fechaIngreso = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear().toString()
  } else {
    fechaIngreso = <i>No ingresada</i>
  }

  let fechaEgreso
  if (ficha.fechaEgreso) {
    iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(ficha.fechaEgreso.substr(0, 19)) : new Date(ficha.fechaEgreso)
    fechaEgreso = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear().toString()
  } else {
    fechaEgreso = <i>No egresó</i>
  }

  async function cargarFotoPerfil () {
    const headers = {
      'Content-Type': 'text/plain',
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) }
    }
    var url = `${apiURL}${ficha.foto}`
    axios.get(url, headers).then((response) => {
      setFotoPerfil(response.data)
    }).catch((_error) => {
      console.log('Error al obtener los usuarios')
      if (_error.response.status === 401) {
        autoSignout()
      }
    })
  }

  React.useEffect((event) => {
    if (ficha && ficha.foto) {
      cargarFotoPerfil()
    }
  }, [ficha])

  return (
    <Link to={'/historicos/' + ficha.idFichaAnimal} underline='none'>
      <Card className={classes.card}>
        <CardContent className={classes.grid}>
          <Grid
            container
            spacing={0}
            alignItems='center'
            justify='center'
            textOverflow='hidden'
            className={classes.grid}
          >
            <Table className={classes.table}>
              <TableBody>
                {fotoPerfil
                  ? (
                    <TableRow>
                      <TableCell align='left'>
                        <Avatar alt='' src={`data:image/jpeg;base64,${fotoPerfil}`} className={classes.bigAvatar} />
                      </TableCell>
                      <TableCell align='right'>
                        <i>Ficha #{ficha.idFichaAnimal} </i>
                        <Typography className={classes.h6} variant='h6' noWrap>
                          {ANIMAL_FILE_STATE[ficha.estado]}
                        </Typography>
                      </TableCell>
                    </TableRow>)
                  : (
                    <TableRow>
                      <TableCell align='left'>
                        <i>Ficha #{ficha.idFichaAnimal} </i>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography className={classes.h6} variant='h6' noWrap>
                          {ANIMAL_FILE_STATE[ficha.estado]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                <TableRow>
                  <TableCell align='center'>
                    Fecha de Ingreso: <div className={classes.fecha}> {fechaIngreso}</div>
                  </TableCell>
                  <TableCell align='center'>
                    Fecha de Egreso: <div className={classes.fecha}> {fechaEgreso}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='center'>
                    {ficha.departamento}
                  </TableCell>
                  <TableCell className={classes.textLong} align='center'>
                    {ficha.ubicacionActual &&
                      <LinesEllipsis
                        text={ficha.ubicacionActual}
                        maxLine='3'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                      />}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  )
}

export default function ScreenListarFichas() {
  const classes = useStyles()

  const apiURL = process.env.REACT_APP_API_URL
  const [fichas, setFichas] = React.useState(null)
  const [offset, setOffset] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [filtrar, setFiltrar] = React.useState(true)
  const [departamentos, setDepartamentos] = React.useState([])
  const [porNumero, setPorNumero] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [filters, setFilters] = React.useState({
    fileNumber: null,
    departamento: null,
    estado: null,
    clase: null,
    nombreComun: null,
    ordenFecha: true,
    pag: 1
  })
  React.useEffect((event) => {
    if (filtrar) {
      const url = `${apiURL}/fichas_animal`
      const config = {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
        params: filters
      }
      trackPromise(
        axios.get(url, config).then((response) => {
          setFichas(response.data)
        }).catch((_error) => {
          console.log('Error en el GET de fichas: \n', _error) // TODO show errors to the user
          if (_error.response.status === 401) {
            autoSignout()
          }
        })
      )
    } else if (porNumero) {
      if (filters.fileNumber === null || isNaN(filters.fileNumber) || filters.fileNumber < 0 || filters.fileNumber === '') {
        setOpenError(!openError)
        setMessage('Debe cargar un # de ficha valido')
        setPorNumero(false)
      } else {
        const url = `${apiURL}/fichas_animal/${filters.fileNumber}`
        const config = {
          headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) }
        }
        setPorNumero(false)
        trackPromise(
          axios.get(url, config).then((response) => {
            setFichas({ content: [response.data] })
          }).catch((_error) => {
            if (_error.response.status === 401) {
              autoSignout()
            }
            setFichas({ content: [] })
            setOpenError(!openError)
            setMessage('No existe una ficha con #' + filters.fileNumber + ' en el sistema')
            console.log('Error en el GET de reportes: \n', _error) // TODO show errors to the user
          })
        )
      }
    }
  }, [filters, filtrar, porNumero])

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        const deps = [].concat(response.data.map((dep) => { return dep.nombre }))
        setDepartamentos(deps)
      }).catch((error) => {
        console.log(error)
      })
    )
  }, [])

  const handleChange = attribute => event => {
    setFilters({ ...filters, [attribute]: event.target.value, pag: 1 })
    setOffset(0)
  }

  const { promiseInProgress } = usePromiseTracker()
  return (
    <div>
      <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
      <Button
        variant='outlined'
        className={classes.filter}
        onClick={() => { setOpen(true) }}
      >
        <FilterListIcon />
      </Button>
      <Drawer anchor='right' open={open} onClose={() => { setOpen(false) }}>
        <ExpansionPanel className={classes.root} expanded={!filtrar} onChange={() => { setFiltrar(!filtrar) }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h5' gutterBottom>Busquedas</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem>
                <FiltersTextField
                  label='Nro de Ficha'
                  value={filters.reportNumber}
                  className={classes.textField}
                  type='number'
                  onChange={handleChange('fileNumber')}

                />
                <IconButton
                  onClick={() => { setPorNumero(true) }}
                >
                  <PageviewIcon fontSize='large' />
                </IconButton>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.root} expanded={filtrar} onChange={() => { setFiltrar(!filtrar) }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h5' gutterBottom>Filtros</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List className={classes.root}>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      color: '#757575',
                      paddingLeft: '15px'
                    }
                  }}
                  primary='Orden fecha'
                />
                <ListItemIcon>
                  <ToggleButton
                    onClick={() => {
                      setFilters({ ...filters, ordenFecha: !filters.ordenFecha })
                    }}
                  >
                    {filters.ordenFecha ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </ToggleButton>
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Departamentos'
                  value={filters.departamento}
                  onChange={handleChange('departamento')}
                  items={[null].concat(departamentos)}
                  itemsTags={['Todos'].concat(departamentos)}
                />
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Estado del animal'
                  value={filters.estado}
                  onChange={handleChange('estado')}
                  items={[null, 'En_rehabilitación', 'Liberado', 'Muerto']}
                  itemsTags={['Todos', 'En rehabilitación', 'Liberado', 'Muerto']}
                />
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Especie'
                  value={filters.clase}
                  onChange={(event) => {
                    if (event.target.value !== filters.clase) {
                      setFilters({ ...filters, clase: event.target.value, nombreComun: null })
                    }
                  }}
                  items={[]}
                  itemsTags={[]}
                />
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Drawer>
      <Card className={classes.cardTitleSpinner}>
        <CardContent>
          <Typography align='center' className={classes.title} variant='h4'>
            Fichas de Animales
          </Typography>
        </CardContent>
      </Card>

      {promiseInProgress
        ? (
          <Card className={classes.cardTitleSpinner}>
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          </Card>
        )
        : (
          <Grid
            container
            spacing={0}
            alignItems='center'
            justify='center'
          >
            {fichas && fichas.content.map((ficha) => {
              return (
                <CardFicha key={ficha.idFichaAnimal} ficha={ficha} />)
            })}

            <div id='newLine' />

            <div class='fondoPag'>
              {(fichas && filtrar) &&
                <Pagination
                  limit={fichas.size}
                  offset={offset}
                  total={fichas.totalElements}
                  reduced='true'
                  onClick={(e, offset, page) => {
                    setOffset(offset)
                    setFilters({ ...filters, pag: page })
                  }}
                />}
            </div>
          </Grid>
        )}
    </div>
  )
}
