import React from 'react'
import { render } from "react-dom";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import Map from './Map'
import './style.css'
import ErrorMsg from './ErrorMsg'
import SuccessMessage from './SuccessMessage'
import { KarumbeBigTextField,KarumbeTextField, KarumbeBigNewTextField, KarumbeSelect, KarumbeGridList, KarumbeInputFotos, KarumbeButton } from '../../karumbe_components'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import Typography from '@material-ui/core/Typography'
import './ScreenReporte.css'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    backgroundColor:'#55c5c1'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  radioButton: {
    margin: 'auto'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: 'auto',
    maxWidth: '842px',
    height: 'auto',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop:'5px'
    
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
  card: {
    width: '100%',
    maxWidth: '750px',
    marginTop: '5%',
    marginBottom:'3%',
    backgroundColor: 'rgb(32, 122, 126,0.95)',
   

  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  title: {
    color: '#3C810D'
  },
  subtitle:
  {
        fontSize:'20px',
       fontFamily:'TovariSans'
      
  },
  subtitleLink:
  {
        fontSize: '20px',
        color: 'white',
        fontFamily: 'TovariSans'
      
  },
  title1: {
         fontSize: '26px',
        color: '#3C810D',
        fontFamily: 'TovariSans'
       
    },
  title2: {
    //        color: 'rgb(32 122 126)'
        color:'white',
        fontFamily: 'TovariSans'
    }
}))

export default function ScreenReporte (props) {
  const classes = useStyles()
  //const positionY = window.pageYOffset
  //const positionX = window.pageXOffset
  const [redirect, setRedirect] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [clasesAnimal, setClasesAnimal] = React.useState([])
  
  const [verMapa, setVerMapa] = React.useState(false)
  const [departamentos, setDepartamentos] = React.useState([])
  const apiURL = process.env.REACT_APP_API_URL
  const estado = ['Vivo', 'Muerto', 'Desconoce']
  const tags = ['VIVO', 'MUERTO', 'DESCONOCE']
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
  const [error, setError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [report, setReport] = React.useState({
    estado: null,
    telefonoContacto: '',
    nombreContacto: null,
    emailContacto: null,
    latitud: null,
    longitud: null,
    observaciones: '',
    ubicacion: null,
    nombreClase: null,
    nombreComunReporte: null,
    departamento: null,
    fotosReporte: []
  })
  const [notifyEmail, setNotifyEmail] = React.useState(false)
  function limpiarFormulario () {
    setError(false)
    setOpenSuccess(false)
    setOpenError(false)
    setReport({
      ...report,
       estado: null,
        telefonoContacto: '',
        nombreContacto: null,
        emailContacto: null,
        latitud: null,
        longitud: null,
        observaciones: '',
        ubicacion: null,
        nombreClase: null,
        nombreComunReporte: null,
        departamento: null,
        fotosReporte: [],
    })
  }
  const handleChange = attribute => event => {
    setReport({ ...report, [attribute]: event.target.value })
  }


  const postReport = function (event) {
    var positionY = window.pageYOffset
    var positionX = window.pageXOffset
    if (report.departamento === null || (report.ubicacion === null) || report.ubicacion === '' ||
      report.nombreClase === null || report.nombreComunReporte === null ||
      (report.emailContacto !== null && !report.emailContacto.match('.+@.+')) || report.estado === null) {
      setError(true)
      setMessage('Por favor complete los campos obligatorios y vuelva a enviar su reporte')
      window.scrollTo(positionX,positionY)
      setOpenError(true)
    } else {
      if (report.telefonoContacto.length < 12) {
        report.telefonoContacto = ''
      }
      var url = `${apiURL}/reportes`
      trackPromise(
        axios.post(url, report).then(response => {
          limpiarFormulario()
          setMessage('Su reporte ha sido enviado correctamente. ¡Gracias por colaborar!')
          setOpenSuccess(true)
          setOpenError(false)

//          setdataRedirect({ path: '/', msj: 'Su reporte ha sido enviado correctamente. ¡Gracias por colaborar!' })
//          setRedirect(true)
        }).catch(responseError => {
          console.log(responseError)
          console.log(`${apiURL}/reportes`)
          setMessage('Hubo un error con la página, inténtelo más tarde')
          setOpenError(true)
        })
      )
    }
  }

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        setDepartamentos(response.data.map((obj) => {
          return obj.nombre
        }))
      }).catch(_error => {
        console.log('Error al ingresar el reporte: \n', _error) // TODO show errors to the user
      })
    )
  }, [])

  React.useEffect((event) => {
    const url = `${apiURL}/clases/reportes`
    trackPromise(
      axios.get(url).then((response) => {
        setClasesAnimal(response.data)
      }).catch(_error => {
        console.log('Error al ingresar el reporte: \n', _error) // TODO show errors to the user
      })
    )
  }, [])

  function handleChangePhone (value) {
    setReport({ ...report, telefonoContacto: value })
  }

  function handleCorreoToggle (event) {
    setNotifyEmail(!notifyEmail)
  }

  function handleChangeEmail (event) {
    setReport({ ...report, emailContacto: event.target.value.trim() })
  }

  React.useEffect((event) => {
    if (!notifyEmail) {
      setReport({ ...report, emailContacto: null })
    }
  }, [notifyEmail])

  function handleVerMapa (event) {
    setVerMapa(!verMapa)
  }

  React.useEffect((event) => {
    if (!verMapa) {
      setReport({ ...report, latitud: null, longitud: null })
    }
  }, [verMapa])

  function handleChangeFotos (e) {
    if (report.fotosReporte.length < 10) {
      var reader = new window.FileReader()
      reader.readAsBinaryString(e.target.files[0])
      reader.onload = function () {
        setReport({ ...report, fotosReporte: [...report.fotosReporte, window.btoa(reader.result)] })
      }
    } else {
      // Mostrar alerta del maximo de fotos que se puede subir
    }
  }


  const cancelar = function () {
    window.scrollTo(0, 0)
//    setRedirect(true)
  }

  function handleChangeRemoveFoto (img) {
    var array = [...report.fotosReporte]
    var index = array.indexOf(img)
    if (index !== -1) {
      array.splice(index, 1)
      setReport({ ...report, fotosReporte: array })
    } else {
      console.log('Not found loaded img')
    }
  }

  function commonNamesFun () {
    return [...clasesAnimal[report.nombreClase]].sort()
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
<div class="restOfScreenForReporteScreen">
    <div className={classes.container}>
      <Card className={classes.card}>
      
        <ErrorMsg open={openError} onHandleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
          {promiseInProgress
          
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : (
              <CardContent>
                <TableRow>
                <TableCell align='center'>
                <Typography align='center' className={classes.title2} style={{color:'white'}} variant='h4' >
                     ¿Encontraste una tortuga? <br /> ¡Escríbenos!
                </Typography>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell  align='center'>
                <Typography  className={classes.subtitle} variant='subtitle2' align='justify'>
                    Es de gran relevancia para nosotros contar con los reportes de los animales encontrados, ya sea vivos, para poder asistirlos, así como también los que lamentablemente no lo están, ya que nos es útil contar con dichos datos para investigación.
                </Typography>
                 <Typography  align='center' className={classes.subtitleLink} >
                <a href={process.env.PUBLIC_URL + '/guia_varamientos.jpg'} style={{color:'white'}}>
                   
                        Clickea aquí para ir a la guía de varamientos
                   
                </a>
                 </Typography>
                </TableCell>
                      </TableRow>
                      
                <form className={classes.container} noValidate autoComplete='off'>
                  <List className={classes.root}>
                    <ListItem id='animalState'>
                      <KarumbeSelect
                        label='Estado del animal*'
                        value={report.estado}
                        onChange={handleChange('estado')}
                        error={report.estado === null && error}
                        items={tags}
                        itemsTags={estado}
                      />
                    </ListItem>
                    <ListItem id='class'>
                      <KarumbeSelect
                        label='Tortuga*'
                        value={report.nombreClase}
                        onChange={handleChange('nombreClase')}
                        error={report.nombreClase === null && error}
                        items={Object.keys(clasesAnimal).sort()}
                      />
                    </ListItem>
                    <ListItem id='commonName'>
                      {report.nombreClase &&
                        <KarumbeSelect 
                          label='Nombre Común*'
                          value={report.nombreComunReporte}
                          onChange={handleChange('nombreComunReporte')}
                          error={report.nombreComunReporte === null && error}
                          items={commonNamesFun()}
                        />}
                    </ListItem>
                    <ListItem id='state'>
                      <KarumbeSelect
                        label='Departamento*'
                        value={report.departamento}
                        onChange={handleChange('departamento')}
                        error={report.departamento === null && error}
                        items={departamentos}
                      />
                    </ListItem>
                    <ListItem id='location'>
                      <KarumbeTextField
                        label='Ubicación*'
                        placeholder='Ubicación'
                        value={report.ubicacion}
                        maxLength={300}
                        onChange={handleChange('ubicacion')}
                        error={(report.ubicacion === null || report.ubicacion === '') && error}
                      />
                    </ListItem>
                    <ListItem id='checkMap'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={verMapa}
                            onChange={handleVerMapa}
                            value={verMapa}
                            color='primary'
                          />
                        }
                        label='Utilizar mapa para la ubicación'
                      />
                    </ListItem>
                    {verMapa &&
                      <Map
                        zoom={20}
                        setPosition={(currentPosition) => {
                          setReport({ ...report, latitud: currentPosition.lat, longitud: currentPosition.lng })
                        }}
                      />}
                    <ListItem id='name'>
                      <KarumbeTextField
                        label='Nombre de quien realiza el reporte'
                        maxLength={60}
                        value={report.nombreContacto}
                        placeholder='Ingrese su nombre'
                        onChange={handleChange('nombreContacto')}
                      />
                    </ListItem>
                    <ListItem id='phoneNumber'>
                      <MuiPhoneNumber
                        name='phone'
                        maxLength={45}
                        label='Teléfono'
                        data-cy='user-phone'
                        regions='south-america'
                        defaultCountry='uy'
                        countryCodeEditable={false}
                        autoFormat={false}
                        fullWidth='true'
                        value={report.telefonoContacto}
                        onChange={handleChangePhone}
                        className={classes.phoneNumber}
                      />
                    </ListItem>
                    <ListItem id='observations'>
                      <KarumbeBigTextField 
                        label='Observaciones'
                        rows='3'
                        maxLength={300}
                        value={report.observaciones}
                        placeholder='observaciones'
                        onChange={handleChange('observaciones')}
                      />
                    </ListItem>
                    <ListItem id='checkNotifications'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={report.notifReporteLeido}
                            onChange={handleCorreoToggle}
                            value='checkBoxCorreo'
                            color='primary'
                          />
                        }
                        label='Quiero recibir información sobre el reporte'
                      />
                    </ListItem>
                    {notifyEmail &&
                      <ListItem id='email'>
                        <KarumbeTextField
                          maxLength={60}
                          error={!(report.emailContacto !== null && report.emailContacto.match('.+@.+')) && error}
                          label='Correo'
                          value={report.emailContacto}
                          placeholder='ejemplo@karumbe.com'
                          onChange={handleChangeEmail}
                        />
                      </ListItem>}
                    <ListItem>
                      <KarumbeInputFotos
                        onChange={handleChangeFotos}
                        id='1'
                      />
                      <KarumbeGridList
                        items={report.fotosReporte}
                        onChange={handleChangeRemoveFoto}
                        hideDeleteOption={false}
                      />
                    </ListItem>
                    <ListItem>
                      <KarumbeButton
                        label='Cancelar' onClick={cancelar}
                      />
                      <KarumbeButton
                        label='Enviar' onClick={postReport}
                      />
                    </ListItem>

                  </List>
                </form>
              </CardContent>
            )}
      </Card>
    </div>
    </div>
  )
}
