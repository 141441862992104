import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { KarumbeButton } from '../../karumbe_components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { Redirect } from 'react-router-dom'

import TableHead from '@material-ui/core/TableHead'

const useStyles = makeStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    justifyContent: 'center'
  },
  card: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.58)'
  },
  title: {
    color: '#3C810D'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  error: {
    color: '#F0072D'
  },
  titleRecomendation: {
    color: '#FF7043'
  },
  tableMine: {
    width: '100%'
    
  },
  tableWrapper: {
    margin: 10,
    overflowX: 'auto',
    display: 'inline-block',
  }
})

export default function VerInstanciasRealizadas(props) {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const [tratamiento, setTratamiento] = React.useState()
  const [dataRedirect, setdataRedirect] = React.useState()
  const [redirect, setRedirect] = React.useState(false)
  console.log(props.instancias)
  // TABLA DE VER INSTANCIAS ------------------------------------------------
  const [rows, setRows] = React.useState()
  console.log(props.instancias ? props.instancias.map((obj,index) => {
    return createRow(index, obj)
  }) : [])
  

  const columns = [
    { id: 'C0', label: 'N° instancia' },
    { id: 'C1', label: 'Fecha' },
    { id: 'C2', label: 'Hora' },
    { id: 'C3', label: 'Éxito' },
    { id: 'C4', label: 'Observaciones' }
  ]
  function createData(C0, C1, C2, C3, C4) {
    return { C0, C1, C2, C3, C4 }
  }

  function nullToEmpty(string) {
    return !string ? '' : string
  }

  function createRow(index, instanciaT) {
    if (instanciaT) {
      return createData(index+1,
        convertDate(instanciaT.fechaHora),
        getHour(instanciaT.fechaHora),
        instanciaT.exito + ' %',
        nullToEmpty(instanciaT.comentario))
    }
  }

  // FIN TABLA DE VER INSTANCIAS ------------------------------------------------

  function convertDate(fecha) {
    const currentDate = new Date(fecha)
    const formattedDate = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth()+1).toString() + '/' + currentDate.getFullYear().toString()
    return formattedDate
  }

  function getHour(fecha) {
    const date = new Date(fecha)
    const hours = date.getHours()
    const minutes = '0' + date.getMinutes()
    const formattedTime = hours + ':' + minutes.substr(-2)
    return formattedTime
  }

  console.log(props.instancias)
  return (
    <>
      {props.instancias.length === 0 ?
        <i>No hay instancias realizadas para este tratamiento</i> :

        <div style={{ maxHeight: props.height, width: props.width }} className={classes.tableWrapper}>
          <Table stickyHeader className={classes.tableMine}>
            <TableHead className={classes.tableMine}>
              {columns.map((column, index) => (
                <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyDay : classes.tableCell}>
                  {column.label}
                </TableCell>
              ))}
            </TableHead>
            {(props.instancias ? props.instancias.map((obj,index) => {
              return createRow(index, obj)
            }) : []).map(row => {
              console.log('Holaaa')
              return (
                <TableRow tabIndex={-1} key={row.code} className={classes.tableMine}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell style={{ padding: '10px' }} key={column.id} >
                        <p style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >{column.format && typeof value === 'number' ? column.format(value) : value}</p>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </Table>
        </div>
                 


      }
    

    </>
  )


}
