import React from 'react'
import { ACCESS_TOKEN, ROLE } from '../../../constants'
import { Route, Redirect } from 'react-router-dom'
import AccesDenied from '../AccessDenied/AccesDenied'

export default function ProtectedRoute ({ component: Component, role, admin, ...rest }) {
  /*
  component = component to render if you have the permissions
  role = privilege needed to see the component, if no role is passed then it will
          only ask for the user to be logged
  the rest of props are just the same as a react route
  */
  return (
    <Route
      {...rest}
      render={(props) => {
        return ((window.localStorage.getItem(ACCESS_TOKEN))
          ? (window.localStorage.getItem(ROLE) === role || !role || (window.localStorage.getItem(ROLE) === 'ADMIN' &&  admin))
            ? <Component {...props} />
            : <AccesDenied />
          : <Redirect to='/login' />
        )
      }}
    />

  )
}
