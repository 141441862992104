import React, { useState } from 'react'
import { render } from "react-dom";
import './ScreenInicio.css'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Carousel from "../../generalComponents/Carousel/Carousel";
import { BrowserRouter as Router, Route, Link as LinkForPage } from 'react-router-dom'

import { Link, animateScroll as scroll } from "react-scroll";


//AGREGADO AHORA
import Section from '../../generalComponents/Section/Section.js'
import ScreenColabora from '../screenColabora/ScreenColabora.js'
import ScreenConozcanos from '../screenConozcanos/ScreenConozcanos.js'
import ScreenNuestroTrabajo from '../screenNuestroTrabajo/ScreenNuestroTrabajo.js'
import ScreenAporte from '../screenAporte/ScreenAporte.js'
import ScreenEducacion from '../screenEducacion/ScreenEducacion.js'
import ScreenVoluntariado from '../screenVoluntariado/ScreenVoluntariado.js'
import ScreenContactanos from '../screenContactanos/ScreenContactanos.js'
import ScreenLogin from '../screenLogin/ScreenLogin.js'
import ScreenDonaVisitaPatrocinaFoot from '../screenDonaVisitaPatrocinaFoot/ScreenDonaVisitaPatrocinaFoot'
import ScreenPatrocina from '../screenPatrocina/ScreenPatrocina'
import ScreenReporte from '../screenReporte/ScreenReporte.js'
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'

export default function ScreenInicio (props) {
  const [message, setMessage] = React.useState([])
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [openModal, setOpenModal] = useState(true);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);
  function handleCloseMsj () {
    setOpenSuccess(false)
  }
return(
    <div class=""> 
      {/*<div style={{ width: '100%', height: '20px', backgroundColor: '#5dbf82' }}></div>*/}
      
       <Carousel />
        <div>
         <FloatingWhatsApp phone='+59899917811' size='50px' headerTitle='Karumbé' zIndex='9999' popupMessage='Hola, ¿En qué podemos ayudarte?'/>
     
            
             <Section
                
                title="Conozcanos"
                subtitle={<ScreenConozcanos/>}
                dark={false}
                id='/conozcanos'
              />             
           
              <Section
                title="Conoce nuestro trabajo"
                subtitle={<ScreenNuestroTrabajo/>}
                dark={false}
                id='/nuestrotrabajo'
              />
              
              <Section
                title="Reporta tu animal"
                subtitle={<ScreenReporte/>}
                dark={false}
                id='/reportar'
              />
              
              <Section
                title="Reporta tu bichito"
                subtitle={<ScreenEducacion/>}
                dark={false}
                id='/educacion'
              />
              <Section
                title="Voluntariar"
                subtitle={<ScreenVoluntariado/>}
                dark={false}
                id='/voluntariado'
              />
              
              <ScreenDonaVisitaPatrocinaFoot />
              <ScreenPatrocina />
              <ScreenContactanos/>
              
              </div>
        
{/*        <Modal open={openModal} onClose={onCloseModal} center>
            <nav>
                <Link to="/colaborar" onClick={onCloseModal}>
                     <img src='images/popup/ayuda_socobioma.jpeg' />
                </Link>
            </nav>

      </Modal> */}
        
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} handleCloseMsj={handleCloseMsj} />
     </div>
  )  
 
}
