import React, { Component } from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'
import LocateControl from './LocateControl'

const locateOptions = {
  position: 'topright',
  strings: {
    title: 'Mostrar mi ubicacion'
  },
  onActivate: () => { } // callback before engine starts retrieving locations
}

const MyMarker = props => {
  const initMarker = ref => {
    if (ref) {
      ref.leafletElement.openPopup()
    }
  }

  return <Marker ref={initMarker} {...props} />
}

class MapLeaflet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPos: null
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    this.setState({ currentPos: e.latlng })
    this.props.setPosition(e.latlng)
  }

  render () {
    return (
      <div className='mapStyle'>
        <Map center={this.props.center} zoom={20} onClick={this.handleClick}>
          <TileLayer
            url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
          />
          <LocateControl options={locateOptions} startDirectly />
          {this.state.currentPos && <MyMarker position={this.state.currentPos} />}
        </Map>
      </div>
    )
  }
}

export default MapLeaflet
