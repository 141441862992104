import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './Carousel.css'
import ListItem from '@material-ui/core/ListItem'
import { Link as LinkForSection, animateScroll as scroll } from "react-scroll";

export default () => (
             <div className="carousel-container"> 
  <Carousel autoPlay interval={4500}  infiniteLoop  showThumbs={false} showArrows={false} showIndicators={true} showStatus={false}>
    {/*<div  class="imgCarousel">
        <img src='images/carousel_images/1.png' />
    </div>*/}
    <div  class="imgCarousel">
        <img src='images/carousel_images/CARRUSEL_1E.jpg'  class="rounded-image"/>

    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/CARRUSEL_2E.jpg' class="rounded-image"/>
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/CARRUSEL_4E.jpg'  class="rounded-image"/>
    </div>
    {/*<div  class="imgCarousel">
        <img src='images/carousel_images/5.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/6.jpg' />
    </div>
    <div  class="imgCarousel">
        <img src='images/carousel_images/7.jpg' />
    </div>*/}
  </Carousel>
  </div>
);

