import React from 'react'
import autoLogout from '../../../generalFunctions/autoSignout.js'
import { Card, CardContent, List, ListItem, Divider, Typography, AppBar, Toolbar, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import '../../../constants/card.css'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import Pagination from 'material-ui-flat-pagination'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import {
  isBrowser,
  isMobile,
  BrowserView,
  MobileView
} from 'react-device-detect';

import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'

import axios from 'axios'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import { ACCESS_TOKEN } from '../../../constants'
import ColumnaActividades from './ColumnaActividades/ColumnaActividades'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  containerTreatments: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.4)'
  },
  containerTreatmentsRed: {
    width: '100%',
    border: '1px solid rgba(219, 53, 20, 0.5)'
  },
  list: {
    overflowY: 'scroll'
  },
  containerTreatmentsGreen: {
    width: '100%',
    border: '1px solid rgba(119, 191, 63, 0.5)'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    marginBottom: '0',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    overflow: 'auto'
  },
  listIcon: {
    marginRight: '0px',
    color: '#77BF3F'
  },
  addIcon: {
    color: '#77BF3F'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  dialog: {
    margin: '4px',
    flexGrow: 1
  },
  titulo: {
    margin: 'auto',
    color: '#3C810D',
  },
  subdate: {
    margin: 'auto',
    color: '#3C810D',
  },
  appBar: {
    position: 'fixed',
    background: '#9FCC3C'
  },
  divFlex: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '33.33%',
    height: '100%',
    lineHeight: '100%',
    border: '1px solid rgba(0, 0, 0, 0.4)'
  },
  toolbar: theme.mixins.toolbar
}))

const themeAdd = createMuiTheme({
  palette: {
    primary: green
  }
})


export default function ScreenActiviadadesDelDia(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [insts, setInsts] = React.useState(null)
  const [instsSig, setInstsSig] = React.useState(null)
  const [instsAnt, setInstsAnt] = React.useState(null)
  const { promiseInProgress } = usePromiseTracker()
  const apiURL = process.env.REACT_APP_API_URL
  const [fecha, setFecha] = React.useState(new Date())
  const [widthTable, setWidthTable] = React.useState(0)
  const [dateState, setDateState] = React.useState({
    month: moment()
  })


  React.useEffect(() => {
    const url = `${apiURL}/calendario`
    const config = {
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
      params: {
        fecha: dateState.month.unix() * 1000
      }
    }
    trackPromise(
      axios.get(url, config).then((response) => {
        console.log(response.data)
        response.data.instancias.sort((inst1, inst2) => {
          var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
          const a = iOS ? new Date(inst1.fechaHora.substr(0, 19)) : new Date(inst1.fechaHora)
          const b = iOS ? new Date(inst2.fechaHora.substr(0, 19)) : new Date(inst2.fechaHora)
          if (a > b) {
            return 1
          }
          if (b > a) {
            return -1
          }
          return 0
        })
        const instancias = {
          animales: response.data.animales,
          instancias: response.data.instancias,
          tratamientos: response.data.tratamientos
        }
        setInsts(instancias)
      }).catch(error => {
        console.log('Error al obtener los tratamientos: \n', error)
        if (error.response.status === 401) {
          autoLogout()
        }
      }))
  }, [dateState])

  React.useEffect(() => {
    const url = `${apiURL}/calendario`
    const config = {
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
      params: {
        fecha: dateState.month.clone().add(1, 'days').unix() * 1000
      }
    }
    trackPromise(
      axios.get(url, config).then((response) => {
        console.log(response.data)
        response.data.instancias.sort((inst1, inst2) => {
          var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
          const a = iOS ? new Date(inst1.fechaHora.substr(0, 19)) : new Date(inst1.fechaHora)
          const b = iOS ? new Date(inst2.fechaHora.substr(0, 19)) : new Date(inst2.fechaHora)
          if (a > b) {
            return 1
          }
          if (b > a) {
            return -1
          }
          return 0
        })
        const instancias = {
          animales: response.data.animales,
          instancias: response.data.instancias,
          tratamientos: response.data.tratamientos
        }
        setInstsSig(instancias)
      }).catch(_error => {
        console.log('Error al obtener los tratamientos: \n', _error)
        if (_error.response.status === 401) {
          autoLogout()
        }
      }))
  }, [dateState])

  React.useEffect(() => {
    if (isBrowser) {
      const url = `${apiURL}/calendario`
      const config = {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
        params: {
          fecha: dateState.month.clone().subtract(1, 'days').unix() * 1000
        }
      }
      trackPromise(
        axios.get(url, config).then((response) => {
          console.log(response.data)
          response.data.instancias.sort((inst1, inst2) => {
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
            const a = iOS ? new Date(inst1.fechaHora.substr(0, 19)) : new Date(inst1.fechaHora)
            const b = iOS ? new Date(inst2.fechaHora.substr(0, 19)) : new Date(inst2.fechaHora)
            if (a > b) {
              return 1
            }
            if (b > a) {
              return -1
            }
            return 0
          })
          const instancias = {
            animales: response.data.animales,
            instancias: response.data.instancias,
            tratamientos: response.data.tratamientos
          }
          setInstsAnt(instancias)
        }).catch(_error => {
          console.log('Error al obtener los tratamientos: \n', _error)
          if (_error.response.status === 401) {
            autoLogout()
          }
        }))
    }
  }, [dateState])

  function handleDecreaseMonth() {
    setDateState(
      prevState => {
        return { month: prevState.month.subtract(1, 'days') }
      },
      dateState.filterByDay
    )
  }

  function handleIncreaseMonth() {
    setDateState(prevState => ({ month: prevState.month.add(1, 'days') }), dateState.filterByDay)
  }

  React.useEffect(() => {
    function updateSize() {
      setWidthTable(window.innerWidth - 100)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <>
      <Card style={{ width: props.width }}>
        <List className={classes.root}>
          <ListItem>
            <Typography
              variant='h4'
              align='center'
              className={classes.titulo}
            >
              <span onClick={handleDecreaseMonth}>{'< '}</span>
              <span>Actividades diarias</span>
              <span onClick={handleIncreaseMonth}>{' >'}</span>

            </Typography>
          </ListItem>
        </List>
      </Card>
      <div>
        <BrowserView>
          <ColumnaActividades promiseInProgress={promiseInProgress} isMobile={false} insts={instsAnt} dateState={{ month: dateState.month.clone().subtract(1, 'days') }} />
          <ColumnaActividades promiseInProgress={promiseInProgress} isMobile={false} insts={insts} dateState={dateState} />
          <ColumnaActividades promiseInProgress={promiseInProgress} isMobile={false} insts={instsSig} dateState={{ month: dateState.month.clone().add(1, 'days') }} />
        </BrowserView>
        <MobileView>
          <ColumnaActividades promiseInProgress={promiseInProgress} isMobile insts={insts} dateState={dateState} />
          <ColumnaActividades promiseInProgress={promiseInProgress} isMobile insts={instsSig} dateState={{ month: dateState.month.clone().add(1, 'days') }} />
        </MobileView>
      </div>
    </>
  )
}
