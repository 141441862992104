import React from 'react'
import { Redirect } from 'react-router-dom'

export default function ScreenLogout () {
  window.localStorage.clear()

  return (
    <div>
      {<Redirect to='/' />}
    </div>
  )
};
