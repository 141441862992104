/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import './ScreenDonaVisitaPatrocinaFoot.css';
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    backgroundColor:'#55c5c1'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  radioButton: {
    margin: 'auto'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: 'auto',
    maxWidth: '842px',
    height: 'auto',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop:'5px'
    
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
  card: {
    width: '100%',
    maxWidth: '750px',
    marginTop: '5%',
    marginBottom:'3%',
    backgroundColor: 'rgb(32, 122, 126,0.95)',
   

  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  title: {
    color: '#3C810D'
  },
  subtitle:
  {
      
       fontFamily:'Source Sans Pro'
      
  },
  subtitleLink:
  {
        fontSize: '20px',
        color: 'white',
        fontFamily: 'TovariSans'
      
  },
  title1: {
         fontSize: '26px',
        color: '#3C810D',
        fontFamily: 'TovariSans'
       
    },
  title2: {
    //        color: 'rgb(32 122 126)'
     fontSize: '26px',
        color:'black',
        fontFamily: 'TovariSans'
    }
}))
const ScreenDonaVisitaPatrocinaFoot = () => {
const classes = useStyles()
  const handleClick = (id) => {
    // Lógica para manejar el clic en un círculo
    console.log(`Clic en círculo ${id}`);
    window.location.href = '#'+id;
  };

  return (
    <div className="contenedor">
      
      
      
       <div className="circulo" onClick={() => handleClick(3)}>
        <img src="images/dona_visita_sumate/VOLUNTARIAR.png" alt="Imagen 3" />
      </div>
      <div className="circulo" onClick={() => handleClick(2)}>
        <img src="images/dona_visita_sumate/VISITAR.png" alt="Imagen 2" />
      </div>
      <div className="texto-izquierda">
        <Typography align='center' className={classes.title2}>
                     ¿QUERÉS AYUDAR? <br /> 
        </Typography>
      </div>
     <div className="circulo" onClick={() => handleClick('/colabora')}>
        <img src="images/dona_visita_sumate/DONAR.png" alt="Imagen 1" />
      </div>
      <div className="circulo" onClick={() => handleClick(3)}>
        <img src="images/dona_visita_sumate/CONTACTAR.png" alt="Imagen 3" />
      </div>
    </div>
  );
};

export default ScreenDonaVisitaPatrocinaFoot;