import React from 'react'
import { useMediaQuery } from '@material-ui/core';
import Carousel from "./components/generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link as LinkForSection, animateScroll as scroll } from "react-scroll";
import clsx from 'clsx'
import './App.css'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { HashRouter as Router, Route, Link as LinkForPage } from 'react-router-dom'
import { ACCESS_TOKEN, ROLE } from './constants'
import ProtectedRoute from './components/generalComponents/ProtectedRoute/ProtectedRoute'

import Section from './components/generalComponents/Section/Section.js'
import SideBar from './components/sidebar/Sidebar.js'
import ScreenColabora from './components/screens/screenColabora/ScreenColabora.js'
import ScreenConozcanos from './components/screens/screenConozcanos/ScreenConozcanos.js'
import ScreenNuestroTrabajo from './components/screens/screenNuestroTrabajo/ScreenNuestroTrabajo.js'
import ScreenAporte from './components/screens/screenAporte/ScreenAporte.js'
import ScreenEducacion from './components/screens/screenEducacion/ScreenEducacion.js'
import ScreenVoluntariado from './components/screens/screenVoluntariado/ScreenVoluntariado.js'
import ScreenContactanos from './components/screens/screenContactanos/ScreenContactanos.js'
import ScreenInicio from './components/screens/screenInicio/ScreenInicio.js'
import ScreenLogin from './components/screens/screenLogin/ScreenLogin.js'
import ScreenLogout from './components/screens/screenLogout/ScreenLogout.js'
import ScreenReporte from './components/screens/screenReporte/ScreenReporte.js'
import ScreenAltaVoluntario from './components/screens/screenAltaVoluntario/ScreenAltaVoluntario'
import ScreenVerReporte from './components/screens/screenVerReporte/ScreenVerReporte'
import ScreenListarReportes from './components/screens/screenListarReportes/ScreenListarReportes.js'
import ScreenFichaAnimal from './components/screens/screenFichaAnimal/ScreenFichaAnimal'
import ScreenVerFichaAnimal from './components/screens/screenVerFichaAnimal/ScreenVerFichaAnimal'
import ScreenModificarFichaAnimal from './components/screens/screenModificarFichaAnimal/ScreenModificarFichaAnimal'
import ScreenListarFichas from './components/screens/screenListarFichas/ScreenListarFichas.js'
import ScreenVerTratamiento from './components/screens/screenVerTratamiento/ScreenVerTratamiento'
import ScreenVerHistorico from './components/screens/screenResumenAnimal/ScreenResumenAnimal'
import ScreenAltaFichaTratamiento from './components/screens/screenAltaFichaTratamiento/ScreenAltaFichaTratamiento'
import ScreenModificarFichaTratamiento from './components/screens/screenModificarFichaTratamiento/ScreenModificarFichaTratamiento'
import ScreenFichaDiaria from './components/screens/screenFichaDiaria/ScreenFichaDiaria'
import ScreenModificarFichaDiaria from './components/screens/screenModificarFichaDiaria/ScreenModificarFichaDiaria'
import ScreenVerUsuario from './components/screens/screenVerUsuario/ScreenVerUsuario'
import ScreenListarUsuarios from './components/screens/screenListarUsuarios/ScreenListarUsuarios'
import ScreenRealizarTratamiento from './components/screens/screenRealizarTratamiento/ScreenRealizarTratamiento'
import ScreenActiviadadesDelDia from './components/screens/screenActividadesDelDia/ScreenActividadesDelDia'
import ScreenAnimalesRehabilicaion from './components/screens/screenListarFichas/ScreenAnimalesRehabilicaion.js'
import ScreenModificarUsuario from './components/screens/screenModificarUsuario/ScreenModificarUsuario'
import ScreenDonaVisitaPatrocinaFoot from './components/screens/screenDonaVisitaPatrocinaFoot/ScreenDonaVisitaPatrocinaFoot'
import ScreenPatrocina from './components/screens/screenPatrocina/ScreenPatrocina'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'
const menuItemsLogged = ['Inicio', 'Mi perfil', 'Actividades del día', 'Nueva ficha de ingreso', 'Animales en rehabilitación', 'Búsqueda de fichas', 'Reportes', 'Estadísticas', 'Voluntarios']

const menuItemsNotLogged = ['INICIO', '¿QUIENES SOMOS?', 'NUESTRO TRABAJO', 'REPORTAR TORTUGA', 'EDUCACIÓN AMBIENTAL', 'VOLUNTARIADO', 'TORTUGAS', "DONAR"]
const itemAcceso =  'LOGIN'
const itemTienda =  'Tienda'
const linksLogged = ['/inicio', '/perfil', '/actividades', '/ficha_ingreso', '/animales_rehabilitacion', '/fichas', '/reportes', '/estadisticas', '/listar_voluntarios']

const linksNotLogged = ['/inicio', '/conozcanos','/nuestrotrabajo' ,'/reportar', '/educacion', '/voluntariado', '/tortugas' ,'/colabora', '/contacto']
const linkAcceso =  '/login'
const linkTienda =  'https://karumbe.myshopify.com/'
const iconosLogged = ['iconos/home.png', 'iconos/mi_perfil.png', 'iconos/actividades_del_dia.png', 'iconos/nueva_ficha_ingreso.png', 'iconos/animales_en_rehabilitacion.png',
  'iconos/busqueda_de_fichas.png', 'iconos/reportes.png', 'iconos/estadisticas.png', 'iconos/administrar.png']
//Aportes
//menuItems Aportes
//linksLogged /aportes
//iconosLogged  iconos/aportes.png
const iconosNotLogged = ['iconos/home.png', 'iconos/conocenos.png', 'iconos/reportar_animal.png', 'iconos/educacion_ambiental.png', 'iconos/voluntariado.png',
  'iconos/apoyanos.png', 'iconos/contactanos.png']
const iconoAcceso = 'iconos/acceso.png'
const iconoTienda = 'iconos/shop.png'
const iconoHamburgerMenu = 'iconos/menu_blanco.png'
var show  = true; 
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  restOfScreen: {

  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
})
)

export default function App ()  {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(true)
   const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);
const isMobile = useMediaQuery('(max-width:1000px)');
const [isBlinking, setIsBlinking] = React.useState(false);
React.useEffect(() => {
  // Desplaza la ventana a la parte superior (0,0) cuando el componente se monta
  window.scrollTo(0, 0);

  // Configura el parpadeo del botón
  const interval = setInterval(() => {
    // Toggle entre añadir y quitar la clase de parpadeo cada 2 segundos
    setIsBlinking(prevState => !prevState);
  }, 1000);

  // Limpia el intervalo cuando el componente se desmonta
  return () => clearInterval(interval);
}, []);
 function handleDrawerOpenLogin () {
    setOpen(!open)
    setShow()
  }

  function handleDrawerOpen () {
    setOpen(!open)

  }
  
  function handleDrawerOpenPublic () {
    setOpen(!open)
    show = true;
  }
  
  
  function handleDrawerOpenPrivate () {
    setOpen(!open)
    show = false;
    
  }
  
  function setShow () {
     show = !show 
  }
const handleButtonClickSideBar = (seccionPath) => {
    handleDrawerOpenPublic ()
    // Comprueba si estamos en la página /home
    console.log(window.location.hash);
    if(seccionPath==='/colabora'){
        window.location.href = '#/colabora'; 
    }
    else if (window.location.hash === '#/') {
        console.log(seccionPath);
        if(seccionPath==='/inicio') {
            cancelar();
        }
        else{
      // Si estamos en la página /home, navega a la sección
      return;
        }
    } else {
      // Si no estamos en /home, redirige primero a /home y luego a la sección
      window.location.href = '#/';
      setTimeout(() => {
        const sectionElement = document.getElementById(seccionPath);
      if (sectionElement) {
        // Encontramos la sección, desplazarnos a ella
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
      }, 800);    
    }
  };
   const cancelar = function () {
    window.scrollTo(0, 0)
//    setRedirect(true)
  }
const handleButtonClick = (seccionPath) => {
    
    console.log(window.location.hash);
    if(seccionPath==='/colabora'){ //DEFINIMOS PRIMERO TODAS LAS POSIBLES PAGINAS EXTERNAS A LA SPA
        window.location.href = '#/colabora'; 
    }
    else if (window.location.hash === '#/') {// Comprueba si estamos en la página /home
        console.log(seccionPath);
        if(seccionPath==='/inicio') {
            cancelar();
        }else if(seccionPath==='/colabora'){
             window.location.href = '#/colabora'; 
        }
        else{
      // Si estamos en la página /home, navega a la sección
      return;
        }
    } else {
      // Si no estamos en /home, redirige primero a /home y luego a la sección
      window.location.href = '#/';
      setTimeout(() => {
        const sectionElement = document.getElementById(seccionPath);
      if (sectionElement) {
        // Encontramos la sección, desplazarnos a ella
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
      }, 800);    
    }
  };

  const iconoSidebar = { maxWidth: 25, maxHeight: 25 }

  const iconoHamburgerSidebar = { maxWidth:  30, maxHeight: 30 }
  const sideList = () => {
    const menuItems = window.localStorage.getItem(ACCESS_TOKEN) ? menuItemsLogged : menuItemsNotLogged
    const links = window.localStorage.getItem(ACCESS_TOKEN) ? linksLogged : linksNotLogged
    const iconos = window.localStorage.getItem(ACCESS_TOKEN) ? iconosLogged : iconosNotLogged
    
    return (
      <div
        className={classes.list}
        role='presentation'
        onClick={handleDrawerOpen}
        onKeyDown={handleDrawerOpen}
        style={{ backgroundColor:'white'}}
      >
        <nav >
       
          <List >
            {menuItems.map((text, index) => (
              <div key={text + 'div'}>
              {!window.localStorage.getItem(ACCESS_TOKEN) &&
                <ListItem button component={LinkForSection} onClick={() => handleButtonClickSideBar(links[index])} to={links[index]} activeClass="active" smooth={true} offset={-50} duration={500}  key={text} >
                {/*  <ListItemIcon>
                    <img style={iconoSidebar} src={'/' + iconos[index]} />
                  </ListItemIcon> */}
                 <ListItemText primary={<span style={{fontWeight:'bold',fontFamily:'Microsoft Yi Baiti', fontSize: '16px' }}>{text}</span>} />
                </ListItem>
                }
                {window.localStorage.getItem(ACCESS_TOKEN) &&
                  <ListItem button component={LinkForPage} onClick={handleDrawerOpenPrivate} to={links[index]} key={text}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src={'/' + iconos[index]} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
                }
                
                <Divider />
              </div>
              
            )
            
              
              )}
       {!window.localStorage.getItem(ACCESS_TOKEN) &&
        <div>
           {/* <ListItem button component="a" href="https://karumbe.myshopify.com/" >
                  <ListItemIcon>
                    <img style={iconoSidebar} src={'/' + iconoTienda} />
                  </ListItemIcon>
                  <ListItemText primary={itemTienda} />
            </ListItem> */}
            <ListItem button component={LinkForPage} to={linkAcceso} key={itemAcceso} onClick={handleDrawerOpenLogin}>
      
                </ListItem> 
        </div>
                }
            {window.localStorage.getItem(ROLE) === 'ADMIN' &&
              <div>
                <ListItem button component={LinkForPage} to='/gestion_voluntarios' key='Gestion de voluntarios' onClick={handleDrawerOpenPrivate}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/agregar_voluntario.png' />
                  </ListItemIcon>
                  <ListItemText primary='Ingresar voluntario' />
                </ListItem>
                <ListItem button component={LinkForPage} to='/aportes' key='aportes' onClick={handleDrawerOpenPrivate}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/aportes.png' />
                  </ListItemIcon>
                  <ListItemText primary='Aportes' />
                </ListItem>
                <Divider />
              </div>}
            {window.localStorage.getItem(ROLE) === 'VOL_FIN' &&
                    <div>
                <ListItem button component={LinkForPage} to='/aportes' key='aportes' onClick={handleDrawerOpenPrivate}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/aportes.png' />
                  </ListItemIcon>
                  <ListItemText primary='Aportes' />
                </ListItem>
                <Divider />
              </div>}
            {window.localStorage.getItem(ACCESS_TOKEN) &&
              <div>
                <ListItem button component={LinkForPage} to='/logout' key='logout' onClick={setShow}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/acceso.png' />
                  </ListItemIcon>
                  <ListItemText primary='Logout' />
                </ListItem>
                <Divider />
              </div>}
          </List>
          <div className='bottomText' >
            <i>Icon made by Freepik </i>
            <br></br>
            <i>  from www.flaticon.com</i>
          </div>
        </nav>
      </div>
    )
  }
  const topList = () => {
    const menuItems = window.localStorage.getItem(ACCESS_TOKEN) ? menuItemsLogged : menuItemsNotLogged
    const links = window.localStorage.getItem(ACCESS_TOKEN) ? linksLogged : linksNotLogged
    const iconos = window.localStorage.getItem(ACCESS_TOKEN) ? iconosLogged : iconosNotLogged
    
    return (
              <nav style={{  display: 'flex', justifyContent: 'space-between' }}> 
      <div >
             
            {menuItems.map((text, index) => (
            <button style={{ backgroundColor: text === 'INICIO' ? '#32A199' : 'transparent', color: 'rgb(32 122 126)', border: 'none', borderRadius: text === 'Inicio' ? '5px' : 'none', }}>
                    <div>
                      {!window.localStorage.getItem(ACCESS_TOKEN) && (
                        <ListItem button component={LinkForSection} to={links[index]} onClick={() => handleButtonClick(links[index])} activeClass="active" smooth={true} offset={-50} duration={500} key={text}>
                          {text === "INICIO" ? (
                            <div style={{ width: '100px', height: '46px', marginTop:'3px'}}>
                              <IconButton onClick={cancelar} href='/' style={{ width: '100px', height: '45px', backgroundColor: '#32A199' }}>
                                <img src='/topBar/logo_karumbe_transparente.png' className='responsive' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                              </IconButton>
                            </div>
                          ) : text === "DONAR" ? (

                                <IconButton 
                                    onClick={cancelar} 
                                    href='/' 
                                    style={{ width: '100%', height: '80%', backgroundColor: '#ffbc00ab', borderRadius: '10px' }}
                                    className={isBlinking ? 'blinking' : ''}
                                >
                                    <span style={{fontWeight:'bold',fontFamily:'Microsoft Yi Baiti', fontSize: '16px',color: 'darkblue' }}>{text}&nbsp;<span style={{fontFamily:'Microsoft Yi Baiti', fontSize: '14px',color:'black'}}>🖤</span></span>
                                </IconButton>
                              
                          ) : (
                            <ListItemText primary={<span style={{fontWeight:'bold',fontFamily:'Microsoft Yi Baiti', fontSize: '16px' }}>{text}</span>} />
                          )
                        }
                        </ListItem>
                      )}
                      {window.localStorage.getItem(ACCESS_TOKEN) && (
                        <ListItem button component={LinkForPage} to={links[index]} key={text}>
                          <ListItemIcon>
                            <img style={iconoSidebar} src={'/' + iconos[index]} />
                          </ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItem>
                      )}
                    </div>
              </button>
              
            )
            
              
              )}
       {!window.localStorage.getItem(ACCESS_TOKEN) &&
        <div>
{/*            <ListItem button component="a" href="https://karumbe.myshopify.com/" >
                  <ListItemIcon>
                    <img style={iconoSidebar} src={'/' + iconoTienda} />
                  </ListItemIcon>
                  <ListItemText primary={itemTienda} />
            </ListItem> */}
         {/*   <ListItem button component={LinkForPage} to={linkAcceso} key={itemAcceso} onClick={handleDrawerOpenLogin}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src={'/' + iconoAcceso} />
                  </ListItemIcon>
                  <ListItemText primary={itemAcceso} />
                </ListItem>*/}
        </div> 
                }
            {window.localStorage.getItem(ROLE) === 'ADMIN' &&
              <div>
                <ListItem button component={LinkForPage} to='/gestion_voluntarios' key='Gestion de voluntarios' onClick={handleDrawerOpenPrivate}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/agregar_voluntario.png' />
                  </ListItemIcon>
                  <ListItemText primary='Ingresar voluntario' />
                </ListItem>
                <ListItem button component={LinkForPage} to='/aportes' key='aportes' onClick={handleDrawerOpenPrivate}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/aportes.png' />
                  </ListItemIcon>
                  <ListItemText primary='Aportes' />
                </ListItem>
                <Divider />
              </div>}
            {window.localStorage.getItem(ROLE) === 'VOL_FIN' &&
                    <div>
                <ListItem button component={LinkForPage} to='/aportes' key='aportes' onClick={handleDrawerOpenPrivate}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/aportes.png' />
                  </ListItemIcon>
                  <ListItemText primary='Aportes' />
                </ListItem>
                <Divider />
              </div>}
            {window.localStorage.getItem(ACCESS_TOKEN) &&
              <div>
                <ListItem button component={LinkForPage} to='/logout' key='logout' onClick={setShow}>
                  <ListItemIcon>
                    <img style={iconoSidebar} src='/iconos/acceso.png' />
                  </ListItemIcon>
                  <ListItemText primary='Logout' />
                </ListItem>
                <Divider />
              </div>}
         {/*
          <div className='bottomText' >
            <i>Icon made by Freepik </i>
            <br></br>
            <i>  from www.flaticon.com</i>
          </div> */}

      </div>
      </nav>
    )
  }

  return (
	

    <div className='restOfScreenForInicioScreen2'>
      <Router>
        <div className='container'>
          <CssBaseline />
        <AppBar 
            style={{ background: 'rgba(255, 255, 255, 1)', zIndex: '1000' }}
            position='sticky'
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open
            })}
        >
                         
        <Toolbar>
            {isMobile || window.localStorage.getItem(ACCESS_TOKEN) ? 
      
           <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                 
                <div style={{ width: '100%', height: '64px'}}> 
                    <IconButton href='/'  style={{ width: '80px', height: '64px', backgroundColor: '#32A199',borderRadius:'8%' }}>
                        <img src='/topBar/logo_karumbe_transparente.png' className='responsive'  style={{ maxWidth: '100%', maxHeight: '100%'}} />
                    </IconButton>
                </div> 
                <div style={{ position: 'absolute', right: 0, top:'8px'  }}>
                    <IconButton edge="start" color="inherit" onClick={handleDrawerOpen}>
                        <MenuIcon style={{color:'black'}}/>
                    </IconButton>
                </div>
    <IconButton 
    onClick={cancelar} 
    href='#/colabora' 
    style={{ 
        width: '21%', 
        height: '54%', 
        backgroundColor: '#ffbc00ab', 
        borderRadius: '10px',        
        marginTop: '7px',
        right: '50px',
        position: 'absolute',
        top:'8px'
       }}
    className={isBlinking ? 'blinking' : ''}
>
    <span style={{fontWeight:'bold',fontFamily:'Microsoft Yi Baiti', fontSize: '16px',color: 'darkblue' }}>DONAR&nbsp;</span><span style={{fontFamily:'Microsoft Yi Baiti', fontSize: '10px',color:'black'}}>🖤</span>
</IconButton>
            </div>
                    : 
                topList()}
            </Toolbar>  
        </AppBar>
        <SwipeableDrawer
           open={open}
            onClose={handleDrawerOpen}
            onOpen={handleDrawerOpen}

          >
            {sideList()}
        </SwipeableDrawer>
        
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div>
             
              <Route  path='/colabora' exact component={ScreenColabora} />
              <Route path='/educacion' exact component={SideBar} />
              <ProtectedRoute path='/gestion_voluntarios' role='ADMIN' exact component={ScreenAltaVoluntario} />
             
              {/*<Route path='/voluntariado' exact component={SideBar} />*/}
              <Route path='/login' exact component={ScreenLogin} />
             
              <ProtectedRoute path='/perfil' exact component={ScreenVerUsuario} />
              <Route path='/logout' exact component={ScreenLogout} />
              <ProtectedRoute path='/reporte/:idReporte' exact component={ScreenVerReporte} />
              <ProtectedRoute path='/reportes' exact component={ScreenListarReportes} />              
              <ProtectedRoute path='/aportes' role='VOL_FIN' admin={true} exact component={ScreenAporte} />
              <ProtectedRoute path='/actividades_del_dia' exact component={SideBar} />
              <ProtectedRoute path='/alta_ficha_diaria/:idFichaAnimal' exact component={ScreenFichaDiaria} />
              <ProtectedRoute path='/modificar_ficha_diaria/:idFichaAnimal/:idFichaDiaria' exact component={ScreenModificarFichaDiaria} />
              <ProtectedRoute path='/ficha_ingreso' exact component={ScreenFichaAnimal} />
              <ProtectedRoute path='/modificar_ficha' exact component={ScreenModificarFichaAnimal} />
              <ProtectedRoute path='/fichas' exact component={ScreenListarFichas} />
              <ProtectedRoute path='/fichas/:idFicha' exact component={ScreenVerFichaAnimal} />
              <ProtectedRoute path='/historicos/:idFicha' exact component={ScreenVerHistorico} />
              <ProtectedRoute path='/ficha_tratamiento/:idTratamiento' exact component={ScreenVerTratamiento} />
              <ProtectedRoute path='/alta_tratamiento/:idFichaAnimal' exact component={ScreenAltaFichaTratamiento} />
              <ProtectedRoute path='/modificar_ficha_tratamiento/:idTratamiento' exact component={ScreenModificarFichaTratamiento} />
              <ProtectedRoute path='/usuario/:idUsuario' exact component={ScreenVerUsuario} />
              <ProtectedRoute path='/realizar_tratamiento/:idTratamiento' exact component={ScreenRealizarTratamiento} />
              <ProtectedRoute path='/actividades' exact component={ScreenActiviadadesDelDia} />
              <ProtectedRoute path='/listar_voluntarios' exact component={ScreenListarUsuarios} />
              <ProtectedRoute path='/animales_rehabilitacion' exact component={ScreenAnimalesRehabilicaion} />
              <ProtectedRoute path='/modificar_usuario/:idUsuario' exact component={ScreenModificarUsuario} />
               <Route path='/' exact component={ScreenInicio} />
            </div>
          </main>
        </div>
      </Router>
    </div>
  )
}
