import React from 'react'
import Card from '@material-ui/core/Card'
import '../../../constants/card.css'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { KarumbeTextField, KarumbeDateTimePicker, KarumbeButton } from '../../karumbe_components'
import { makeStyles } from '@material-ui/core/styles'
import { FiltersComboBox } from '../../generalComponents/FilterComponents/FiltersTextField.js'
import axios from 'axios'
import { ACCESS_TOKEN, MONTHS } from '../../../constants'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import CardContent from '@material-ui/core/CardContent'
import VerInstanciasRealizadas from '../screenVerTratamiento/verInstanciasRealizadas'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles({
  title: {
    color: '#3C810D',
    marginTop: 30,
    marginBottom: 15
  },
  error: {
    color: '#F0072D'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  margin: {
    marginTop: '15px'
  },
  dosis: {
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '40%'
  },
  radioGroup: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
    marginBottom: 'auto'
  },
  tipoDar: {
    backgroundColor: '#FCFAFA',
    width: '50%',
    marginLeft: 'auto',
    marginRight: '0px',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto'
  },
  lable: {
    marginRight: 'auto',
    marginTop: '20px',
    marginLeft: 'auto'
  },
  card: {
    maxWidth: '1500'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
})

export default function ScreenRealizarTratamiento (props) {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const [errorFecha, setErrorFecha] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [errorExito, setErrorExito] = React.useState(false)
  const { promiseInProgress } = usePromiseTracker()
  const [widthTable, setWidthTable] = React.useState(0)

  const [realizar, setRealizar] = React.useState({
    exito: null,
    comentario: null,
    fechaHora: props.location.state ? props.location.state.fechaHora.getTime() : null,
    fechaSeleccionada: props.location.state ? props.location.state.fechaHora.getTime() : null
  })
  const [ficha, setFicha] = React.useState({
    idFichaTratamiento: null,
    fechaHoraComienzo: null,
    tipoTratamiento: null,
    via: null,
    cantidadDosis: null,
    dosis: null,
    cantidadDar: null,
    dar: null,
    frecuencia: null,
    duracion: null,
    instruccionesEspeciales: null,
    idFichaAnimal: props.match.params.idFichaAnimal,
    instancias: []
  })

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/ficha_tratamiento/${props.match.params.idTratamiento}`
    trackPromise(
      axios.get(url, config).then((response) => {
        console.log(response.data)
        setFicha(response.data)
        if (!props.location.state) {
          setRealizar({ ...realizar, fechaHora: new Date().getTime() })
        }
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al cargar la ficha tratamiento \n', _error) // TODO show errors to the user
      }))
  }, [])

  React.useEffect(() => {
    function updateSize () {
      setWidthTable(window.innerWidth - 100)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const postRealizar = () => {
    var enviar = false
    var exito = false
    var fecha = false

    if (realizar.fechaHora == null) {
      setErrorFecha(true)
      enviar = false
    } else {
      setErrorFecha(false)
      fecha = true
    }

    if (realizar.exito == null) {
      setErrorExito(true)
      enviar = false
    } else {
      setErrorExito(false)
      exito = true
    }

    if (fecha && exito) {
      enviar = true
    }

    if (enviar && !realizar.fechaSeleccionada && ficha.instancias.filter(ins => {
      return ins.fechaHora === realizar.fechaHora
    }).length) {
      if (!window.confirm('Ya existe una instancia con esa fecha y hora. ¿Desea sobrescribirla?')) {
        return
      }
    }

    const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/ficha_tratamiento/${props.match.params.idTratamiento}/instancias_tratamiento`

    if (enviar) {
      console.log(realizar)
      trackPromise(
      axios.post(url, realizar, config).then(response => {
        setMessage('El tratamiento se ha realizado correctamente')
        setOpenSuccess(true)
        setOpenError(false)
        volver()
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        setMessage('Hubo un error al enviar los datos, por favor intente en otro momento')
        setOpenError(true)
        setOpenSuccess(false)
      }))
    } else {
      setMessage('Algunos campos requeridos requieren su atención')
      setOpenError(true)
      setOpenSuccess(false)
    }
  }

  const volver = () => {
    window.history.go(-1)
    window.scrollTo(0, 0)
  }

  const handleChangeDate = attribute => date => {
    setRealizar({ ...realizar, [attribute]: date.getTime() })
  }

  const handleChange = attribute => event => {
    setRealizar({ ...realizar, [attribute]: event.target.value })
  }

  function addLine (texto) {
    return (texto) ? texto.replace(/_/g, '/') : null
  }

  function convertDate () {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(ficha.fechaHoraComienzo.substr(0, 19)) : new Date(ficha.fechaHoraComienzo)
    const formattedDate = (currentDate.getDate()).toString() + ' de ' + MONTHS[currentDate.getMonth()] + ' de ' + currentDate.getFullYear().toString() + ', ' + currentDate.getHours().toString() + ':' + currentDate.getMinutes().toString().padStart(2, '0')
    return formattedDate
  }

  return (
    <div class='container'>
      <Card class='card'>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        <Typography align='center' className={classes.title} variant='h4'>
          Realizar tratamiento # {ficha.idFichaTratamiento}
        </Typography>
        {promiseInProgress
          ? (
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          )
          : (
            <List className={classes.root}>

              <ListItem>
                <Typography align='left' variant='subtitle2'>
                  Este tratamiento inicio el {ficha.fechaHoraComienzo && convertDate()}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='left' variant='subtitle2'>
                  Droga o procedimiento: {ficha.tipoTratamiento}
                </Typography>
              </ListItem>
              {ficha.medicamento &&
                <ListItem>
                  <Typography align='left' variant='subtitle2'>
                    Medicamento: {ficha.medicamento}
                  </Typography>
                </ListItem>}
              {ficha.via &&
                <ListItem>
                  <Typography align='left' variant='subtitle2'>
                    Via: {addLine(ficha.via)}
                  </Typography>
                </ListItem>}
              {ficha.cantidadDosis &&
                <ListItem>
                  <Typography align='left' variant='subtitle2'>
                    Dosis: {ficha.cantidadDosis} {addLine(ficha.dosis)}
                  </Typography>
                </ListItem>}
              {ficha.cantidadDar &&
                <ListItem>
                  <Typography align='left' variant='subtitle2'>
                    Dar: {ficha.cantidadDar} {addLine(ficha.dar)}
                  </Typography>
                </ListItem>}
              {ficha.instruccionesEspeciales &&
                <ListItem>
                  <Typography align='left' variant='subtitle2'>
                    Instrucciones especiales: {ficha.instruccionesEspeciales}
                  </Typography>
                </ListItem>}
              <ListItem>
                <Typography align='left' variant='subtitle2'>
                  El tratamiento se debe realizar cada {ficha.frecuencia} horas
                </Typography>
              </ListItem>
              <ListItem>
                <Typography align='left' variant='subtitle2'>
                  La duracion estimada es de {ficha.duracion} dias
                </Typography>
              </ListItem>

              <Typography style={{ marginTop: '25px', color: '#3C810D' }} align='center'>
                <i> Instancias Realizadas </i>
              </Typography>

              <ListItem>
                <VerInstanciasRealizadas height={300} width={widthTable} instancias={ficha.instancias} />
              </ListItem>

              <Typography style={{ marginTop: '25px', color: '#3C810D' }} align='center'>
                {realizar.fechaSeleccionada
                  ? <i> Modificar instancia </i>
                  : <i> Crear instancia </i>}
              </Typography>

              <ListItem>
                <KarumbeDateTimePicker
                  onChange={handleChangeDate('fechaHora')}
                  value={realizar.fechaHora}
                  label='Fecha y hora de realizacion'
                  error={errorFecha}
                />
              </ListItem>

              <ListItem>
                <FiltersComboBox
                  label='Exito del tratamiento* (en %)'
                  value={realizar.exito}
                  onChange={handleChange('exito')}
                  items={[null, 0, 25, 50, 75, 100]}
                  itemsTags={[' ', '0%', '25%', '50%', '75%', '100%']}
                  error={errorExito}
                />
              </ListItem>
              <ListItem>
                <KarumbeTextField
                  label='Comentarios'
                  value={realizar.comentario}
                  placeholder=''
                  type='number'
                  onChange={handleChange('comentario')}
                  maxLength={300}
                  rows={5}
                />
              </ListItem>
              <ListItem>
                <KarumbeButton
                  label='Volver'
                  onClick={volver}
                />
                {realizar.fechaSeleccionada
                  ? <KarumbeButton
                    label='Modificar'
                    onClick={postRealizar}
                  />
                  : <KarumbeButton
                    label='Crear'
                    onClick={postRealizar}
                  />}
              </ListItem>
            </List>)}
      </Card>
    </div>
  )
}
