import React from 'react'
import { Redirect } from 'react-router'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { ACCESS_TOKEN, ROLE } from '../../../constants'
import ErrorMessage from './ErrorMessage'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import { withRouter } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    maxWidth: 360,
    height: 'auto',
    width: '100%'

  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    maxWidth: 360,
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: 'auto',
    maxWidth: 360,
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'
  },
  fondoTransparente: {
  
  backgroundColor: 'rgba(255, 255, 255, .93)',
  margin: '5% 15% 0 15%',
  borderRadius: '5px',
  paddingBottom: '20px',
  }
}))

export default withRouter(function ScreenLogin (props) {
  const classes = useStyles()
  const [/* redirect, */setRedirect] = React.useState(false)
  const [username, setUsername] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [error, setError] = React.useState(false)
  const [inputError, setInputError] = React.useState(false)
  const { promiseInProgress } = usePromiseTracker()
  const loggedHome = '/perfil'
  // const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })

  const logInClick = function (event) {
    if (!username || !password) {
      setInputError(true)
    } else {
      var apiBaseUrl = process.env.REACT_APP_API_URL + '/auth/signin'
      var payload = {
        usernameOrEmail: username,
        password: password
      }
      trackPromise(
        axios.post(apiBaseUrl, payload).then(function (response) {
          window.localStorage.setItem(ACCESS_TOKEN, response.data.accessToken)
          window.localStorage.setItem(ROLE, response.data.role)
          setRedirect(true)
        }).catch((error) => {
          console.log(error)
          if (error.status !== 200) {
            setError(true)
          }
        })
      )
    }
  }

  return (
    <div>
      {window.localStorage.getItem(ACCESS_TOKEN) && <Redirect to={loggedHome} />}
      <Card class={classes.fondoTransparente} className={classes.card}>
        <ErrorMessage open={error} handleOpen={() => { setError(!error) }} />
        {promiseInProgress
          ? (
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          )
          : (
            <CardContent>
              <form className={classes.container} noValidate autoComplete='off'>
                <List className={classes.root}>
                  <ListItem>
                    <TextField
                      error={(username === null || username === '') && inputError}
                      id='outlined-user'
                      label='Usuario'
                      className={classes.textField}
                      margin='normal'
                      variant='outlined'
                      onChange={(event) => {
                        setUsername(event.target.value)
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      error={(password === null || password === '') && inputError}
                      id='outlined-password-input'
                      label='Contraseña'
                      className={classes.textField}
                      type='password'
                      autoComplete='current-password'
                      margin='normal'
                      variant='outlined'
                      onChange={(event) => {
                        setPassword(event.target.value)
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <Button onClick={logInClick} variant='outlined' color='primary' className={classes.button}>
                      Login
                    </Button>
                  </ListItem>

                </List>
              </form>
            </CardContent>
          )}
      </Card>
    </div>
  )
})
