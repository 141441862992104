export const API_BASE_URL = 'http://localhost:5000/api'

export const ACCESS_TOKEN = 'accessToken'
export const ROLE = 'role'

export const USERNAME_MIN_LENGTH = 3
export const USERNAME_MAX_LENGTH = 15

export const EMAIL_MAX_LENGTH = 40

export const PASSWORD_MIN_LENGTH = 6
export const PASSWORD_MAX_LENGTH = 20

export const MONTHS = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
  'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'
]

export const ANIMAL_STATES = {
  DESCONOCE: 'Desconoce',
  VIVO: 'Vivo',
  MUERTO: 'Muerto'
}

export const ANIMAL_FILE_STATE = {
  Liberado: 'Liberado',
  En_rehabilitación: 'Rehabilitación',
  Muerto: 'Muerto'
}
