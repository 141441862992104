/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react'
import { render } from "react-dom";
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import './style.css'
import ErrorMsg from './ErrorMsg'
import SuccessMessage from './SuccessMessage'
import { KarumbeTextField, KarumbeSelectProveedor, KarumbeMonthDatePicker, KarumbeSelect, KarumbeGridList, KarumbeInputFotos, KarumbeButton } from '../../karumbe_components'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import Typography from '@material-ui/core/Typography'
import './ScreenColabora.css'
import iconBROU from './brou.png'
import iconRedPagos from './redpagos.png'
import iconPayPal from './paypal-logo-4.png'
import iconOca from './oca.png'
import iconVisa from './visa.png'
import iconColectate from './logoCIco.png'
import mercadoPago from './MP_QR.jpeg'
import donarOnline from './donaronlineLogo.svg'
import donaFacil from './donafacil.svg'
import { Redirect } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import Avatar from '@material-ui/core/Avatar'
import { Container, Row, Col } from "reactstrap";
import Grid from '@material-ui/core/Grid'
const useStyles = makeStyles({
    card: {
    width: '100%',
    maxWidth: '750px',
    marginTop: '5%',
    marginBottom:'3%',
    backgroundColor: 'rgb(255, 255, 255,0.5)',
   

  },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '40%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
//        color: 'rgb(32 122 126)'
        color:'black',
        fontFamily: 'TovariSans'
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},
 h7: { fontSize:'12px',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
       
        width: 158,
        height: 158
    },
    subtitle:{
        fontSize:'20px',
        fontFamily:'TovariSans'
    },
     body: {
      
    fontFamily: 'Gil Sans MT',
      fontSize:'16px',
      textAlign:'justify',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
})

export default function ScreenColabora (props) {
  const classes = useStyles()
  //const positionY = window.pageYOffset
  //const positionX = window.pageXOffset
  const iconoSidebar = { maxWidth: 40, maxHeight: 60  }
  const [redirect, setRedirect] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [departamentos, setDepartamentos] = React.useState([])
  const apiURL = process.env.REACT_APP_API_URL
  const proveedorTarjeta = ['VISA', 'OCA']
  const moneda = ['$', 'USD']
  const monedaTags = ['PESOS', 'DOLARES']
  const [verFormulario, setVerFormulario] = React.useState(false)
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
  const [tarjeta, setTarjeta] = React.useState({
    nombreAportante: null,
    cedulaIdentidadAportante: null,
    departamentoAportante: null,
    direccionAportante: null,
    telefonoAportante: null,
    emailAportante: null,
    proveedorTarjetaAportante: null,
    numeroTarjetaAportante: null,
    fechaVencimientoTarjetaAportante: null,
//    codigoSeguridadAportante: null,
    monedaAportante: null,
    montoAportante: null
  })
  React.useEffect(() => {
        // Desplaza la ventana a la parte superior (0,0) cuando el componente se monta
        window.scrollTo(0, 0);
    }, []);
   function limpiarFormulario () {
    setError(false)
    setOpenSuccess(false)
    setOpenError(false)
    setTarjeta({
    ...tarjeta,
    nombreAportante: null,
    cedulaIdentidadAportante: null,
    departamentoAportante: null,
    direccionAportante: null,
    telefonoAportante:null,
    emailAportante: null,
    proveedorTarjetaAportante: null,
    numeroTarjetaAportante: null,
    fechaVencimientoTarjetaAportante: null,
//    codigoSeguridadAportante: null,
    monedaAportante: null,
    montoAportante: null
    })
  }
  const [notifyEmail, setNotifyEmail] = React.useState(false)

 
  const cancelar = function () {
    window.scrollTo(0, 0)
//    setRedirect(true)
  }
  
   function handleCloseMsj() {
        setOpenSuccess(false)
    }
  const { promiseInProgress } = usePromiseTracker()

return (
        <div class="restOfScreenForColaboraScreen">
            <Container> 
                    <div class="container">
                        <Card className={classes.card}>
                        <CardContent className={classes.grid}>
                            <Grid
                                container
                                spacing={0}
                                alignItems='center'
                                justify='center'
                                textOverflow='hidden'
                                className={classes.grid}
                                >
                                <Table className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center'>
                                                <Typography className={classes.title} variant='h4'>
                                                    Donaciones / Donations
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  align='center'>
                                                <Typography className={classes.subtitle} >
                                                  <b>¿Sabías qué?</b> Cada año Karumbé recibe decenas de tortugas marinas y de agua dulce que precisan rehabilitación para poder devolverlas sanas a su hábitat.
                                                </Typography>  
                                                </TableCell>
                                                </TableRow>      
                                        <TableRow>
                                        
                                            <TableCell align='center'>
                                            <Typography className={classes.body} >
                                                     Recibimos tortugas con hipotermia, lesiones en caparazón, lesiones en el cuerpo (enredadas en líneas de nylon y redes de pesca), ingesta de plásticos, ingesta de anzuelos, perdida de aletas, neumonía y tumores entre otros problemas.
                                                    </Typography>
                                                <Avatar  alt='' src={'/images/colabora/colabora_1.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/colabora/colabora_2.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/colabora/colabora_3.jpg'} className={classes.bigAvatar} />
                                                <Avatar  alt='' src={'/images/colabora/colabora_4.jpg'} className={classes.bigAvatar} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography className={classes.body} >
                                                        Con tu donación podremos realizar los rescates y comprar medicamentos, guantes, jeringas, algodón, suero, antisépticos y termostatos para efectuar la rehabilitación.
                                               </Typography>
                                            </TableCell>
                                        </TableRow>
                                         <TableRow style={{textAlign:'center'}}>
                                         <TableCell>
                    <Typography className={classes.h6} style={{textAlign:'left'}}>
                    Tu apoyo es <b>muy importante</b> para lograr los rescates y la rehabilitación.
                    </Typography>
                    <TableCell  align='center' className={classes.bigAvatar}>                 
                        <Typography className={classes.h7} style={{textAlign:'center'}}>
                            <img style={{maxHeight:'30px'}} src={iconBROU} />
                        </Typography>
                        <Typography className={classes.h7} style={{textAlign:'left'}}>
                            <b>-CA en $: 001533014-00002 (Nº anterior: 196-0450075)</b>
                        </Typography>
                        <Typography className={classes.h7} style={{textAlign:'left'}}>
                            <b>-CA en USD: 001533014-00001 (Nº anterior: 196-0449970)</b>
                        </Typography>
                    </TableCell>
                    
                    <TableCell  align='center' className={classes.bigAvatar}>
                    <Typography className={classes.h6} style={{textAlign:'center'}} >
                           &nbsp;
                        </Typography>
                        
                         <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <a href="https://www.paypal.com/paypalme/karumbeturtles">
                                <input style={{maxHeight:'30px',marginTop:'4px'}} type="image" src={iconPayPal} border="0" name="submit" title="Paypal" alt="Donate with Paypal button"  />
                            </a>
                        </Typography>
                           &nbsp;
                     <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <a href="https://donafacil.uy/organizador/karumbe">
                                <input style={{maxHeight:'90px'}} type="image" src={donaFacil} border="0" name="submit" title="DonaFacil" alt="Donate with DonarOnline button"  />
                            </a>
                        </Typography>   
                        
                  
                        
                        
                    </TableCell>
                    <TableCell  align='center' className={classes.bigAvatar}>
                    <Typography className={classes.h6} style={{textAlign:'center'}} >
                           &nbsp;
                        </Typography>
                    
                      
                        
                     
                          <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <a href="https://colectate.com.uy/colecta/participar.php?ID=10620&fbclid=IwAR2TL6LVkREOCbtaayBkc7G0AVLITasICvgKW59nPLHbqJNb3g4dEzAEvTw">
                                <input style={{maxHeight:'45px',marginBottom:'5px'}} type="image" src={iconColectate} border="0" name="submit" title="Colectate" alt="Donate with Colectate button"  />
                            </a>
                        </Typography>
                        <Typography className={classes.h6} style={{textAlign:'center'}} >
                            <a href="https://donaronline.org/karumbe/uruguay-rehabilitacion-de-tortugas-para-volver-a-su-habitat">
                                <input style={{maxHeight:'90px'}} type="image" src={donarOnline} border="0" name="submit" title="DonarOnline" alt="Donate with DonarOnline button"  />
                            </a>
                        </Typography>
                       
                        
                    </TableCell>
                   
                    <Typography className={classes.h6} style={{textAlign:'center'}} >
                           &nbsp;
                        </Typography>
                         <Typography className={classes.body} >
                                 INTERNACIONAL <br/>
                                 Si vivís en el exterior y queres ayudar en el rescate y rehabilitación de las tortugas podes solicitar nuestras cuentas en Europa, Estados Unidos (Zelle), Brasil (PIX) o Argentina al email: <b>karumbemail@gmail.com</b> <br/>
                                 INTERNATIONAL <br/>
                                Do you live abroad and want to help in the rescue and rehabilitation of turtles? <br/>
                                You can apply for our accounts at in Europe, United States (Zelle), Brazil (PIX) or Argentina at email <b>karumbemail@gmail.com</b>
                        </Typography>
                    </TableCell>
                    </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                        
                        </Card>
                    </div>
            </Container>
        </div>
            
)
}


