import React from 'react'
import axios from 'axios'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import 'react-responsive-ui/style.css'
import MuiPhoneNumber from 'material-ui-phone-number'
import { ACCESS_TOKEN, ROLE } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { KarumbeTextField, KarumbeSelect, KarumbeInputFotos, KarumbeGridList, KarumbeButton } from '../../karumbe_components'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { Redirect } from 'react-router-dom'
import { DialogActions, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import autoLogout from '../../../generalFunctions/autoSignout'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: 'auto',
    maxWidth: '842px',
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  title: {
    color: '#3C810D'
  },
  bigAvatar: {
    width: 70,
    height: 70
  }
}))

export default function ScreenModificarUsuario(props) {
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const classes = useStyles()
  const [departamentos, setDepartamentos] = React.useState([])
  const [deptoError, setDeptoError] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [contactoError, setContactoError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [usuarioError, setUsuarioError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [showOldPass, setShowOldPass] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [passError, setPassError] = React.useState(false)
  const [cambiarPass, setCambiarPass] = React.useState(false)
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })
  const [redirect, setRedirect] = React.useState(false)
  const [fotoPerfil, setFotoPerfil] = React.useState([])
  const [nombreVacio, setNombreVacio] = React.useState(false)
  const [apellidoVacio, setApellidoVacio] = React.useState(false)
  const [usuario, setUsuario] = React.useState(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [cambioPassData, setcambioPassData] = React.useState({
    idUsuario: null,
    newPassword: null,
    oldPassword: null
  })

  const handleChange = attribute => event => {
    setUsuario({ ...usuario, [attribute]: event.target.value })
  }

  const handleChangePass = attribute => event => {
    setcambioPassData({ ...cambioPassData, [attribute]: event.target.value })
    console.log(cambioPassData)
  }

  const handleChangeUserName = (event) => {
    setUsuario({ ...usuario, nombreUsuario: event.target.value.trim() })
  }

  function handlePassToggle(event) {
    setCambiarPass(!cambiarPass)
  }

  React.useEffect((event) => {
    if (!cambiarPass) {
      setcambioPassData({ ...cambioPassData, oldPassword: null, newPassword: null })
    }
  }, [cambiarPass])

  function handleChangePhone(value) {
    if (value.length > 13) {
      value = value.slice(0, 13)
    }
    setUsuario({ ...usuario, contacto: value })
  }

  function handleChangeEmail(event) {
    setUsuario({ ...usuario, correo: event.target.value.trim() })
  }
  const handleClickOpen = () => {
    console.log('clicked')
    setOpenDialog(true)
    setCambiarPass(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setCambiarPass(false)
    setPassError(false)
    setcambioPassData({ ...cambioPassData, newPassword: null, oldPassword: null })
  }
  React.useEffect((event) => {
    setError(false)
    setOpenError(false)
    setOpenSuccess(false)
    setEmailError(false)
    setUsuarioError(false)
    setPassError(false)
    //setContactoError(false)
    setNombreVacio(false)
    setApellidoVacio(false)
    setDeptoError(false)
  }, [usuario])

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        setDepartamentos(response.data.map((obj) => {
          return obj.nombre
        }))
      }).catch(responseError => {
        console.log(responseError)
      }))
  }, [])

  React.useEffect((event) => {
    var url = `${apiURL}/usuarios/${props.match.params.idUsuario}`
    setcambioPassData({ ...cambioPassData, idUsuario: props.match.params.idUsuario })
    trackPromise(
      axios.get(url, config).then((response) => {
        setUsuario(response.data)
        console.log(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener usuario: \n', _error)
      }))
  }, [])

  const postCambioPass = function () {
    var abrioErrorPass = false
    console.log(cambiarPass.newPassword)
    if (cambiarPass && (cambioPassData.newPassword == null || cambioPassData.newPassword.length < 6 || cambioPassData.newPassword.length > 20)) {
      if (!abrioErrorPass) {
        setMessage('El password debe ser de 6 a 20 caracteres')
        setOpenError(true)
        abrioErrorPass = true
      }
      setPassError(true)
    }

    if ((cambiarPass && (cambioPassData.newPassword === null || cambioPassData.newPassword !== confirmPassword))) {
      if (!abrioErrorPass) {
        setMessage('Los password no coinciden')
        setOpenError(true)
        abrioErrorPass = true
      }
      setPassError(true)
    }

    if ((cambiarPass && (cambioPassData.newPassword == null || cambioPassData.newPassword.length < 6 ||
      cambioPassData.newPassword.length > 20 || (cambioPassData.newPassword !== confirmPassword)))) {
      setError(true)
      window.scrollTo(0, 0)
    } else {
      var url = `${apiURL}/usuarios/modificar_password`
      trackPromise(
        axios.post(url, cambioPassData, config).then(response => {
          setOpenSuccess(true)
          setMessage('Contraseña modificada satisfactoriamente')
          setOpenDialog(false)
        }).catch(responseError => {
          if (responseError.response.status === 401) {
            autoLogout()
          }
          setMessage('Error al modificar la contraseña')
          setOpenError(true)
        })
      )
    }
  }

  const postModificarUsuario = function () {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    var abrioError = false
    console.log(usuario)
    if (usuario.nombre === '') {
      if (!abrioError) {
        setMessage('Debe especificar un nombre')
        setOpenError(true)
        abrioError = true
      }
      setNombreVacio(true)
    }

    if (usuario.apellido === '') {
      if (!abrioError) {
        setMessage('Debe especificar un apellido')
        setOpenError(true)
        abrioError = true
      }
      setApellidoVacio(true)
    }

    if ((usuario.correo === '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(usuario.correo)))) {
      if (!abrioError) {
        setMessage('El email ingresado no tiene el formato correcto')
        setOpenError(true)
        abrioError = true
      }
      setEmailError(true)
    }

    if (usuario.contacto.length < 12) {
      if (!abrioError) {
        setMessage('Debe especificar un número de contacto')
        setOpenError(true)
        abrioError = true
      }
      //etContactoError(true)
    }

    if (usuario.departamento === '') {
      if (!abrioError) {
        setMessage('Debe seleccionar un departamento')
        setOpenError(true)
        abrioError = true
      }
      setDeptoError(true)
    }

    if (usuario.nombreUsuario === '') {
      if (!abrioError) {
        setMessage('Debe especificar un nombre de usuario')
        setOpenError(true)
        abrioError = true
      }
      setUsuarioError(true)
    }

    if (usuario.nombre === '' || usuario.apellido === '' || usuario.nombreUsuario === '' ||
      usuario.contacto === '' || usuario.departamento === '' ||
      (usuario.contacto.length < 12) ||
      (usuario.correo === '' || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(usuario.correo)))) {
      setError(true)
      window.scrollTo(0, 0)
    } else {
      var url = `${apiURL}/usuarios/${props.match.params.idUsuario}/modificar`
      trackPromise(
        axios.post(url, usuario, config).then(response => {
          setOpenSuccess(true)
          setdataRedirect({ path: '/usuario/' + props.match.params.idUsuario, msj: 'El perfil de voluntario se ha modificado satisfactoriamente' })
          setRedirect(true)
          setMessage('El voluntario se ha modificado satisfactoriamente')
        }).catch(responseError => {
          if (responseError.response.status === 401) {
            autoLogout()
          }
          setMessage(responseError.response.data.message)
          if (responseError.response.data.message === 'El correo electrónico ya existe para otro voluntario.') {
            setEmailError(true)
          }
          if (responseError.response.data.message === 'El nombre de usuario ya existe para otro voluntario.') {
            setUsuarioError(true)
          }
          setOpenError(true)
        })
      )
    }
  }

  async function cargarFotoPerfil() {
    const headers = {
      'Content-Type': 'text/plain',
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) }
    }
    var url = `${apiURL}${usuario.foto}`
    axios.get(url, headers).then((response) => {
      setFotoPerfil([response.data])
    }).catch((_error) => {
      if (_error.response.status === 401) {
        autoLogout()
      }
      console.log('Error al obtener foto de perfil')
    })
  }

  React.useEffect((event) => {
    if (usuario && usuario.foto[0]) {
      cargarFotoPerfil()
    }
  }, [usuario])

  function handleFotoPerfil(e) {
    var reader = new window.FileReader()
    reader.readAsBinaryString(e.target.files[0])
    reader.onload = function () {
      var FormData = require('form-data')
      const data = new FormData()
      data.append('foto', window.btoa(reader.result))
      var url = `${apiURL}/fotos_usuario/${props.match.params.idUsuario}`
      var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
      axios.post(url, data, config).then(response => {
        setFotoPerfil([window.btoa(reader.result)])
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        console.log(responseError)
      })
    }
  }

  function RemoveFotoPerfil(e) {
    var result = window.confirm('Seguro quiere eliminar la foto de perfil? Este cambio sera permanente')
    if (result) {
      var url = `${apiURL}/fotos_usuario/${props.match.params.idUsuario}`
      var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
      // send http.delete photo id
      axios.delete(url, config).then(response => {
        setFotoPerfil([])
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        console.log(responseError)
      })
    }
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {redirect
          ? (
            <Redirect
              push={dataRedirect.push}
              to={{
                pathname: dataRedirect.path,
                state: { msj: dataRedirect.msj }
              }}
            />
          )
          : (promiseInProgress)
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : ((usuario)
              ? (
                <CardContent>
                  <Typography align='center' className={classes.title} variant='h4'>
                    Editar perfil
                  </Typography>
                  <form className={classes.container} noValidate autoComplete='off'>
                    <List className={classes.root}>
                      <ListItem>
                        <KarumbeTextField
                          label='Nombre*'
                          placeholder='Ingrese su nombre'
                          value={usuario.nombre}
                          error={nombreVacio && error}
                          onChange={handleChange('nombre')}
                          maxLength={45}
                        />
                      </ListItem>
                      <ListItem>
                        <KarumbeTextField
                          label='Apellido*'
                          maxLength={45}
                          placeholder='Ingrese su apellido'
                          value={usuario.apellido}
                          error={apellidoVacio && error}
                          onChange={handleChange('apellido')}
                        />
                      </ListItem>
                      <ListItem>
                        <KarumbeTextField
                          label='Correo*'
                          placeholder='ejemplo@karumbe.com'
                          value={usuario.correo}
                          error={emailError && error}
                          onChange={handleChangeEmail}
                          maxLength={45}
                        />
                      </ListItem>
                      <ListItem>
                        <MuiPhoneNumber
                          name='phone'
                          label='Teléfono'
                          data-cy='user-phone'
                          regions='south-america'
                          defaultCountry='uy'
                          countryCodeEditable={false}
                          autoFormat={false}
                          fullWidth='true'
                          value={usuario.contacto}
                          onChange={handleChangePhone}
                          maxLength={45}
                        />
                      </ListItem>
                      <KarumbeSelect
                        label='Departamento*'
                        value={usuario.departamento}
                        onChange={handleChange('departamento')}
                        error={deptoError && error}
                        items={departamentos}
                      />
                      <ListItem>
                        <KarumbeTextField
                          label='Nombre de usuario'
                          placeholder='Autogenenerado nombre.apellido'
                          value={usuario.nombreUsuario}
                          error={usuarioError && error}
                          onChange={handleChangeUserName}
                          maxLength={91}
                        />
                      </ListItem>

                      <ListItem>
                        <ExpansionPanel className={classes.root}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Typography className={classes.heading}>Fotos</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <List className={classes.root}>
                              <ListItem>
                                <KarumbeInputFotos
                                  onChange={handleFotoPerfil}
                                  id={1}
                                />
                                <KarumbeGridList
                                  items={fotoPerfil}
                                  onChange={RemoveFotoPerfil}
                                  col={1}
                                />
                              </ListItem>
                            </List>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </ListItem>

                      <Dialog onClose={handleCloseDialog} fullWidth aria-labelledby='necropsy-dialog-title' open={openDialog}>
                        <DialogTitle id='necropsy-dialog-title' onClose={handleCloseDialog}>
                          <Typography className={classes.title} align='center' variant='h5'>
                            {'Cambiar contraseña'}
                          </Typography>
                          <Typography variant='h6' align='center'>

                          </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                          <div>
                            {window.localStorage.getItem(ROLE) !== 'ADMIN' &&
                              <ListItem>
                                <TextField
                                  id='outlined-password-input'
                                  label='Contraseña actual'
                                  className={classes.textField}
                                  autoComplete='current-password'
                                  type={showOldPass ? 'text' : 'Password'}
                                  margin='normal'
                                  variant='filled'
                                  error={passError}
                                  onChange={handleChangePass('oldPassword')}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        <IconButton
                                          aria-label='toggle password visibility'
                                          onClick={() => { setShowOldPass(!showOldPass) }}
                                          onMouseDown={event => {
                                            event.preventDefault()
                                          }}
                                        >
                                          {showOldPass ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </ListItem>}
                            <ListItem>
                              <TextField
                                id='outlined-password-input'
                                label='Confirmar contraseña'
                                className={classes.textField}
                                autoComplete='current-password'
                                type={showPassword ? 'text' : 'Password'}
                                margin='normal'
                                variant='filled'
                                error={passError}
                                onChange={handleChangePass('newPassword')}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={() => { setShowPassword(!showPassword) }}
                                        onMouseDown={event => {
                                          event.preventDefault()
                                        }}
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <TextField
                                id='outlined-password-input'
                                label='Confirmar contraseña nueva'
                                className={classes.textField}
                                autoComplete='current-password'
                                type={showConfirmPassword ? 'text' : 'Password'}
                                margin='normal'
                                variant='filled'
                                onChange={(event) => { setConfirmPassword(event.target.value) }}
                                error={passError}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                        onMouseDown={event => {
                                          event.preventDefault()
                                        }}
                                      >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </ListItem>
                            <ListItem>
                              <Button
                                variant='outlined'
                                color='primary'
                                className={classes.button}
                                onClick={postCambioPass}
                              >
                                Cambiar contraseña
                              </Button>
                            </ListItem>
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <KarumbeButton onClick={handleCloseDialog} label='Cerrar' />
                        </DialogActions>
                      </Dialog>

                      <ListItem id='checkNotifications'>
                        <KarumbeButton onClick={handleClickOpen} label='Quiero cambiar la contraseña' />

                      </ListItem>
                      <ListItem>
                        <Button
                          variant='outlined'
                          color='primary'
                          className={classes.button}
                          onClick={postModificarUsuario}
                        >
                          Confirmar cambios
                        </Button>
                      </ListItem>
                    </List>
                  </form>
                </CardContent>
              ) : (
                <CardContent className={classes.card}>
                  <Typography align='center' className={classes.error} variant='h5'>
                    El usuario que usted solicita no se encuentra en el sistema.
                  </Typography>
                </CardContent>
              ))}
      </Card>
    </div>
  )
}
