import React from "react";
import '../../../App.css'

export default function Section({ title, subtitle, dark, id }) {   
 return (  
    <div className={"section" + (dark ? " section-dark" : "")}>       
      <div className={"section-content " } id={id}>
        {subtitle}
      </div>
    </div>
  );
}
