// TODO contendor pagina conozcanos.
// trae informacion del backend y llama a los componentes de presentacion pasandoles los datos pertinentes
import React, { useState, useEffect } from 'react';
import { render } from "react-dom";
import properties from 'properties-parser';
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'

import {  animateScroll as scroll } from "react-scroll";
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'

import Divider from '@material-ui/core/Divider'
import Carousel from "../../generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Container, Row, Col } from "reactstrap";
import  "./ScreenNuestroTrabajo.css";
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles({
//    card: {
//        width: '100%',
//        maxWidth: '650px',
//        maxHeight: '250px',
//        height: '100%',
//        marginBottom:'3%',
//        marginRight:'15px',
//        backgroundColor: 'rgba(0, 151, 200, 1)'
//
//    },
     card: {
        maxWidth: '1150px',
        height: '98%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        paddingBottom:'25px' // agregado 04/06

    },
    card1: {
        width: '100%',
        maxWidth: '400px',
        height: '100%',
        maxHeight: '250px',
        marginBottom:'3%',
        marginRight:'15px',
        backgroundColor: 'rgba(0, 151, 200, 1)'

    },
    cardGrande: {
        width: '100%',
        maxWidth: '650px',
        maxHeight: '250px',
        height: '100%',
        marginBottom:'3%',
        marginRight:'15px',
       backgroundColor: 'rgba(255, 255, 255, 0.7)'
        
    },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '10%'

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '100%',

    },
    title: {
         fontSize: '24px',
        color: '#3C810D',
        fontFamily: 'TovariSans'
  
    },
     title2: {
//        color: 'rgb(32 122 126)'
        color:'white',
        fontFamily: 'TovariSans'
    },
    title1: {
         fontSize: '18px',
        color: '#3C810D',
        fontFamily: 'TovariSans'
       
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},

    h7: {
    color: '#3C810D',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
        
    },
    dropdownButton: {
    backgroundColor: '#2aa9a3',
    opacity:'0.9',
    width: '100%',
    maxWidth: '1000px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
  flexDirection: 'column',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0 auto',
  },
  dropdownButton1: {
    backgroundColor: '#55c5c1',
    opacity:'0.9',
    width: '100%',
    maxWidth: '1000px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0 auto',
  },
   dropdownButton2: {
    backgroundColor:  '#79e2df',
    opacity:'0.9',
    width: '100%',
    maxWidth: '1000px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0 auto',
  },
  dropdownContent: {
    width: '100%',
    maxWidth: '1000px',
    marginTop: '1px',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)',
    background: 'rgba(255, 255, 255, 0.7)',
    margin: '0 auto',
  },
  dropdownContentExtern: {
    width: '100%',
    maxWidth: '1150px',
    marginTop: '1px',
    margin: '0 auto',
  },
  titleContent: {
    width: '100%',
    maxWidth: '1000px',
    marginTop: '1px',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)',
    background: 'rgba(255, 255, 255, 0.7)',
    margin: '0 auto',
    maxHeight:'80px'
  },
  body: {
      
      fontFamily: 'Gil Sans MT',
      fontSize:'16px',
      textAlign:'justify',
  }
  
})

export default function ScreenNuestroTrabajo() {
    const [message, setMessage] = React.useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const classes = useStyles()
    const [propertiesData, setPropertiesData] = React.useState([]);
   
  const [expandedNuestraHistoria, setExpandedNuestraHistoria] = useState(false);
    
  const handleExpandNuestraHistoria = () => {
    setExpandedNuestraHistoria(!expandedNuestraHistoria);
  };
   const [expandedNuestraMision, setExpandedNuestraMision] = useState(false);
    
  const handleExpandNuestraMision = () => {
    setExpandedNuestraMision(!expandedNuestraMision);
  };
  
  const [expandedOtro, setExpandedOtro] = useState(false);
    
  const handleExpandOtro = () => {
    setExpandedOtro(!expandedOtro);
  };
  
   const [expandedEducacion, setExpandedEducacion] = useState(true);
    
  const handleExpandEducacion = () => {
    setExpandedEducacion(!expandedEducacion);
  };
  
  const [expandedInvestigacion, setExpandedInvestigacion] = useState(true);
    
  const handleExpandInvestigacion = () => {
    setExpandedInvestigacion(!expandedInvestigacion);
  };
  
   const [expandedRehabilitacion, setExpandedRehabilitacion] = useState(true);
    
  const handleExpandRehabilitacion = () => {
    setExpandedRehabilitacion(!expandedRehabilitacion);
  };
    function handleCloseMsj() {
        setOpenSuccess(false)
    }
    async function loadPropertiesFile() {
        try {
          const response = await axios.get('textos/screenConozcanos.properties');
          const fileContent = response.data;
          // Analiza el contenido del archivo utilizando properties-parser
          setPropertiesData(properties.parse(fileContent));
          return propertiesData;
        }catch (error) {
            console.error('Error loading properties file:', error);
            return null;
        }
    }
    
    useEffect(() => {
        async function fetchData() {
            const propertiesData = await loadPropertiesFile();
        }fetchData();
    }, []);
    
    return (
            
    <div class="restOfScreenForNuestroTrabajoScreen" >
     
         <Container> 
           <div class="col center">
            <div className={classes.card}>
            <CardContent className={classes.grid}>
             <Grid
                container
                spacing={0}
                alignItems='center'
                justify='center'
                textOverflow='hidden'
                className={classes.grid}
            >
            <Table   className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell align='center'>
                            <Typography className={classes.title2}  variant='h4'>
                                NUESTROS PILARES
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
                <div className="nt-dropdown-container">
                    <div className="nt-dropdown-button" onClick={handleExpandInvestigacion}>
                      <div className="nt-circle-container">
                        <div className="nt-circle">
                          <img src="images/nuestroTrabajo/INVESTIGACION.jpg" alt="Imagen" />
                        </div>
                        <Typography className={classes.title1} variant="h6" style={{ fontWeight: 'bold', color: 'rgb(32 122 126)' }}>
                          INVESTIGACIÓN
                        </Typography>

                      </div>
                    </div>
                     <Collapse  in={expandedInvestigacion}>
                      <div className="nt-dropdown-content">
                        <Typography className={classes.body} >
                          Karumbé abarca varias líneas de investigación y en la actualidad se encuentra desarrollando diversos proyectos.
                        </Typography>

                      </div>
                      </Collapse>
                  </div>

        <div className="nt-dropdown-container">
            <div className="nt-dropdown-button" onClick={handleExpandEducacion}>
              <div className="nt-circle-container">
                <div className="nt-circle">
                  <img src="images/nuestroTrabajo/EDUCACION.JPG" alt="Imagen" />
                </div>
                <Typography className={classes.title1} variant="h6" style={{ fontWeight: 'bold', color: 'rgb(32 122 126)'}}>
                  EDUCACIÓN
                </Typography>
              </div>
            </div>
             <Collapse  in={expandedEducacion}>
              <div className="nt-dropdown-content">
                <Typography  className={classes.body}  >
                 Para Karumbé la Educación es uno de los pilares fundamentales para lograr un cambio positivo en pos del cuidado de la Naturaleza.
                </Typography>
              </div>
              </Collapse>
          </div>
          
           <div className="nt-dropdown-container">
                <div className="nt-dropdown-button" onClick={handleExpandRehabilitacion}>
                  <div className="nt-circle-container">
                    <div className="nt-circle">
                      <img src="images/nuestroTrabajo/REHABILITACION.jpg" alt="Imagen" />
                    </div>
                    <Typography className={classes.title1} variant="h6" style={{ fontWeight: 'bold', color: 'rgb(32 122 126)'}}>
                      REHABILITACIÓN
                    </Typography>
                  </div>
                </div>
                 <Collapse  in={expandedRehabilitacion}>
                  <div className="nt-dropdown-content">
                     <Typography className={classes.body} >
                          A lo largo de toda la costa uruguaya aparecen muchas tortugas que necesitan ayuda, siendo las causas principales obstrucción por plásticos, hipotermia, epibiota, descompresión, entre otros.
                        </Typography>
                  </div>
                  </Collapse>
              </div> 
           
          </Grid>
          </CardContent>
           </div>
            </div>
        </Container>       
    </div>
        )
}