import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import SelectDep from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  filter: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    position: 'fixed',
    top: '10',
    right: '0',
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: '#3EA91D'
    }
  },
  comboBox: {
    width: '100%'
  }
}))

export function FiltersTextField (props) {
  const classes = useStyles()

  return (
    <TextField
      label={props.label}
      value={props.value}
      className={classes.textField}
      InputProps={{ inputProps: { min: 0 } }}
      InputLabelProps={{
        shrink: true
      }}
      margin='normal'
      variant='outlined'
      min={0}
      type={props.type}
      onChange={props.onChange}

    />
  )
}

export function FiltersComboBox (props) {
  const classes = useStyles()
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <FormControl variant='outlined' className={classes.comboBox}>
      <InputLabel ref={inputLabel} htmlFor='outlined-age-simple'>
        {props.label}
      </InputLabel>
      <SelectDep
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        labelWidth={labelWidth}
      >
        {props.items && props.items.map((item, index) => {
          return (
            <MenuItem key={item} value={item}>
              {props.itemsTags ? props.itemsTags[index] : item}
            </MenuItem>
          )
        })}
      </SelectDep>
    </FormControl>
  )
}
