/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { KarumbeButton } from '../../karumbe_components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { Redirect } from 'react-router-dom'
import autoLogout from '../../../generalFunctions/autoSignout'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Container, Row, Col } from "reactstrap";

import TableHead from '@material-ui/core/TableHead'

const useStyles = makeStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    justifyContent: 'center'
  },
   card: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.58)'
  },
  title: {
    color: '#3C810D'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  error: {
    color: '#F0072D'
  },
  titleRecomendation: {
    color: '#FF7043'
  },
  tableMine: {
    width: '100%'
    
  },
  tableWrapper: {
    margin: 10,
    overflowX: 'auto',
    display: 'inline-block',
  }
})

export default function ScreenAporte() {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const [aportes, setAportes] = React.useState([])
  const [dataRedirect, setdataRedirect] = React.useState()
  const [redirect, setRedirect] = React.useState(false)
  const [widthTable, setWidthTable] = React.useState(0)
 
  // TABLA DE VER INSTANCIAS ------------------------------------------------
  const [rows, setRows] = React.useState()
 console.log(aportes)
  // TABLA DE VER INSTANCIAS ------------------------------------------------
  console.log(aportes ? aportes.map((obj,index) => {
    return createRow(index, obj)
  }) : [])
  

  const columns = [
    { id: 'C0', label: 'N°' },
    { id: 'C1', label: 'Fecha Realizado'},
    { id: 'C2', label: 'Nombre' },
    { id: 'C3', label: 'Teléfono' },
    { id: 'C4', label: 'Cédula de identidad' },
    { id: 'C5', label: 'Email' },
    { id: 'C6', label: 'Tipo de tarjeta' },
    { id: 'C7', label: 'Moneda' },
    { id: 'C8', label: 'Dirección' },
    { id: 'C9', label: 'Nº tarjeta' },
    { id: 'C10', label: 'Fecha vencimiento' },
    { id: 'C11', label: 'Monto' },
    { id: 'C12', label: 'Departamento' }
  ]
  function createData(C0, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11,C12) {
    return { C0, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11,C12 }
  }

  function nullToEmpty(string) {
    return !string ? '' : string
  }

  function createRow(index, instanciaT) {
    if (instanciaT) {
      return createData(index+1,
        convertDate(instanciaT.fecha),
        nullToEmpty(instanciaT.nombre),
        nullToEmpty(instanciaT.telefonoContacto),
        nullToEmpty(instanciaT.cedulaIdentidad),
        nullToEmpty(instanciaT.emailContacto),
        nullToEmpty(instanciaT.proveedorTarjeta),
        nullToEmpty(instanciaT.moneda),
        nullToEmpty(instanciaT.direccion),
        nullToEmpty(instanciaT.numeroTarjeta),        
        convertDateExpired(instanciaT.fechaVencimiento),     
        nullToEmpty(instanciaT.monto),
        nullToEmpty(instanciaT.departamento.nombre))
    }
  }
  React.useEffect(() => {
    function updateSize () {
      setWidthTable(window.innerWidth - 100)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  // FIN TABLA DE VER INSTANCIAS ------------------------------------------------

  function convertDate(fecha) {
    const currentDate = new Date(fecha)
    const formattedDate = (currentDate.getDate()).toString() + '/' + (currentDate.getMonth()+1).toString() + '/' + currentDate.getFullYear().toString()
    return formattedDate
  }
  
  function convertDateExpired(fecha) {
    const currentDate = new Date(fecha)
    const formattedDate =  (currentDate.getMonth()+1).toString() + '/' + currentDate.getFullYear().toString()
    return formattedDate
  }

  function getHour(fecha) {
    const date = new Date(fecha)
    const hours = date.getHours()
    const minutes = '0' + date.getMinutes()
    const formattedTime = hours + ':' + minutes.substr(-2)
    return formattedTime
  }
  React.useEffect((event) => {
    const url = `${apiURL}/aportes`
    trackPromise(
      axios.get(url, config).then((response) => {
        setAportes(response.data.aportes
            )

      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener el tratamiento: \n', _error)
      }))
  }, [])

 const { promiseInProgress } = usePromiseTracker()
return (

    <div className={classes.container}>
     
      {aportes.length === 0 ?
        <i>No hay tarjetas para mostrar</i> 
        : (promiseInProgress)
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : (
        <div style={{ maxHeight: aportes.height, width: widthTable}}  className={classes.tableWrapper}>
        <Table stickyHeader className={classes.tableMine}>
            <TableHead className={classes.tableMine}>
              {columns.map((column, index) => (
                <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyDay : classes.tableCell}>
                  {column.label}
                </TableCell>
              ))}
            </TableHead>
            {(aportes ? aportes.map((obj,index) => {
              return createRow(index, obj)
            }) : []).map(row => {
              console.log('Holaaa')
              return (
                <TableRow tabIndex={-1} key={row.code} className={classes.tableMine}  class="card">
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell style={{ padding: '10px' }} key={column.id} >
                        <p style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} >{column.format && typeof value === 'number' ? column.format(value) : value}</p>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </Table>
        </div>
                 


      )}

       </div>
   
    )
   

    
}