import React from 'react'
import Card from '@material-ui/core/Card'
import '../../../constants/card.css'
import Typography from '@material-ui/core/Typography'
import { InputAdornment } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { KarumbeTextField, KarumbeDateTimePicker, KarumbeButton, KarumbeSelect, KarumbeNumbersField } from '../../karumbe_components'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { ACCESS_TOKEN } from '../../../constants'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import CardContent from '@material-ui/core/CardContent'
import autoLogout from '../../../generalFunctions/autoSignout'
import { Redirect } from 'react-router-dom'


const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => {
  return (<Radio color='default' {...props} />)
})

const useStyles = makeStyles({
  title: {
    color: '#3C810D'
  },
  error: {
    color: '#F0072D'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  margin: {
    marginTop: '15px'
  },
  dosis: {
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '40%'
  },
  radioGroup: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
    marginBottom: 'auto'
  },
  tipoDar: {
    backgroundColor: '#FCFAFA',
    width: '50%',
    marginLeft: 'auto',
    marginRight: '0px',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto'
  },
  lable: {
    marginRight: 'auto',
    marginTop: '20px',
    marginLeft: 'auto'
  },
  card: {
    maxWidth: '1500'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
})

export default function ScreenModificarFichaTratamiento (props) {
  const [redirect, setRedirect] = React.useState(false)
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const [tiposTratamientos, setTiposTratamientos] = React.useState([])
  const [medicamentos, setMedicamentos] = React.useState([])
  const [errorFecha, setErrorFecha] = React.useState(false)
  const [errorTipoTratamiento, setErrorTipoTratamiento] = React.useState(false)
  const [errorDosis, setErrorDosis] = React.useState(false)
  const [errorDar, setErrorDar] = React.useState(false)
  const [errorFrecuencia, setErrorFrecuencia] = React.useState(false)
  const [errorDuracion, setErrorDuracion] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [dataRedirect, setdataRedirect] = React.useState({ path: '/', msj: null, push: true })

  const [ficha, setFicha] = React.useState({
    fechaHoraComienzo: null,
    tipoTratamiento: null,
    via: null,
    medicamento: null,
    cantidadDosis: null,
    dosis: null,
    cantidadDar: null,
    dar: null,
    frecuencia: null,
    duracion: null,
    instruccionesEspeciales: null,
    idFichaAnimal: props.match.params.idFichaAnimal
  })

  React.useEffect(() => {
    const url = `${apiURL}/tipos_tratamiento`
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    axios.get(url, config).then((response) => {
      setTiposTratamientos(response.data)
    }).catch(_error => {
      if (_error.response.status === 401) {
        autoLogout()
      }
      console.log('Error al ingresar el reporte:', _error) // TODO show errors to the user
    })
  }, [])

  React.useEffect(() => {
    const url = `${apiURL}/medicamentos`
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    axios.get(url, config).then((response) => {
      setMedicamentos(response.data)
    }).catch(_error => {
      if (_error.response.status === 401) {
        autoLogout()
      }
      console.log('Error al ingresar el reporte:', _error) // TODO show errors to the user
    })
  }, [])

  React.useEffect((event) => {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/ficha_tratamiento/${props.match.params.idTratamiento}`
    trackPromise(
      axios.get(url, config).then((response) => {
        setFicha(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al cargar la ficha tratamiento \n', _error) // TODO show errors to the user
      }))
  }, [])

  /*   React.useEffect(() => {
    console.log(tiposTratamientos)
    console.log([null].concat(tiposTratamientos.map(obj => obj.idTipoTratamiento)))
  }, [tiposTratamientos])
 */
  const postTreatment = () => {
    var fecha = false
    var tratamiento = false
    var frecuencia = false
    var duracion = false
    var enviar = false
    var dosis = false
    var dar = false

    if (ficha.fechaHoraComienzo == null) {
      setErrorFecha(true)
      fecha = false
    } else {
      setErrorFecha(false)
      fecha = true
    }
    if (ficha.tipoTratamiento == null || ficha.tipoTratamiento === 'Droga o procedimiento') {
      setErrorTipoTratamiento(true)
      tratamiento = false
    } else {
      setErrorTipoTratamiento(false)
      tratamiento = true
    }

    if (ficha.frecuencia == null || isNaN(ficha.frecuencia) ||
      ficha.frecuencia === '' || ficha.frecuencia <= 0) {
      setErrorFrecuencia(true)
      frecuencia = false
    } else {
      setErrorFrecuencia(false)
      frecuencia = true
    }

    if (ficha.duracion == null || isNaN(ficha.duracion) ||
      ficha.duracion === '' || ficha.duracion <= 0) {
      setErrorDuracion(true)
      duracion = false
    } else {
      setErrorDuracion(false)
      duracion = true
    }

    if (
      ((ficha.cantidadDosis > 0) && (ficha.dosis !== null)) ||
      ((ficha.cantidadDosis === null || isNaN(ficha.cantidadDosis) || ficha.cantidadDosis === '') && (ficha.dosis === null))
    ) {
      setErrorDosis(false)
      dosis = true
    } else {
      setErrorDosis(true)
      dosis = false
    }

    if (
      ((ficha.cantidadDar > 0) && (ficha.dar !== null)) ||
      ((ficha.cantidadDar === null || isNaN(ficha.cantidadDar) || ficha.cantidadDar === '') && (ficha.dar === null))
    ) {
      setErrorDar(false)
      dar = true
    } else {
      setErrorDar(true)
      dar = false
    }

    const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    const url = `${apiURL}/ficha_tratamiento`

    if (fecha && tratamiento && frecuencia && duracion && dosis && dar) {
      enviar = true
    } else {
      enviar = false
    }

    if (enviar) {
      trackPromise(
        axios.put(url, ficha, config).then(response => {
          setdataRedirect({ path: '/historicos/' + ficha.idFichaAnimal, msj: 'El tratamiento se ha modificado correctamente' })
          setRedirect(true)
          setMessage('El tratamiento se ha modificado correctamente')
          setOpenSuccess(true)
          setOpenError(false)
        }).catch(responseError => {
          if (responseError.response.status === 401) {
            autoLogout()
          }
          setMessage('Hubo un error al enviar los datos, por favor intente en otro momento')
          setOpenError(true)
          setOpenSuccess(false)
        })
      )
    } else {
      setMessage('Algunos campos requeridos requieren su atención')
      setOpenError(true)
      setOpenSuccess(false)
    }
  }

  const volver = () => {
    window.history.go(-1)
    window.scrollTo(0, 0)
  }

  const handleChangeDate = attribute => date => {
    setFicha({ ...ficha, [attribute]: date.getTime() })
  }

  const handleChange = attribute => event => {
    setFicha({ ...ficha, [attribute]: event.target.value })
  }

  const { promiseInProgress } = usePromiseTracker()

  return (
    <div class='container'>
      <Card class='card'>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {redirect
          ? (
            <Redirect
              push={dataRedirect.push}
              to={{
                pathname: dataRedirect.path,
                state: { msj: dataRedirect.msj }
              }}
            />
          )
          : (promiseInProgress)
            ? (
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            )
            : (
              <CardContent>
                <Typography align='center' className={classes.title} variant='h4'>
                  Modificar tratamiento #{props.match.params.idTratamiento}
                </Typography>
                <List className={classes.root}>
                  <ListItem>
                    <KarumbeDateTimePicker
                      onChange={handleChangeDate('fechaHoraComienzo')}
                      value={ficha.fechaHoraComienzo}
                      label='Fecha y hora de ingreso*'
                      error={errorFecha}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeSelect
                      label='Droga o procedimiento*'
                      value={ficha.tipoTratamiento}
                      onChange={handleChange('tipoTratamiento')}
                      items={['Droga o procedimiento'].concat(tiposTratamientos.map(obj => obj.nombre))}
                      error={errorTipoTratamiento}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeSelect
                      label='Medicamento'
                      value={ficha.medicamento}
                      onChange={handleChange('medicamento')}
                      items={['Medicamento'].concat(medicamentos)}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeSelect
                      label='Via'
                      value={ficha.via}
                      onChange={handleChange('via')}
                      itemsTags={['Oral', 'i/m', 'i/peritoneal', 'i/v', 'inhalacion', 'ocular', 'tópica(dérmica)', 'i/celómica']}
                      items={['oral', 'i_m', 'i_peritoneal', 'i_v', 'inhalación', 'ocular', 'tópica_dérmica', 'i_celómica']}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeNumbersField
                      label='Dosis'
                      value={ficha.cantidadDosis}
                      styleTextfield={classes.dosis}
                      placeholder=''
                      type='number'
                      onChange={handleChange('cantidadDosis')}
                      maxLength={7}
                      error={errorDosis}
                    />
                    <KarumbeSelect
                      label='(unidad dosis)'
                      value={ficha.dosis}
                      onChange={handleChange('dosis')}
                      items={[null, 'ml_kg', 'mg_kg']}
                      itemsTags={['unidad dosis', 'ml/kg', 'mg/kg']}
                      error={errorDosis}
                    />
                  </ListItem>

                  <ListItem>
                    <KarumbeNumbersField
                      label='Dar'
                      value={ficha.cantidadDar}
                      styleTextfield={classes.dosis}
                      placeholder=''
                      type='number'
                      onChange={handleChange('cantidadDar')}
                      maxLength={7}
                      error={errorDar}
                    />

                    <KarumbeSelect
                      label='(unidad a dar)'
                      value={ficha.dar}
                      onChange={handleChange('dar')}
                      items={[null, 'ml', 'Comprimidos', 'Gotas']}
                      itemsTags={['unidad a dar', 'ml', 'Comprimidos', 'Gotas']}
                      error={errorDar}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeNumbersField
                      label='Frecuencia * (en hrs.)'
                      value={ficha.frecuencia}
                      type='number'
                      onChange={handleChange('frecuencia')}
                      maxLength={7}
                      error={errorFrecuencia}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>Horas</InputAdornment>
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeNumbersField
                      label='Duracion * (en días)'
                      value={ficha.duracion}
                      placeholder=''
                      type='number'
                      onChange={handleChange('duracion')}
                      error={errorDuracion}
                      maxLength={7}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>Días</InputAdornment>
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeTextField
                      label='Instrucciones especiales'
                      value={ficha.instruccionesEspeciales}
                      placeholder=''
                      type='number'
                      onChange={handleChange('instruccionesEspeciales')}
                      maxLength={300}
                      rows={5}
                    />

                  </ListItem>
                  <ListItem>
                    <KarumbeButton
                      label='Volver'
                      onClick={volver}
                    />
                    <KarumbeButton
                      label='Modificar'
                      onClick={postTreatment}
                    />
                  </ListItem>
                </List>
              </CardContent>
            )}
      </Card>
    </div>
  )
}
