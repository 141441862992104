import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { KarumbeGridList, KarumbeButton } from '../../karumbe_components'
import { FiltersTextField } from '../../generalComponents/FilterComponents/FiltersTextField.js'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ACCESS_TOKEN, MONTHS } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { Map, TileLayer, Popup, Marker } from 'react-leaflet'
// import Button from '@material-ui/core/Button'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { Link } from 'react-router-dom'
import autoLogout from '../../../generalFunctions/autoSignout'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const useStyles = makeStyles({
  card: {
    marginTop: '20px',
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  title: {
    color: '#3C810D'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: '22px'
  },
  error: {
    color: '#F0072D'
  },
  button: {
    marginLeft: 'center',
    marginRight: 'center',
    marginTop: '31px',
    marginBottom: 'auto',
    maxWidth: 300,
    height: 'auto',
    width: '100%',
    backgroundColor: 'white'

  },
  cell: {
    padding: '14px 14px 14px 14px'
  },
  textLong: {
    wordBreak: 'break-word',
    maxWidth: '100%'
  }
})

export default function ScreenVerReporte (props) {
  const idUsuario = 1
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const [open, setOpen] = React.useState(false)

  const [checked, setChecked] = useState(false)

  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const { promiseInProgress } = usePromiseTracker()
  const [fotos, setFotos] = React.useState([])
  const [report, setReport] = React.useState()
  const [ficha, setFicha] = React.useState({
    idFicha: null
  })

  const handleChange = attribute => event => {
    const val = (!event.target.value) ? null : event.target.value
    setFicha({ ...ficha, [attribute]: Number.parseInt(val) })
  }

  const postVisto = function (event) {
    var url = `${apiURL}/reportes/${report.idReporte}/visto`
    trackPromise(
      axios.post(url, {}, config).then(response => {
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        console.log('Error al marcar visto: \n', responseError)
      })
    )
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCloseDiscard = () => {
    setOpen(false)
  }
  const volver = function () {
    window.history.go(-1)
    window.scrollTo(0, 0)
  }

  const postVincular = function () {
    var url = `${apiURL}/reportes/${report.idReporte}/vincular`
    if (ficha.idFicha === null || isNaN(ficha.idFicha)) {
      setMessage('Debe ingresar un Nro de ficha')
      setOpenError(true)
    } else if (ficha.idFicha === report.idFicha) {
      setMessage('El reporte ya se encuentra vinculado a este nro de ficha')
      setOpenError(true)
    } else {
      trackPromise(
        axios.post(url, ficha, config).then(response => {
          setMessage('El reporte ' + report.idReporte + ' se ha vinculado satisfactoriamente a la ficha ' + ficha.idFicha)
          setOpenSuccess(true)
          setOpen(false)
          if (!report.idVisto) {
            setReport({ ...report, idFicha: ficha.idFicha, idVisto: idUsuario, archivado: true })
            postVisto()
          } else {
            setReport({ ...report, idFicha: ficha.idFicha, archivado: true })
          }
        }).catch(responseError => {
          if (responseError.response.status === 401) {
            autoLogout()
          }
          setMessage(responseError.response.data)
          setOpenError(true)
        })
      )
    }
  }

  const postArchivar = function (event) {
    var url = `${apiURL}/reportes/${report.idReporte}/archivar`
    trackPromise(
      axios.post(url, {}, config).then(response => {
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoLogout()
        }
        console.log('Error al archivar: \n', responseError)
      })
    )
  }

  React.useEffect((event) => {
    const url = `${apiURL}/reportes/${props.match.params.idReporte}`
    trackPromise(
      axios.get(url, config).then((response) => {
        setReport(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener el reporte: \n', _error)
      }))
  }, [])

  function handleVisto (event) {
    if (!report.idVisto) {
      setReport({ ...report, idVisto: idUsuario })
      postVisto()
    } else {
      if (idUsuario === report.idVisto) {
        setReport({ ...report, idVisto: null })
      }
    }
  }

  function convertDate () {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const currentDate = iOS ? new Date(report.fecha.substr(0, 19)) : new Date(report.fecha)
    const formattedDate = (currentDate.getDate()).toString() + ' de ' + MONTHS[currentDate.getMonth()] + ' de ' + currentDate.getFullYear().toString() + ', ' + currentDate.getHours().toString() + ':' + currentDate.getMinutes().toString().padStart(2, '0')
    return formattedDate
  }

  function handleArchivado (event) {
    setReport({ ...report, archivado: !report.archivado })
    postArchivar()
  }

  async function cargarFotos () {
    const headers = {
      'Content-Type': 'text/plain',
      Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN)
    }
    var promises = []
    for (const link of report.fotosReportes) {
      var url = `${apiURL}${link}`
      promises.push(axios.get(url, { headers }))
    }
    try {
      var responses = await Promise.all(promises)
      setFotos(responses.map((foto) => { return foto.data }))
    } catch (_error) {
      console.log('Error al traer fotos: \n', _error)
    }
  }

  function toUpLowerCase (texto) {
    let aux = texto.toLowerCase()
    aux = aux.charAt(0).toUpperCase() + aux.slice(1)
    return aux
  }

  React.useEffect((event) => {
    if (report) {
      cargarFotos()
    }
  }, [report])

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {promiseInProgress
          ? (
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          )
          : ((report)
            ? (
              <CardContent>
                <Typography align='right' variant='subtitle2'>
                  Id Reporte: {report.idReporte}
                </Typography>
                <Typography align='center' className={classes.title} variant='h4'>
                  Reporte animal
                </Typography>
                {report.nombreContacto &&
                  <Typography align='center' variant='subtitle2'>
                    Realizado por: {report.nombreContacto}
                  </Typography>}
                {report.nombreVisto &&
                  <Typography align='center' variant='subtitle2'>
                    Visto por: {report.nombreVisto}
                  </Typography>}
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell align='left'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={report.idVisto}
                              style={{ color: 'green' }}
                              onChange={() => {
                                setChecked(!checked)
                                handleVisto()
                              }}
                              disabled={report.idVisto || checked}
                            />
                          }
                          label='Visto'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={report.archivado}
                              style={{ color: 'green' }}
                              onChange={handleArchivado}
                            />
                          }
                          label='Archivado'
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {convertDate()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {(report.telefonoContacto || report.emailContacto) &&
                  <Card className={classes.card}>
                    <CardContent>
                      <h5 align='left' className={classes.title}>Contacto</h5>
                      <Table className={classes.table}>
                        <TableBody>
                          {report.telefonoContacto &&
                            <TableRow>
                              <TableCell align='left'>Teléfono: </TableCell>
                              <TableCell align='left'>{report.telefonoContacto}</TableCell>
                            </TableRow>}
                          {report.emailContacto &&
                            <TableRow>
                              <TableCell align='left'>Mail: </TableCell>
                              <TableCell align='left'>{report.emailContacto}</TableCell>
                            </TableRow>}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>}
                <Card className={classes.card}>
                  <CardContent>
                    <h5 align='left' className={classes.title}>Animal</h5>
                    <Table className={classes.table}>
                      <TableBody>
                        {report.clase &&
                          <TableRow>
                            <TableCell align='left'>Clase: </TableCell>
                            <TableCell align='left'>{report.clase}</TableCell>
                          </TableRow>}
                        {report.nombreComunReporte &&
                          <TableRow>
                            <TableCell align='left'>Nombre Común: </TableCell>
                            <TableCell align='left'>{report.nombreComunReporte}</TableCell>
                          </TableRow>}
                        {report.estado &&
                          <TableRow>
                            <TableCell align='left'>Estado: </TableCell>
                            <TableCell align='left'>{toUpLowerCase(report.estado)}</TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <h5 align='left' className={classes.title}>Ubicación</h5>
                    <Table className={classes.table}>
                      <TableBody>
                        {report.departamento &&
                          <TableRow>
                            <TableCell align='left'>Departamento: </TableCell>
                            <TableCell align='left'>{report.departamento}</TableCell>
                          </TableRow>}
                        {report.ubicacion &&
                          <TableRow>
                            <TableCell align='left'>Ubicación: </TableCell>
                            <TableCell align='left' className={classes.textLong}>{report.ubicacion}</TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                    {report.latitud && report.longitud &&
                      <Map center={[report.latitud, report.longitud]} zoom={15}>
                        <TileLayer
                          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                        />
                        <Marker position={[report.latitud, report.longitud]}>
                          <Popup>
                            Posición donde se encuentre el animal encontrado.
                          </Popup>
                        </Marker>
                      </Map>}
                  </CardContent>
                </Card>
                {report.fotosReportes[0] &&
                  <Card className={classes.card}>
                    <CardContent>
                      <h5 align='left' className={classes.title}>Fotos</h5>
                      <KarumbeGridList
                        items={fotos}
                        noDeleteOption
                      />
                    </CardContent>
                  </Card>}
                {report.observaciones &&
                  <Card className={classes.card}>
                    <CardContent>
                      <h5 align='left' className={classes.title}>Observaciones:</h5>
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell align='left'>{report.observaciones}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>}
                <Card className={classes.card}>
                  <CardContent>
                    <h5 align='left' className={classes.title}>Correspondencia con fichas del sistema </h5>
                    <Table className={classes.table}>
                      <TableBody>
                        {report.idFicha &&
                          <TableRow>
                            <TableCell align='left'> <i> Asociado a <Link to={'/fichas/' + report.idFicha}> Ficha #{report.idFicha}</Link> </i> </TableCell>
                            <TableCell align='right'> </TableCell>
                          </TableRow>}
                        {!report.idFicha &&
                          <TableRow>
                            <TableCell align='left'> <i>El reporte no está asociado a ninguna ficha</i> </TableCell>
                            <TableCell align='right'> </TableCell>
                          </TableRow>}

                        <TableRow align='right'>
                          <div id='newLine' />
                          <Typography className={classes.heading}> Modificar asociación </Typography>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.cell} align='left'>
                            <Link
                              to={{
                                pathname: '/ficha_ingreso',
                                state: {
                                  baseReport: report,
                                  fotosReport: fotos
                                }
                              }} style={{ textDecoration: 'none' }}
                            >
                              <KarumbeButton
                                onClick={postVisto}
                                label='Crear ficha nueva'
                              />
                            </Link>
                          </TableCell>
                          <TableCell className={classes.cell} align='right'>

                            <KarumbeButton onClick={handleClickOpen} label='Asociar a ficha existente' />
                            <Dialog onClose={handleCloseDiscard} aria-labelledby='semiologic-dialog-title' open={open}>
                              <DialogTitle id='semiologic-dialog-title' onClose={handleCloseDiscard}>
                                Asociar a ficha existente
                              </DialogTitle>
                              <DialogContent dividers>
                                <FiltersTextField
                                  label='Nro de ficha'
                                  value={ficha.idFicha}
                                  type='number'
                                  onChange={handleChange('idFicha')}
                                  error={openError}
                                />

                              </DialogContent>
                              <DialogActions>
                                <KarumbeButton onClick={handleCloseDiscard} label='Cerrar' />
                                <KarumbeButton onClick={postVincular} label='Guardar' />
                              </DialogActions>
                            </Dialog>
                          </TableCell>

                        </TableRow>
                      </TableBody>
                    </Table>

                    <KarumbeButton
                      label='Volver' onClick={volver}
                    />
                  </CardContent>
                </Card>
              </CardContent>
            )
            : (
              <CardContent className={classes.card}>
                <Typography align='center' className={classes.error} variant='h5'>
                  El reporte que usted solicita no se encuentra en el sistema.
                </Typography>
              </CardContent>
            ))}
      </Card>
    </div>
  )
}
