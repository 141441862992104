import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/styles'
import gif from './Error acceso restringido.gif'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    maxWidth: 750,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}))

export default function AccesDenied (props) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent class='successCard'>
          <p>No podes estar aca, rapido volve a home antes que te vea el pinguino</p>
          <img src={gif} />
        </CardContent>
      </Card>
    </div>
  )
}
