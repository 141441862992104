import React, { useRef, useState, useEffect } from 'react';
import './ScreenPatrocina.css';

const ScreenPatrocina = () => {
  const patrocinarBtnRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imagesCar1 = [
    'images/patrocina/fallabrino_logo.png',
    'images/patrocina/meraki_brown_logo.png',
    'images/patrocina/ursal_logo.png',
    'images/patrocina/revaso.png',
    'images/patrocina/malafama.png',//,
    'images/patrocina/indie.png'
  ];
   const imagesCar1Links = [
    'http://www.bodegapablofallabrino.uy/',
    'https://merakisustentable.com/uy/',
    'http://www.urusal.com/',
    'https://www.revaso.uy/',
    'https://cervezamalafama.com/',//,
    'https://indie.uy/'
  ];
  const imagesCar2 = [
    'images/patrocina/meraki_brown_logo.png',
    'images/patrocina/ursal_logo.png',
    'images/patrocina/revaso.png',
    'images/patrocina/malafama.png',//,
    'images/patrocina/indie.png',
    'images/patrocina/fallabrino_logo.png'

  ];
  const imagesCar2Links = [
    'https://merakisustentable.com/uy/',
    'http://www.urusal.com/',
    'https://www.revaso.uy/',
    'https://cervezamalafama.com/',
    'https://indie.uy/',//,
    'http://www.bodegapablofallabrino.uy/'
  ];
    const imagesCar3 = [
    'images/patrocina/ursal_logo.png',
    'images/patrocina/revaso.png',
    'images/patrocina/malafama.png',//,
    'images/patrocina/indie.png',
    'images/patrocina/fallabrino_logo.png',
    'images/patrocina/meraki_brown_logo.png'
  ];
  const imagesCar3Links = [
    'http://www.urusal.com/',
    'https://www.revaso.uy/',
    'https://cervezamalafama.com/',
    'https://indie.uy/',
    'http://www.bodegapablofallabrino.uy/',//,
    'https://merakisustentable.com/uy/'
  ];
  const imagesCar4 = [
    
    'images/patrocina/revaso.png',
    'images/patrocina/malafama.png',//,
    'images/patrocina/indie.png',
    'images/patrocina/fallabrino_logo.png',
    'images/patrocina/meraki_brown_logo.png',
    'images/patrocina/ursal_logo.png',
  ];
  const imagesCar4Links = [
    'https://www.revaso.uy/',
    'https://cervezamalafama.com/',
    'https://indie.uy/',
    'http://www.bodegapablofallabrino.uy/',
    'https://merakisustentable.com/uy/',//,
    'http://www.urusal.com/'
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imagesCar1.length - 1 ? 0 : prevIndex + 1
      );
      if (patrocinarBtnRef.current) {
        patrocinarBtnRef.current.classList.toggle('scaling-animation');
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [imagesCar1.length]);

  const handleClick = (link) => {
    window.location.href = link;
  };

  return (
    <div className="screen-patrocina-container">
      <div className="image-links-container">
        {imagesCar1.map((image, index) => {
        let additionalClass = '';
        if (image === 'images/patrocina/ursal_logo.png') {
          additionalClass = 'pa-img-urusal';
        }
        if (image === 'images/patrocina/fallabrino_logo.png') {
          additionalClass = 'pa-img-fallabrino';
        }
        return (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className={`${currentImageIndex === index ? 'active' : ''} ${additionalClass}`}
            onClick={() => handleClick(imagesCar1Links[index])}
          />
        );
      })}
        {imagesCar2.map((image, index) => {
        let additionalClass = '';
        if (image === 'images/patrocina/ursal_logo.png') {
          additionalClass = 'pa-img-urusal';
        }
        if (image === 'images/patrocina/fallabrino_logo.png') {
          additionalClass = 'pa-img-fallabrino';
        }
        return (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className={`${currentImageIndex === index ? 'active' : ''} ${additionalClass}`}
            onClick={() => handleClick(imagesCar2Links[index])}
          />
        );
      })}
        {imagesCar3.map((image, index) => {
        let additionalClass = '';
        if (image === 'images/patrocina/ursal_logo.png') {
          additionalClass = 'pa-img-urusal';
        }
        if (image === 'images/patrocina/fallabrino_logo.png') {
          additionalClass = 'pa-img-fallabrino';
        }
        return (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className={`${currentImageIndex === index ? 'active' : ''} ${additionalClass}`}
            onClick={() => handleClick(imagesCar3Links[index])}
          />
        );
      })}
        {imagesCar4.map((image, index) => {
        let additionalClass = '';
        if (image === 'images/patrocina/ursal_logo.png') {
          additionalClass = 'pa-img-urusal';
        }
        if (image === 'images/patrocina/fallabrino_logo.png') {
          additionalClass = 'pa-img-fallabrino';
        }
        return (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className={`${currentImageIndex === index ? 'active' : ''} ${additionalClass}`}
            onClick={() => handleClick(imagesCar4Links[index])}
          />
        );
      })}
    <img
        ref={patrocinarBtnRef}
        src="images/patrocina/PATROCINAR_B.png"
        alt="Imagen 5"
        onClick={() => handleClick('link4')}
        className="active"
      />
      </div>
     
     
    </div>
  );
};

export default ScreenPatrocina;