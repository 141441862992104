import React from 'react'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Link } from 'react-router-dom'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN, ANIMAL_STATES } from '../../../constants'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import { FiltersTextField, FiltersComboBox /*, FiltersOrders */ } from '../../generalComponents/FilterComponents/FiltersTextField.js'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ToggleButton } from '@material-ui/lab'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import PageviewIcon from '@material-ui/icons/Pageview'
import IconButton from '@material-ui/core/IconButton'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles({
  card: {
    width: 280,
    height: 250,
    display: 'inline-block',
    margin: '5px 5px 5px 5px'
  },
  cardTitleSpinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    maxWidth: 1439,
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  },
  link: {
    underline: 'none',
    hover: 'red'
  },
  table: {
    padding: '15px 3px 15px 3px',
    margin: '3px'
  },
  fecha: {
    whiteSpace: 'wrap'
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .8)'
    }
  },
  filter: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    position: 'fixed',
    top: '10',
    right: '0',
    '&:active': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderColor: '#3EA91D'
    }
  },
  checkboxIcons: {
    color: '#20DD03'
  },
  checkboxIconsRed: {
    color: '#F53232'
  },
  checkboxText: {
    paddingLeft: '15px',
    color: '#757575'
  },
  checkboxContainer: {
    display: 'flex',
    alignContent: 'space-between'
  },
  root: {
    maxWidth: '261px',
    width: '100%',
    padding: '0px'
  },
  title: {
    color: '#3C810D'
  }
})

function CardReport({ reporte }) {
  const classes = useStyles()
  const tick = { maxWidth: 20, maxHeight: 20 }
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const currentDate = iOS ? new Date(reporte.fecha.substr(0, 19)) : new Date(reporte.fecha)
  console.log(reporte.visto)
  console.log(reporte.archivado)
  let vistoOArch
  if (reporte.visto && reporte.archivado) {
    vistoOArch = <> <img src='iconos/tick_naranja.png' style={tick} alt='Sin imagen' /> <i>Archivado, Visto por {reporte.visto}</i> </>
  } else if (reporte.archivado) {
    vistoOArch = <> <img src='iconos/tick_naranja.png' style={tick} alt='Sin imagen' /> <i>Archivado</i> </>
  } else if (reporte.visto) {
    vistoOArch = <> <img src='iconos/tick_celeste.png' style={tick} alt='Sin imagen' /> <i>Visto por {reporte.visto}</i> </>
  } else {
    vistoOArch = <> <img src='iconos/tick_gris.png' style={tick} alt='Sin imagen' /> <i>Sin ver</i> </>
  }

  return (
    <Link to={'/reporte/' + reporte.idReporte} underline='none' className={classes.hover}>
      <Card className={classes.card}>
        <Grid
          container
          spacing={0}
          alignItems='center'
          justify='center'
        >
          <CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align='left' className={classes.table}>
                    <Typography variant='h5'>
                      {reporte.nombreComunReporte}
                    </Typography>
                  </TableCell>
                  <TableCell align='right' className={classes.table}>
                    <Typography variant='h5'>
                      {ANIMAL_STATES[reporte.estado]}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.table}>
                    {reporte.departamento}
                  </TableCell>
                  <TableCell align='right' className={classes.table}>
                    {(currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear().toString() +
                      ' ' + currentDate.getHours() + ':' + String(currentDate.getMinutes()).padStart(2, '0')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={classes.table}>
                    {vistoOArch}
                  </TableCell>
                  <TableCell align='right' className={classes.table}>
                    Reporte #{reporte.idReporte}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Grid>
      </Card>
    </Link>
  )
}

export default function ScreenListarReportes() {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const [reportes, setReportes] = React.useState(null)
  const [offset, setOffset] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [departamentos, setDepartamentos] = React.useState([])
  const [animalClasses, setAnimalClasses] = React.useState([])
  const [filtrar, setFiltrar] = React.useState(true)
  const [porNumero, setPorNumero] = React.useState(false)
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [filters, setFilters] = React.useState({
    reportNumber: null,
    departamento: null,
    estado: null,
    clase: null,
    nombreComun: null,
    ordenFecha: true,
    pag: 1,
    vistos: false,
    archivados: null
  })

  const handleChange = attribute => event => {
    setFilters({ ...filters, [attribute]: event.target.value, pag: 1 })
    setOffset(0)
  }

  React.useEffect((event) => {
    if (!filtrar && porNumero) {
      if (filters.reportNumber === null || isNaN(filters.reportNumber) || filters.reportNumber < 0 || filters.reportNumber === '') {
        setOpenError(!openError)
        setMessage('Debe cargar un # de reporte válido')
        setPorNumero(false)
      } else {
        const url = `${apiURL}/reportes/${filters.reportNumber}`
        const config = {
          headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) }
        }
        setPorNumero(false)
        trackPromise(
          axios.get(url, config).then((response) => {
            setReportes({
              content: [response.data]
            })
          }).catch((_error) => {
            if (_error.response.status === 401) {
              autoLogout()
            }
            setReportes({
              content: []
            })
            setOpenError(!openError)
            setMessage('No existe un reporte con #' + filters.reportNumber + ' en el sistema')
            console.log('Error en el GET de reporte: \n', _error)
          }))
      }
    } else if (filtrar) {
      const url = `${apiURL}/reportes`
      const config = {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
        params: filters
      }
      trackPromise(
        axios.get(url, config).then((response) => {
          setReportes(response.data)
        }).catch((_error) => {
          if (_error.response.status === 401) {
            autoLogout()
          }
          console.log('Error en el GET de reportes: \n', _error) // TODO show errors to the user
        })
      )
    }
  }, [filters, filtrar, porNumero])

  React.useEffect((event) => {
    const url = `${apiURL}/departamentos`
    trackPromise(
      axios.get(url).then((response) => {
        const deps = [].concat(response.data.map((dep) => { return dep.nombre }))
        setDepartamentos(deps)
      }).catch((error) => {
        console.log(error)
      })
    )
  }, [])

  React.useEffect((event) => {
    const url = `${apiURL}/clases/reportes`
    trackPromise(
      axios.get(url).then((response) => {
        setAnimalClasses(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al ingresar el reporte: \n', _error) // TODO show errors to the user
      })
    )
  }, [])
  /*
  React.useEffect((event) => {
    console.log(filters)
  }, [filters]) */

  const { promiseInProgress } = usePromiseTracker()

  return (
    <div>
      <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
      <Button
        variant='outlined'
        className={classes.filter}
        onClick={() => { setOpen(true) }}
      >
        <FilterListIcon />
      </Button>
      <Drawer anchor='right' open={open} onClose={() => { setOpen(false) }}>
        <ExpansionPanel className={classes.root} expanded={!filtrar} onChange={() => { setFiltrar(!filtrar) }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h5' gutterBottom>Busquedas</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              <ListItem>
                <FiltersTextField
                  label='Nro de reporte'
                  value={filters.reportNumber}
                  className={classes.textField}
                  type='number'
                  onChange={handleChange('reportNumber')}
                />
                <IconButton
                  onClick={() => { setPorNumero(true) }}
                >
                  <PageviewIcon fontSize='large' />
                </IconButton>
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className={classes.root} expanded={filtrar} onChange={() => { setFiltrar(!filtrar) }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography variant='h5' gutterBottom>Filtros</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List className={classes.root}>
              <ListItem>
                <FiltersComboBox
                  label='Departamentos'
                  value={filters.departamento}
                  onChange={handleChange('departamento')}
                  items={[null].concat(departamentos)}
                  itemsTags={['Todos'].concat(departamentos)}
                />
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Estado del animal'
                  value={filters.estado}
                  onChange={handleChange('estado')}
                  items={[null, 'VIVO', 'MUERTO', 'DESCONOCE']}
                  itemsTags={['Todos', 'Vivo', 'Muerto', 'Desconoce']}
                />
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Clase de animal'
                  value={filters.clase}
                  onChange={(event) => {
                    if (event.target.value !== filters.clase) {
                      setFilters({ ...filters, clase: event.target.value, nombreComun: null, pag: 1 })
                      setOffset(0)
                    }
                  }}
                  items={[null].concat(Object.keys(animalClasses))}
                  itemsTags={['Todos'].concat(Object.keys(animalClasses))}
                />
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Nombre común'
                  value={filters.nombreComun}
                  onChange={handleChange('nombreComun')}
                  items={filters.clase && [null].concat(animalClasses[filters.clase].sort())}
                  itemsTags={filters.clase && ['Todos'].concat(animalClasses[filters.clase].sort())}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      color: '#757575',
                      paddingLeft: '15px'
                    }
                  }}
                  primary='Orden fecha'
                />
                <ListItemIcon>
                  <ToggleButton
                    onClick={() => {
                      setFilters({ ...filters, ordenFecha: !filters.ordenFecha, pag: 1 })
                      setOffset(0)
                    }}
                  >
                    {filters.ordenFecha ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </ToggleButton>
                </ListItemIcon>
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Filtro Vistos'
                  value={filters.vistos}
                  onChange={handleChange('vistos')}
                  items={[null, true, false]}
                  itemsTags={['Todos', 'Vistos', 'No vistos']}
                />
              </ListItem>
              <ListItem>
                <FiltersComboBox
                  label='Filtro Archivados'
                  value={filters.archivados}
                  onChange={handleChange('archivados')}
                  items={[null, true, false]}
                  itemsTags={['Todos', 'Archivados', 'No archivados']}
                />
              </ListItem>
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Drawer>
      <Card className={classes.cardTitleSpinner}>
        <CardContent>
          <Typography align='center' className={classes.title} variant='h4'>
            Lista de Reportes
          </Typography>
        </CardContent>
      </Card>

      {
        promiseInProgress
          ? (
            <Card className={classes.cardTitleSpinner}>
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            </Card>
          )
          : (
            <Grid
              container
              spacing={0}
              alignItems='center'
              justify='center'
            >
              {reportes && reportes.content.map((reporte) => {
                return (
                  <CardReport key={reporte.idReporte} reporte={reporte} />)
              })}

              <div id='newLine' />

              <div class='fondoPag'>
                {(reportes && filtrar) &&
                  <Pagination
                    limit={reportes.size}
                    offset={offset}
                    total={reportes.totalElements}
                    reduced='true'
                    onClick={(e, offset, page) => {
                      setOffset(offset)
                      setFilters({ ...filters, pag: page })
                    }}
                  />}
              </div>
            </Grid>
          )
      }
    </div>

  )
}
