import React from 'react'
import { Card, CardContent, Table, TableCell, TableRow } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import '../../../constants/card.css'
import TableHead from '@material-ui/core/TableHead'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import axios from 'axios'
import moment from 'moment'
import autoLogout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles(theme => ({

  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    justifyContent: 'center'
  },
  tableWrapper: {
    margin: 10,
    overflowX: 'auto',
    display: 'inline-block',
    height: 600

  },
  tableMine: {
    maxHeight: 300
  },
  overrides: {
    tableCell: {
      padding: '0px 40px 0px 16px',
      overflow: 'hidden'

    }

  },
  stickyColumn: {
    left: 0,
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 2
  },
  stickyDay: {
    left: 0,
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 3
  }
}))

/* const themeAdd = createMuiTheme({
  palette: {
    primary: green
  }
}) */


const columns = [
  { id: 'C0', label: 'Día' },
  { id: 'C1', label: 'Limpieza recinto' },
  { id: 'C2', label: 'Temperatura corporal(°C)' },
  { id: 'C9', label: 'Peso(Kg)' },
  { id: 'C3', label: 'Defeca' },
  { id: 'C4', label: 'Cuánto' },
  { id: 'C5', label: 'Plásticos' },
  { id: 'C6', label: 'Se le da de comer' },
  { id: 'C7', label: 'Come' },
  { id: 'C8', label: 'Cuánto' },
  { id: 'C10', label: 'Flota' },
  { id: 'C11', label: 'Nada' },
  { id: 'C12', label: 'Camina' },
  { id: 'C13', label: 'Impermeabilidad del plumaje' },
  { id: 'C14', label: 'Observaciones' }
]

function createData (C0, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, C14) {
  return { C0, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, C14 }
}

function boolToSiNo (b) {
  if (b === null) {
    return ''
  }
  return b ? 'Sí' : 'No'
}

function nullToEmpty (string) {
  return !string ? '' : string
}
let hayData = []
let idents = []
function createRow (index, data) {
  if (!data) {
    hayData.push(false)
    idents.push(-1)
    return createData(index + 1, '', '', '', '', '', '', '', '', '', '', '', '', '', '')
  }
  hayData.push(true)
  idents.push(data.idFichaDiaria)
  return createData(index + 1, boolToSiNo(data.limpiezaRecinto), nullToEmpty(data.temperatura),
    boolToSiNo(data.defeca), nullToEmpty(data.defecaCant), boolToSiNo(data.plasticos),
    boolToSiNo(data.dadoComida), nullToEmpty(data.come).replace('_', ' '), nullToEmpty(data.comeCant),
    nullToEmpty(data.peso), boolToSiNo(data.flota), nullToEmpty(data.nada).replace('_', ' '),
    nullToEmpty(data.camina).replace('_', ' '), nullToEmpty(data.impermeabilidadPlumaje).replace('_', ' '),
    nullToEmpty(data.observaciones))
}

// -----------------------------------------------------------------------------------------------------

export default function GridFichasDiarias (props) {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const [rows, setRows] = React.useState([])
  const [dateState, setDateState] = React.useState({
    month: moment()
  })
  
  function handleDecreaseMonth () {
    setDateState(
      prevState => ({ month: prevState.month.subtract(1, 'month') }),
      dateState.filterByMonth
    )
  }

  function handleIncreaseMonth () {
    setDateState(prevState => ({ month: prevState.month.add(1, 'month') }), dateState.filterByMonth)
  }

  function handleDescargarPDF () {
    const url = `${apiURL}/fichas_diarias/${props.ficha}/descargar_pdf?anio=${dateState.month.format('YYYY')}&mes=${dateState.month.format('M')}`
    trackPromise(
      axios.post(url, {}, config).then(response => {
        var base64EncodedPDF = response.data
        var dataURI = 'data:application/pdf;base64,' + base64EncodedPDF
        var link = document.createElement('a')
        document.body.appendChild(link)
        link.download = response.headers.nombre
        link.href = dataURI
        link.click()
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error PDF: \n', _error)
      })
    )
  }

  React.useEffect((event) => {
    hayData = []
    idents = []
    const url = `${apiURL}/fichas_diarias/${props.ficha}?anio=${dateState.month.format('YYYY')}&mes=${dateState.month.format('M')}`
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    trackPromise(
      axios.get(url, config).then((response) => {
        setRows(response.data.map((obj, index) => {
          return createRow(index, obj)
        }))
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al ingresar el reporte: \n', _error) // TODO show errors to the user
      })
    )
    console.log('datas: ', idents)
  }, [dateState])
  const { promiseInProgress } = usePromiseTracker()
  return (
    <>
      <div className={classes.root}>
        <Link to={`/alta_ficha_diaria/${props.ficha}`}>
          <IconButton aria-label='delete'>
            <AddCircleIcon className={classes.addIcon} fontSize='large' />
          </IconButton>
        </Link>
        <Button
          variant='contained'
          color='#ffffff'
          size='small'
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={handleDescargarPDF}
        >
          Descargar en PDF
        </Button>
      </div>

      <h2>
        <span onClick={handleDecreaseMonth}>{'< '}</span>
        <span>{dateState.month.format('MMM YYYY')}</span>
        <span onClick={handleIncreaseMonth}>
          {dateState.month.clone().add(1, 'hour') > moment() ? '' : ' >'}
        </span>
      </h2>

      <div style={{ width: props.width }} className={classes.tableWrapper}>

        {promiseInProgress
          ? (
            <Card className={classes.cardTitleSpinner}>
              <CardContent>
                <LoadingSpinner />
              </CardContent>
            </Card>
          )
          : (
            <Table style={{ width: props.width }} stickyHeader className={classes.tableMine}>
              <TableHead className={classes.tableMine}>
                {columns.map((column, index) => (
                  <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyDay : classes.tableCell}>
                    {column.label}
                  </TableCell>
                ))}
              </TableHead>
              {rows.map(row => {
                return (
                  <TableRow tabIndex={-1} key={row.code} className={classes.tableMine}>
                    {columns.map((column, index) => {
                      const value = row[column.id]
                      if (column.id === 'C0') {
                        return (
                          <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyColumn : classes.tableCell}>
                            <Link to={(hayData[value - 1]) ? `/modificar_ficha_diaria/${props.ficha}/${idents[value - 1]}` : { pathname: `/alta_ficha_diaria/${props.ficha}`, state:{ anio: dateState.month.format('YYYY'), mes: dateState.month.format('M'), dia: value } }} key={column.id}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </Link>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyColumn : classes.tableCell}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </Table>
          )}
      </div>

    </>
  )
}
