import React from 'react'
import Loader from 'react-loader-spinner'
import './spinner.css'

export default function LoadingSpinner (props) {
  return (
    <div className='spinner'>
      <Loader type='Rings' color='#2BAD60' height='200' width='200' />
    </div>
  )
}
