import React from 'react'
import { Card, CardContent, List, ListItem, Divider, Typography, AppBar, Toolbar, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ListIcon from '@material-ui/icons/List'
import IconButton from '@material-ui/core/IconButton'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import '../../../../constants/card.css'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import Pagination from 'material-ui-flat-pagination'
import Tooltip from '@material-ui/core/Tooltip'
import autoLogout from '../../../../generalFunctions/autoSignout'
import LoadingSpinner from '../../../generalComponents/loadingSpinner/LoadingSpinner.js'

import axios from 'axios'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import { ACCESS_TOKEN } from '../../../../constants'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  containerTreatments: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.4)'
  },
  containerTreatmentsRed: {
    width: '100%',
    border: '1px solid rgba(219, 53, 20, 0.5)'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    overflow: 'auto'
  },
  listIcon: {
    marginRight: '0px',
    color: '#77BF3F'
  },
  addIcon: {
    color: '#77BF3F'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  dialog: {
    margin: '4px',
    flexGrow: 1
  },
  titulo: {
    margin: 'auto',
    color: '#3C810D'
  },
  appBar: {
    position: 'fixed',
    background: '#9FCC3C'
  },
  toolbar: theme.mixins.toolbar
}))

const themeAdd = createMuiTheme({
  palette: {
    primary: green
  }
})

function PopUpAllTreatments(props) {
  const classes = useStyles()
  const { open, onClose, idFicha, ...others } = props
  const [tratamientosTodos, setTratamientosTodos] = React.useState(null)
  const [offset, setOffset] = React.useState(0)
  const [page, setPage] = React.useState(1)
  const { promiseInProgress } = usePromiseTracker()
  const apiURL = process.env.REACT_APP_API_URL

  React.useEffect(() => {
    if (open) {
      const url = `${apiURL}/ficha_tratamiento`
      const config = {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
        params: {
          ficha: idFicha,
          ordenFecha: true,
          paginado: true,
          pag: page
        }
      }
      trackPromise(
        axios.get(url, config).then((response) => {
          setTratamientosTodos(response.data)
        }).catch(_error => {
          if (_error.response.status === 401) {
            autoLogout()
          }
          console.log('Error al obtener los tratamientos: \n', _error)
        }))
    }
  }, [open, page])
  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      scroll='paper'
      className={classes.dialog}
      style={{
        margin: 0
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography
            variant='h5'
            className={classes.title}
          >
            Tratamientos de la ficha #{idFicha}
          </Typography>
          <IconButton onClick={onClose} className={classes.closeIcon} edge='start' color='inherit' aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      {promiseInProgress
        ? (
          <LoadingSpinner />
        )
        : (
          <div>
            <List>
              {tratamientosTodos && tratamientosTodos.content.map((obj) => {
                return (
                  <ListItem key={obj.idFichaTratamiento}>
                    <Tratamiento tratamiento={obj} />
                  </ListItem>
                )
              })}
            </List>
            {tratamientosTodos &&
              <Pagination
                limit={tratamientosTodos.size}
                offset={offset}
                total={tratamientosTodos.totalElements}
                reduced='true'
                onClick={(e, offset, page) => {
                  setOffset(offset)
                  setPage(page)
                }}
              />}
          </div>
        )}
    </Dialog>
  )
}

function Tratamiento(props) {
  const classes = useStyles()
  const { idFichaTratamiento, fechaHoraComienzo, tipoTratamiento, via, frecuencia, shouldBeEnded, finalizado, ...other } = props.tratamiento
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const currentDate = iOS ? new Date(fechaHoraComienzo.substr(0, 19)) : new Date(fechaHoraComienzo)
  console.log(props.tratamiento)
  function addLine(texto) {
    return (texto) ? texto.replace(/_/g, '/') : null
  }

  return (
    <Card style={shouldBeEnded ? { boxShadow: '0px 0px 13px 0px rgba(216, 0, 12,0.7)' } : {}} className={shouldBeEnded ? classes.containerTreatmentsRed : classes.containerTreatments}>
      <Link to={'/ficha_tratamiento/' + idFichaTratamiento} underline='none' style={{ textDecoration: 'none' }}>
        <CardContent>
          <Typography style={{ color: '#3C810D' }} variant='subtitle1'>
            Tratamiento #{idFichaTratamiento}
          </Typography>
          {
            shouldBeEnded &&
            <Typography color='error' variant='subtitle2'>
              Duración estipulada excedida
              </Typography>
          }
          {
            finalizado &&
            <Typography variant='subtitle2'>
              Tratamiento finalizado
              </Typography>
          }
        </CardContent>
        <CardContent scroll='paper'>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='center'>
                  Inicio
                  <br />
                  {(currentDate.getDate()).toString() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear().toString()}
                </TableCell>
                <TableCell align='center'>
                  {tipoTratamiento}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center'>
                  Via: {addLine(via)}
                </TableCell>
                <TableCell align='center'>
                  Cada {frecuencia}hs
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Link>
    </Card>
  )
}

export default function ListaTratamientos(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [tratamientos, setTratamientos] = React.useState(null)
  const { promiseInProgress } = usePromiseTracker()
  const apiURL = process.env.REACT_APP_API_URL

  React.useEffect(() => {
    const url = `${apiURL}/ficha_tratamiento`
    const config = {
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) },
      params: {
        ficha: props.idFicha,
        ordenFecha: true,
        activo: true,
        paginado: false
      }
    }

    trackPromise(
      axios.get(url, config).then((response) => {
        setTratamientos(response.data)
      }).catch(_error => {
        if (_error.response.status === 401) {
          autoLogout()
        }
        console.log('Error al obtener los tratamientos: \n', _error)
      }))
  }, [])

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ThemeProvider theme={themeAdd}>
            <Link to={`/alta_tratamiento/${props.idFicha}`}>
              <IconButton aria-label='delete'>
                <AddCircleIcon color='green' className={classes.addIcon} fontSize='large' />
              </IconButton>
            </Link>
          </ThemeProvider>
          <Typography
            variant='h5'
            align='center'
            className={classes.titulo}
          >
            Tratamientos Activos
          </Typography>
          <Tooltip title='Ver todos los tratamientos'>
            <IconButton aria-label='delete' onClick={() => { setOpen(true) }}>
              <ListIcon color='green' className={classes.listIcon} fontSize='large' />
            </IconButton>
          </Tooltip>
        </ListItem>
        <Divider />
        {props.promisePending
          ? (
            <LoadingSpinner />
          )
          : (
            tratamientos && tratamientos.map((obj) => {
              return (
                <ListItem key={obj.idFichaTratamiento}>
                  <Tratamiento tratamiento={obj} />
                </ListItem>
              )
            }))}
        <PopUpAllTreatments
          idFicha={props.idFicha}
          open={open}
          onClose={() => { setOpen(!open) }}
        />
      </List>
    </>
  )
}
