import React from 'react'
import { Card, CardContent, List, ListItem, Divider, Typography, Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LoadingSpinner from '../../../generalComponents/loadingSpinner/LoadingSpinner'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  containerTreatments: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.4)'
  },
  containerTreatmentsRed: {
    width: '100%',
    border: '1px solid rgba(219, 53, 20, 0.5)'
  },
  list: {
    overflowY: 'scroll'
  },
  containerTreatmentsGreen: {
    width: '100%',
    border: '1px solid rgba(119, 191, 63, 0.5)'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    marginBottom: '0',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    overflow: 'auto'
  },
  listIcon: {
    marginRight: '0px',
    color: '#77BF3F'
  },
  addIcon: {
    color: '#77BF3F'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  dialog: {
    margin: '4px',
    flexGrow: 1
  },
  titulo: {
    margin: 'auto',
    color: '#3C810D',
  },
  subdate: {
    margin: 'auto',
    color: '#3C810D',
  },
  appBar: {
    position: 'fixed',
    background: '#9FCC3C'
  },
  mobileColumn: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '50%',
    height: '100%',
    lineHeight: '100%',
    border: '1px solid rgba(0, 0, 0, 0.4)'
  },
  browserColumn: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '33.33%',
    height: '100%',
    lineHeight: '100%',
    border: '1px solid rgba(0, 0, 0, 0.4)'
  },
  containerTreatmentsDisabled: {
    width: '100%',
    backgroundColor: '#f0f0f0'
  },
  toolbar: theme.mixins.toolbar
}))

function Instancia(props) {
  const classes = useStyles()
  const { idFichaTratamiento, fechaHora, tipoTratamiento, estado, via, frecuencia, shouldBeEnded, ...other } = props.tratamiento
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const currentDate = iOS ? new Date(fechaHora.substr(0, 19)) : new Date(fechaHora)
  let styleText = {}

  const styleCard = {
    style: null,
    className: null
  }
  if (estado === 'Atrasada') {
    styleCard.style = { boxShadow: '0px 0px 13px 0px rgba(216, 0, 12,0.7)' }
    styleCard.className = classes.containerTreatmentsRed
    styleText = { color: 'rgba(216, 0, 12,0.7)' }
  } else if (estado === 'Próxima') {
    styleCard.style = {}
    styleCard.className = classes.containerTreatments
    styleText = { color: '#585F55' }
  } else if (estado === 'No realizada') {
    styleCard.style = {}
    styleCard.className = classes.containerTreatmentsDisabled
    styleText = { color: '#898E86' }
  } else {
    styleCard.style = { boxShadow: '0px 0px 13px 0px rgba(115, 216, 33,0.7)' }
    styleCard.className = classes.containerTreatmentsGreen
    styleText = { color: '#3FA71D' }
  }
  return (
    <Card style={styleCard.style} className={styleCard.className}>
      <Link
        to={{
          pathname: '/realizar_tratamiento/' + idFichaTratamiento,
          state: {
            fechaHora: currentDate
          }
        }}
        style={{ textDecoration: 'none' }}
      >
        <CardContent>
          <Typography style={{ color: '#000000' }} variant='subtitle1'>
            Trat. #{idFichaTratamiento}
          </Typography>
          {
            !props.isMobile &&
            <Typography style={styleText} fontWeight='fontWeightBold' variant='subtitle1'>
              <Box fontWeight="fontWeightBold">
                {estado}
              </Box>
            </Typography>
          }
        </CardContent>
        <CardContent scroll='paper'>
          <Divider />
          <Table>
            <TableBody>
              {
                props.isMobile ?
                  <>
                    <TableRow>
                      <TableCell align='center'>
                        {estado}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>
                        Animal #{props.tratamiento.idFichaAnimal}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>
                        {props.tratamiento.nombreAnimal}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>
                        {tipoTratamiento.nombre}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>
                        {currentDate.getHours() + ':' + String(currentDate.getMinutes()).padStart(2, '0')}
                      </TableCell>
                    </TableRow>
                  </>
                  :
                  <>
                    <TableRow>
                      <TableCell align='center'>
                        Animal #{props.tratamiento.idFichaAnimal}
                      </TableCell>
                      <TableCell align='center'>
                        {props.tratamiento.nombreAnimal}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>
                        {tipoTratamiento.nombre}
                      </TableCell>
                      <TableCell align='center'>
                        {currentDate.getHours() + ':' + String(currentDate.getMinutes()).padStart(2, '0')}
                      </TableCell>
                    </TableRow>
                  </>
              }
            </TableBody>
          </Table>
        </CardContent>
      </Link>
    </Card>
  )
}

export default function ColumnaActividades(props) {
  const classes = useStyles()

  return (
    <Card className={props.isMobile ? classes.mobileColumn : classes.browserColumn}>
      
      <Typography
        variant='h6'
        align='center'
        className={classes.subdate}
      >
        {props.dateState.month.format('DD MMM YYYY')}
      </Typography>
      <Divider />
      <List>
        {props.promiseInProgress
          ? (
            <LoadingSpinner />
          )
          : (
            props.insts && props.insts.instancias.map((obj) => {
              return (
                <ListItem key={obj.idFichaTratamiento}>
                  <Instancia
                    isMobile={props.isMobile}
                    tratamiento={
                      {
                        ...obj,
                        tipoTratamiento: props.insts.tratamientos[obj.idFichaTratamiento].tipoTratamiento,
                        nombreAnimal: props.insts.animales[obj.idFichaAnimal].nombreComunFichaAnimal.nombre
                      }
                    } />
                </ListItem>
              )
            }))}
      </List>
    </Card>
  )
}