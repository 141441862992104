import React from 'react'
import axios from 'axios'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import 'react-responsive-ui/style.css'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import { KarumbeTextField, KarumbeDatePicker, KarumbeButton } from '../../karumbe_components'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import autoSignout from '../../../generalFunctions/autoSignout'


const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formControl: {
    backgroundColor: '#FCFAFA',
    margin: theme.spacing(1),
    width: '100%'
  },
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    maxWidth: 800,
    backgroundColor: 'rgba(255, 255, 255, 0.95)'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%'
  }
}))

export default function ScreenAltaVoluntario (props) {
  const apiURL = process.env.REACT_APP_API_URL
  const classes = useStyles()
  const [message, setMessage] = React.useState([])
  const [openError, setOpenError] = React.useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [ficha, setFicha] = React.useState({
    limpiezaRecinto: null,
    temperatura: null,
    defeca: null,
    defecaCant: null,
    plasticos: null,
    dadoComida: null,
    come: null,
    comeCant: null,
    peso: null,
    flota: null,
    nada: null,
    camina: null,
    impermeabilidadPlumaje: null,
    observaciones: null,
    idFichaAnimal: props.match.params.idFichaAnimal,
    fecha: props.location.state? (new Date(props.location.state.anio, props.location.state.mes - 1, props.location.state.dia)).getTime() :(new Date()).getTime()
  })

  const handleChange = attribute => event => {
    setFicha({ ...ficha, [attribute]: event.target.value })
  }

  const handleChangeDate = attribute => date => {
    setFicha({ ...ficha, [attribute]: date.getTime() })
  }

  React.useEffect((event) => {
    setError(false)
    setOpenError(false)
    setOpenSuccess(false)
  }, [ficha])

  const goBack = function () {
    window.history.go(-1)
  }

  const postFichaDiaria = function () {
    var config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
    var url = `${apiURL}/fichas_diarias`
    trackPromise(
      axios.post(url, ficha, config).then(response => {
        setOpenSuccess(true)
        setMessage('La ficha diaria se ha ingresado satisfactoriamente')
      }).catch(responseError => {
        if (responseError.response.status === 401) {
          autoSignout()
  
        }
        setOpenError(true)
        setMessage(responseError.response.data)
      })
    )
  }
  const { promiseInProgress } = usePromiseTracker()

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <ErrorMsg open={openError} handleOpen={() => { setOpenError(!openError) }} message={message} />
        <SuccessMessage open={openSuccess} handleOpen={() => { setOpenSuccess(!openSuccess) }} message={message} />
        {promiseInProgress
          ? (
            <CardContent>
              <LoadingSpinner />
            </CardContent>
          )
          : (
            <CardContent>
              <Typography align='center' className={classes.title} variant='h4'>
                  Ingresar Ficha Diaria
              </Typography>
              <form className={classes.container} noValidate autoComplete='off'>
                <List className={classes.root}>
                  <ListItem>
                    <KarumbeDatePicker
                      onChange={handleChangeDate('fecha')}
                      maxDate={new Date()}
                      value={ficha.fecha}
                      label='Fecha de la ficha'
                    />
                  </ListItem>
                  <ListItem>
                    <Typography align='center' variant='h5'>
                        Limpieza Recinto:
                    </Typography>
                    <RadioGroup row onChange={handleChange('limpiezaRecinto')} value={ficha.limpiezaRecinto}>
                      <FormControlLabel
                        value='true'
                        control={<Radio color='primary' />}
                        label='Si'
                        labelPlacement='top'
                      />
                      <FormControlLabel
                        value='false'
                        control={<Radio color='primary' />}
                        label='No'
                        labelPlacement='top'
                      />
                    </RadioGroup>
                  </ListItem>
                  <ListItem>
                    <TextField
                      label='Temperatura Corporal'
                      maxLength={12}
                      placeholder='Ingrese la tempertura corporal en °C'
                      type='number'
                      className={classes.textField}
                      margin='normal'
                      variant='filled'
                      onChange={handleChange('temperatura')}
                      value={ficha.temperatura}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      label='Peso'
                      maxLength={12}
                      type='number'
                      className={classes.textField}
                      margin='normal'
                      variant='filled'
                      placeholder='Ingrese el peso en Kg'
                      onChange={handleChange('peso')}
                      value={ficha.peso}
                    />
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>Defecación</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Defeca:
                            </Typography>
                            <RadioGroup row onChange={handleChange('defeca')} value={ficha.defeca}>
                              <FormControlLabel
                                value='true'
                                control={<Radio color='primary' />}
                                label='Si'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio color='primary' />}
                                label='No'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                          <ListItem>
                            <KarumbeTextField
                              label='Cuanto'
                              value={ficha.defecaCant}
                              onChange={handleChange('defecaCant')}
                              maxLength={45}
                            />
                          </ListItem>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Plástico:
                            </Typography>
                            <RadioGroup row onChange={handleChange('plasticos')} value={ficha.plasticos}>
                              <FormControlLabel
                                value='true'
                                control={<Radio color='primary' />}
                                label='Si'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio color='primary' />}
                                label='No'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel2a-header'
                      >
                        <Typography className={classes.heading}>Comida</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Se le da de comer:
                            </Typography>
                            <RadioGroup row onChange={handleChange('dadoComida')} value={ficha.dadoComida}>
                              <FormControlLabel
                                value='true'
                                control={<Radio color='primary' />}
                                label='Si'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio color='primary' />}
                                label='No'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Come:
                            </Typography>
                            <RadioGroup row onChange={handleChange('come')} value={ficha.come}>
                              <FormControlLabel
                                value='Solo'
                                control={<Radio color='primary' />}
                                label='Sólo'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Con_ayuda'
                                control={<Radio color='primary' />}
                                label='Con Ayuda'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='No'
                                control={<Radio color='primary' />}
                                label='No'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                          <ListItem>
                            <KarumbeTextField
                              label='Cuanto'
                              onChange={handleChange('comeCant')}
                              value={ficha.comeCant}
                              maxLength={45}
                            />
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <ExpansionPanel className={classes.root}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel3a-header'
                      >
                        <Typography className={classes.heading}>Datos Generales</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List className={classes.root}>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Flota:
                            </Typography>
                            <RadioGroup row onChange={handleChange('flota')} value={ficha.flota}>
                              <FormControlLabel
                                value='true'
                                control={<Radio color='primary' />}
                                label='Si'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio color='primary' />}
                                label='No'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='null'
                                control={<Radio color='primary' />}
                                label='N/a'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Nada:
                            </Typography>
                            <RadioGroup row onChange={handleChange('nada')} value={ficha.nada}>
                              <FormControlLabel
                                value='Mal'
                                control={<Radio color='primary' />}
                                label='Mal'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Bien'
                                control={<Radio color='primary' />}
                                label='Bien'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Muy_bien'
                                control={<Radio color='primary' />}
                                label='Muy Bien'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Null'
                                control={<Radio color='primary' />}
                                label='N/a'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                          <ListItem>
                            <Typography align='center' variant='h6'>
                                Camina:
                            </Typography>
                            <RadioGroup row onChange={handleChange('camina')} value={ficha.camina}>
                              <FormControlLabel
                                value='Mal'
                                control={<Radio color='primary' />}
                                label='Mal'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Bien'
                                control={<Radio color='primary' />}
                                label='Bien'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Muy_bien'
                                control={<Radio color='primary' />}
                                label='Muy Bien'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Null'
                                control={<Radio color='primary' />}
                                label='N/a'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                          <ListItem>
                            <Typography align='left' variant='h6'>
                                Impermeabilidad del Plumaje:
                            </Typography>
                            <RadioGroup row onChange={handleChange('impermeabilidadPlumaje')} value={ficha.impermeabilidadPlumaje}>
                              <FormControlLabel
                                value='Malo'
                                control={<Radio color='primary' />}
                                label='Malo'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Bueno'
                                control={<Radio color='primary' />}
                                label='Bueno'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Muy_bueno'
                                control={<Radio color='primary' />}
                                label='Muy Bueno'
                                labelPlacement='top'
                              />
                              <FormControlLabel
                                value='Null'
                                control={<Radio color='primary' />}
                                label='N/a'
                                labelPlacement='top'
                              />
                            </RadioGroup>
                          </ListItem>
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </ListItem>
                  <ListItem>
                    <KarumbeTextField
                      label='Observaciones'
                      onChange={handleChange('observaciones')}
                      value={ficha.observaciones}
                      maxLength={500}
                      rows={5}
                    />
                  </ListItem>
                  <ListItem>
                    <KarumbeButton
                      label='Cancelar'
                      onClick={goBack}
                    />
                    <KarumbeButton
                      label='Ingresar Ficha Diaria'
                      onClick={postFichaDiaria}
                    />
                  </ListItem>
                </List>
              </form>
            </CardContent>
          )}
      </Card>
    </div>
  )
}
