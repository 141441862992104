// TODO contendor pagina Sumate.
// trae informacion del backend y llama a los componentes de presentacion pasandoles los datos pertinentes
import React, { useState, useEffect } from 'react'
import { render } from "react-dom";
import SuccessMessage from '../../generalComponents/Message/SuccessMessage'
import Collapse from '@material-ui/core/Collapse'
import {  animateScroll as scroll } from "react-scroll";
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Pagination from 'material-ui-flat-pagination'
import Grid from '@material-ui/core/Grid'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import { ANIMAL_FILE_STATE } from '../../../constants/index'
import ErrorMsg from '../../generalComponents/Message/ErrorMessage'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import autoLogout from '../../../generalFunctions/autoSignout'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MuiPhoneNumber from 'material-ui-phone-number'
import FormLabel from '@material-ui/core/FormLabel'
import { KarumbeTextField, KarumbeDateTimePicker, KarumbeButton, KarumbeSelect, KarumbeDatePicker, KarumbeInputFotos, KarumbeGridList } from '../../karumbe_components'
import '../../../constants/card.css'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Carousel from "../../generalComponents/Carousel/Carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Container, Row, Col } from "reactstrap";
import './ScreenEducacion.css';

const useStyles = makeStyles({
//    card: {
//        width: '100%',
//        maxWidth: '650px',
//        maxHeight: '250px',
//        height: '100%',
//        marginBottom:'3%',
//        marginRight:'15px',
//        backgroundColor: 'rgba(0, 151, 200, 1)'
//
//    },
card: {
        maxWidth: '830px',
        height: '98%',
        display: 'flex',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        

    },
    card1: {
        maxWidth: '730px',
        height: '98%',
        display: 'initial',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0)',
  
    },
    cardGrande: {
        width: '100%',
        maxWidth: '650px',
        maxHeight: '250px',
        height: '100%',
        marginBottom:'3%',
        marginRight:'15px',
       backgroundColor: 'rgba(255, 255, 255, 0.7)'
        
    },
    cardTitleSpinner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        maxWidth: 1448,
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    table: {
        padding: '9px 3px 9px 3px',
        margin: '3px',
        height: '10%',

    },
    text: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    textLong: {
        wordBreak: 'break-word',
        maxWidth: '100px'
    },
    fecha: {
        whiteSpace: 'nowrap'
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)'
        }
    },
    filter: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        position: 'fixed',
        top: '10',
        right: '0',
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 1)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: '#3EA91D'
        }
    },
    checkboxIcons: {
        color: '#20DD03'
    },
    checkboxIconsRed: {
        color: '#F53232'
    },
    checkboxText: {
        paddingLeft: '15px',
        color: '#757575'
    },
    checkboxContainer: {
        display: 'flex',
        alignContent: 'space-between'
    },
    root: {
        maxWidth: '261px',
        width: '100%',
        padding: '0px'
    },
    grid: {
        height: '92%',

    },
    title: {
         fontSize: '24px',
        color: '#3C810D',
        fontFamily: 'TovariSans'
    },
    title1: {
         fontSize: '26px',
        color: '#3C810D',
        fontFamily: 'TovariSans'
       
    },
    subtitle:{
        fontSize:'20px',
        fontFamily:'TovariSans'
    },
    h6: {size: '1.132em',
    textAlign:'justify',
    fontFamily:'Source Sans Pro'},

    h7: {
    color: '#3C810D',
    fontFamily:'Source Sans Pro'},
    bigAvatar: {
        maxWidth: '900px',
        height: '91%',
        display: 'inline-block',
        marginTop: '3%',
        margin: '5px 5px 5px 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        width: 158,
        height: 158
        
    },
  
  dropdownButton1: {
    backgroundColor: '#55c5c1',
    opacity:'0.9',
    width: '100%',
    maxWidth: '1000px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0 auto',
  },
   dropdownButton2: {
    backgroundColor:  '#79e2df',
    opacity:'0.9',
    width: '100%',
    maxWidth: '1000px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0 auto',
  },
  dropdownContent: {
    width: '100%',
    maxWidth: '1000px',
    marginTop: '1px',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)',
    background: 'rgba(255, 255, 255, 0.7)',
    margin: '0 auto',
  },
  dropdownContentExtern: {
    width: '100%',
    maxWidth: '1150px',
    marginTop: '1px',
    margin: '0 auto',
  },
 titleContent: {
  width: '100%',
  maxWidth: '1000px',
  margin: '20px 13% 0', /* Cambiar el valor de margen superior según tus necesidades */
  borderRadius: '20px',
  boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '200px',
  display:'inline-block'
},
  title2: {
//        color: 'rgb(32 122 126)'
        color:'white',
        fontFamily: 'TovariSans'
    },
  body: {
      
    fontFamily: 'Gil Sans MT',
      fontSize:'16px',
      textAlign:'justify',
  }
  
  
})

export default function ScreenEducacion() {
    const [message, setMessage] = React.useState([])
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const classes = useStyles()
      const [expandedMaterialPMarEscuelas, setExpandedMaterialPMarEscuelas] = useState(false);
    const handleExpandMaterialPMarEscuelas = () => {
        setExpandedMaterialPMarEscuelas(!expandedMaterialPMarEscuelas);
      };

    function handleCloseMsj() {
        setOpenSuccess(false)
    }

    return (
         <div class="restOfScreenForEducacionScreen" >
        <Container> 
            <div className={classes.card}>
            <CardContent className={classes.grid}>
             <Grid
                container
                spacing={0}
                alignItems='center'
                justify='center'
                textOverflow='hidden'
                className={classes.grid}
            >
           {/*<div style={{ backgroundImage: 'url("/images/conozcanos/karumbe_conozcanos.jpg")', width: '100%',backgroundSize: 'cover', borderRadius: '5px'}}>*/}
             <Table   className={classes.table} >
                <TableBody>
                    <TableRow >
                        <TableCell align='center'  style={{borderBottom:'1px solid black'}}>
                            <Typography className={classes.title2} style={{color:'black'}}  variant='h4'>
                                EDUCACION AMBIENTAL
                            </Typography>
                        </TableCell>
                       
                    </TableRow>
                    <TableRow >
                        <TableCell align='center'  style={{borderBottom:'1px solid black'}}>
                            <Typography className={classes.subtitle} style={{color:'black'}}  variant='h5'>
                                Una de las áreas en las que Karumbé hace especial énfasis es en la Educación Ambiental.
                            </Typography>
                        </TableCell>
                       
                    </TableRow>
                    
                </TableBody>
            </Table>
            {/*</div>*/}
          </Grid>
          </CardContent>
           </div>
            
        </Container>  
      <div className="ed-container">
                 <div id="one" className="ed-item ed-left" >
                <div className="ed-dropdown-button" >
                <div className="ed-button-topography">
                    <Typography className="ed-topography-dropdown-content" variant="h5" style={{ color: 'white', paddingTop:'15px', fontFamily:'TovariSans' }}>
                        PROGRAMA EDUCATIVO DEL MAR A LAS ESCUELAS<p/>
                    </Typography>
                    </div>
                </div>
               
                    <div  className={classes.dropdownContent}>
                      <Card style={{backgroundColor:'rgba(255, 255, 255, 0.7)'}}>
                        <CardContent>
                         <div className="ed-text-and-circulo">
                           <Typography  className={classes.body}>                  
                           El Programa Educativo “Del Mar a las Escuelas", cuyas actividades están diseñadas para cautivar el interés de niñas, niños y jóvenes en temas relacionados con la zona costera, animales marinos, ecología y conservación, consiste en talleres planificados con maestras, llevado a cabo por un equipo de talleristas interdisciplinario, con propuestas lúdicas y creativas, con un fuerte componente pedagógico y actividades en playas, como exploración, limpieza y liberación. Este programa tiene la posibilidad de llegar a centros educativos de todo el país ya que incluye una modalidad virtual. <br /><br /> <b>Para coordinar actividades educativas comunícate con nosotros vía e-mail: karumbe.educacion@gmail.com</b><p/> 
                            </Typography>
                            
                     
                              <div style={{  textAlign: 'center' }}>
                            <div className="ed-circulo" >
                                <img src="images/educacion/mar_escuelas.jpg" alt="Imagen 3" />
                            </div>
                             
                          
                      </div>
                      
                      </div>
                       
                        </CardContent>
                           
                      </Card>
                    
                    </div>
                     <div >
                <div  className="ed-dropdown-button-aux" onClick={handleExpandMaterialPMarEscuelas}>
                 <div style={{ position: 'relative', height: '73%' }}>
                    <Typography className={classes.title1} variant="h5" style={{ color: 'white' }}>
                       Ver materiales<p/>
                    </Typography>
                    </div>
                    <ExpandMoreIcon style={{ marginLeft: '5px' }} />
                </div>
                <Collapse  in={expandedMaterialPMarEscuelas}>
                    <div  className={classes.dropdownContent}>
                      <Card style={{backgroundColor:'rgba(255, 255, 255, 0.7)'}}>
                        <CardContent style={{paddingBottom:'6px'}}>
                       <Table>
                        <TableBody>
                        
                            <TableRow>
                            
                              <TableCell align='left'>Librillo Del Mar a las Escuelas</TableCell>
                              <TableCell align='left'><a href={process.env.PUBLIC_URL + '/educacion_documentos/Librillo_Del_Mar_A_La_Escuela.pdf'} style={{color:'black'}}>
                   
                        Descargar aquí
                   
                </a></TableCell>
                            
                            </TableRow>
                         
                        </TableBody>
                      </Table>
                      
                           <Typography  className={classes.body} >                  
                               <p/> 
                            </Typography>
                        </CardContent>
                      </Card>
                    </div>
                </Collapse>
            </div>
                    
           
                </div>
                
               
            
                 <div id="two" className="ed-item ed-right" >
                <div className="ed-dropdown-button" >
                 <div className="ed-button-topography">
                    <Typography className="ed-topography-dropdown-content" variant="h5" style={{ color: 'white', paddingTop:'15px', fontFamily:'TovariSans' }}>
                       PROGRAMA EDUCATIVO AULA TORTUGUERA<p/>
                    </Typography>
                    </div>
                </div>
               
                    <div  className={classes.dropdownContent}>
                      <Card style={{backgroundColor:'rgba(255, 255, 255, 0.7)'}}>
                        <CardContent>
                         <div className="ed-text-and-circulo">
                         <div style={{  textAlign: 'center' }}>
                            <div className="ed-circulo" >
                                <img src="images/educacion/aula_tortuguera.jpg" alt="Imagen 3" />
                            </div>
                      </div>
                           <Typography  className={classes.body} >                  
                                El Programa Educativo “Aula Tortuguera”, dirigido a niñas, niños y adolescentes en temas relacionados con las tortugas marinas, su biología y ecología, también pretende vincularlos e involucrarlos en conceptos referentes a la conservación y toma de decisiones responsables sobre los ecosistemas que habitan estos carismáticos animales. Este programa se lleva a cabo en el balneario de La Paloma e incluye charlas, limpiezas de costas y liberación de tortugas.<p/> 
                            </Typography>
                              
                      </div>
                        </CardContent>
                      </Card>
                    </div>
                    
               
          
      
                </div>
                

         
                 <div id="three" className="ed-item ed-left" >
                <div className="ed-dropdown-button" >
                 <div className="ed-button-topography">
                    <Typography className="ed-topography-dropdown-content" variant="h5" style={{ color: 'white', paddingTop:'15px', fontFamily:'TovariSans' }}>
                        LIMPIEZA DE COSTAS<p/>
                    </Typography>
                    </div>
                </div>
               
                    <div  className={classes.dropdownContent}>
                      <Card style={{backgroundColor:'rgba(255, 255, 255, 0.7)'}}>
                        <CardContent>
                         <div className="ed-text-and-circulo">
                           <Typography  className={classes.body}>                  
                                Esta actividad educativa se encuentra enmarcada dentro de los Programas Educativos que ofrece Karumbé a los centros educativos, así como también limpiezas de costas puntuales, como la limpieza internacional de costas y limpiezas empresariales.<p/> 
                            </Typography>
                              <div style={{  textAlign: 'center' }}>
                            <div className="ed-circulo" >
                                <img src="images/educacion/limpieza_playas.JPG" alt="Imagen 3" />
                            </div>
                      </div>
                      </div>
                        </CardContent>
                      </Card>
                    </div>
                    
               
                
            </div>
            </div>  
                </div>
 
        
        
        
        
       
        )
}