import React from 'react'
import { Card, CardContent, Table, TableCell, TableRow } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import '../../../constants/card.css'
import TableHead from '@material-ui/core/TableHead'
import { ACCESS_TOKEN } from '../../../constants'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker'
import LoadingSpinner from '../../generalComponents/loadingSpinner/LoadingSpinner.js'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import autoSignout from '../../../generalFunctions/autoSignout'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  title: {
    color: '#3C810D'
  },
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'auto',
    width: '100%',
    justifyContent: 'center'
  },
  tableWrapper: {
    margin: 10,
    overflowX: 'auto',
    display: 'inline-block',
    width: '100%',
    height: 'auto'

  },
  tableMine: {
    maxHeight: 300
  },
  overrides: {
    tableCell: {
      padding: '0px 40px 0px 16px',
      overflow: 'hidden'
    }
  },
  stickyColumn: {
    left: 0,
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 2
  },
  stickyDay: {
    left: 0,
    position: 'sticky',
    top: 0,
    background: '#fafafa',
    zIndex: 3
  },
  linkArchivo: {
    color: 'black',
    textDecoration: 'none'
  },
  linkBorrar: {
    color: 'red',
    textDecoration: 'none'
  }
}))

const columns = [
  { id: 'C0', label: 'Archivo' },
  { id: 'C1', label: 'Momento de carga' },
  { id: 'C2', label: 'Eliminar' }
]

function createData (C0, C1, C2) {
  return { C0, C1, C2 }
}

let idents = []
function createRow (data) {
  idents.push(data.idArchivo)
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const fh = iOS ? new Date(data.fechaHora.substr(0, 19)) : new Date(data.fechaHora)
  var fechaHora = (fh.getDate()).toString() + '/' + (fh.getMonth() + 1) + '/' + fh.getFullYear().toString() + ', ' + fh.getHours().toString() + ':' + fh.getMinutes().toString().padStart(2, '0')
  return createData(data.nombre + '.' + data.extension, fechaHora, '[ X ]')
}

export default function ScreenArchivosNecropsia (props) {
  const classes = useStyles()
  const apiURL = process.env.REACT_APP_API_URL
  const config = { headers: { Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN) } }
  const [rows, setRows] = React.useState([])
  const [idAnimal, setIdAnimal] = React.useState()

  function descargarArchivo (idArchivo) {
    const url = `${apiURL}/archivos_necropsia/${idAnimal}/descargar/${idArchivo}`
    trackPromise(
      axios.get(url, config).then(response => {
        console.log(response.headers)
        var base64EncodedFile = response.data
        var dataURI = 'data:application/' + 'octet-stream' + ';base64,' + base64EncodedFile
        var link = document.createElement('a')
        document.body.appendChild(link)
        link.download = response.headers.nombre + '.' + response.headers.ext
        link.href = dataURI
        link.click()
      }).catch(responseError => {
        console.log('Error al bajar archivo: \n', responseError)
        if (responseError.response.status === 401) {
          autoSignout()
  
        }
      })
    )
  }

  function subirArchivo (e) {
    var reader = new window.FileReader()
    var file = e.currentTarget.files[0]
    reader.readAsBinaryString(file)
    reader.onload = function () {
      const url = `${apiURL}/archivos_necropsia/${idAnimal}/subir`
      var nombreExtension = file.name.split(/\.(?=[^.]+$)/)
      const headers = {
        nombre: nombreExtension[0],
        ext: nombreExtension[1],
        Authorization: 'Bearer ' + window.localStorage.getItem(ACCESS_TOKEN)
      }

      trackPromise(
        axios.post(url, window.btoa(reader.result), {
          headers: headers
        }).then(response => {
          loadTable()
        }).catch(responseError => {
          console.log('Error al subir archivo: \n', responseError)
          if (responseError.response.status === 401) {
            autoSignout()
    
          }
        })
      )
    }
  }

  function eliminarArchivo (idArchivo) {
    if (window.confirm('¿Está seguro que desea borrar este ítem?')) {
      const url = `${apiURL}/archivos_necropsia/${idAnimal}/eliminar/${idArchivo}`
      trackPromise(
        axios.delete(url, config).then(response => {
          loadTable()
        }).catch(responseError => {
          console.log('Error al eliminar archivo: \n', responseError)
          if (responseError.response.status === 401) {
            autoSignout()   
          }
        })
      )
    }
  }

  function loadTable () {
    idents = []
    setIdAnimal(props.idAnimal)
    const url = `${apiURL}/archivos_necropsia/${props.idAnimal}`
    trackPromise(
      axios.get(url, config).then((response) => {
        setRows(response.data.map(obj => {
          return createRow(obj)
        }))
      }).catch(responseError => {
        console.log('Error al obtener archivos: \n', responseError)
        if (responseError.response.status === 401) {
          autoSignout()
  
        }
      }))
  }

  React.useEffect((event) => {
    loadTable()
  }, [])
  const { promiseInProgress } = usePromiseTracker()

  return (
    <div className={classes.container}>
      <Card className={classes.container}>
        <div style={{ width: props.width }} className={classes.tableWrapper}>

          {promiseInProgress
            ? (
              <Card className={classes.cardTitleSpinner}>
                <CardContent>
                  <LoadingSpinner />
                </CardContent>
              </Card>
            )
            : (
              <CardContent>
                <div>
                  <input
                    accept='*' multiple={false} type='file' style={{ display: 'none' }} id={2} onChange={subirArchivo}
                  />
                  <label htmlFor={2}>
                    <Button
                      onChange={subirArchivo}
                      color='primary'
                      className={classes.buttonFotos}
                      aria-label='upload picture'
                      component='span'
                      startIcon={<CloudUploadIcon />}
                    >
                      Subir un archivo nuevo
                    </Button>
                  </label>
                </div>
                <Table style={{ width: props.width }} stickyHeader className={classes.tableMine}>
                  <TableHead className={classes.tableMine}>
                    {columns.map((column, index) => (
                      <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyDay : classes.tableCell}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableHead>
                  {rows.map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.code} className={classes.tableMine}>
                        {columns.map(column => {
                          const value = row[column.id]
                          if (column.id === 'C0') {
                            return (
                              <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyColumn : classes.tableCell}>
                                <Link className={classes.linkArchivo} onClick={() => descargarArchivo(idents[index])}>{value}</Link>
                              </TableCell>
                            )
                          }
                          if (column.id === 'C2') {
                            return (
                              <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyColumn : classes.tableCell}>
                                <Link className={classes.linkBorrar} onClick={() => eliminarArchivo(idents[index])}><b>{value}</b></Link>
                              </TableCell>
                            )
                          }
                          return (
                            <TableCell style={{ padding: '10px' }} key={column.id} className={index === 0 ? classes.stickyColumn : classes.tableCell}>
                              {value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </Table>
              </CardContent>
            )}
        </div>
      </Card>
    </div>
  )
}
